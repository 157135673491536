import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Box, Divider } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export default function IconBreadcrumbs({
    pages
}) {
    const navigate = useNavigate()
    return (
        <>
            <Breadcrumbs sx={{ textTransform:'capitalize',padding: '1px 2px 2px 10px', display: { xs: 'none', md: 'flex' } }} aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Home"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        navigate('/')
                    }}
                    icon={<HomeIcon fontSize="small" />}
                />
                {pages && pages.length ? pages.map((item, index) => {
                    return <StyledBreadcrumb sx={{ display: { xs: pages.length > 1 && pages.length - 1 == index ? 'none' : 'flex', md: 'flex' } }} onClick={() => {
                        if (item.page_link)
                            navigate(item.page_link)
                    }} label={item.name} />
                }) : ''}
            </Breadcrumbs>
            <Divider sx={{ display: { xs: 'none' } }}></Divider>
        </>
    );
}
