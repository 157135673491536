import { checkConnectionEstablished } from "./common"
import { emitters } from "./emitter-listener-types"
export const Rooms = class Rooms {
    static joinRoom(ws, {
        roomName
    }) {
        try {
            checkConnectionEstablished(ws.socket)
            ws.socket.emit(emitters.JoinRoomRequest, {
                roomName
            })
        } catch (error) {
            console.log(error, 'error')
        }
    }
    static leaveRoom(ws, {
        roomName
    }) {
        try {
            checkConnectionEstablished(ws.socket)
            ws.socket.emit(emitters.LeaveRoomRequest, {
                roomName
            })
        } catch (error) {
            console.log(error, 'error')
        }
    }
}