import { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from "@mui/material/Container";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from "@mui/material/IconButton";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from "@mui/material/Button";
import {
    Addaddress, loginSelector, resetPwd, getuserAddress, getBankDetails
} from "../../store/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../services/api";
import SimpleReactValidator from "simple-react-validator";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function MyAccount() {
    const dispatch = useDispatch();
    const formValidator = useRef(new SimpleReactValidator());
    const passwordValidator = useRef(new SimpleReactValidator());
    let userDetails = localStorage.getItem('userDetails');
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [passwordFormSubmit, setpasswordFormSubmit] = useState(false)
    const [value, setValue] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [shownewPassword, setshownewPassword] = useState(false);
    const { addressloading } = useSelector(loginSelector);
    const [userDatas, setuserDatas] = useState('');
    const [address, setaddress] = useState({
        // user_id: '',
        house_no: '',
        street_name: '',
        city: '',
        state: '',
        pincode: '',
        country: 'India'
    });

    const [contactDetails, setcontactDetails] = useState({
        name: '',
        email_id: '',
        mobile_no: ''
    });

    const [bankdetails, setbankdetails] = useState({
        account_no: '',
        ifsc_code: '',
        branch_name: '',
        bank_name: ''
    });

    const [changePassword, setchangePassword] = useState({
        old_password: '',
        new_password: ''
    });

    useEffect(() => {
        if (userDetails) {
            let user = JSON.parse(userDetails)
            let userId = user._id
            dispatch(getuserAddress({ userId }))
            setcontactDetails({
                ...contactDetails,
                name: user.name,
                email_id: user.email,
                mobile_no: user.mobile
            })
        }
    }, [userDetails])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onAddressSubmit = () => {
        setAddFormSubmit(true);
        let postData = address
        // postData.user_id = userDatas._id
        if (formValidator.current.allValid()) {
            dispatch(Addaddress({ postData }))
        }
    }

    const onChangePasswordSubmit = () => {
        setpasswordFormSubmit(true)
        let postData = changePassword
        if (passwordValidator.current.allValid()) {
            dispatch(resetPwd({ postData }))
        }
    }

    const handleClickShowPassword = (type) => {
        if (type == 1) {
            setShowPassword((show) => !show);
        } else {
            setshownewPassword((shownewPassword) => !shownewPassword)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        formValidator.current.showMessages()
        passwordValidator.current.showMessages()
    }, [])

    return (
        <>
            <Container sx={{ marginTop: '10px' }}  maxWidth="xl" >
                <h4 style={{ marginTop: '80px' }}><b>My Account</b></h4>
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', marginTop: 5, height: 300, width: '100%' }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#15c995'
                            },
                        }}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1, borderColor: 'divider',
                            alignItems: "flex-start",
                            width: {xl:'calc(100% - 80%)',gl:'calc(100% - 80%)',md:'calc(100% - 70%)',sm:'calc(100% - 60%)',xs:'100%'}
                        }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 0) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 0) ? 600 : ''
                            
                        }}
                            label="Contact Details" {...a11yProps(0)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 1) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            width: '100%',
                            fontWeight: (value == 1) ? 600 : ''
                        }} label="Residential Address" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 2) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            width: '100%',
                            fontWeight: (value == 2) ? 600 : ''
                        }} label="Change Password" {...a11yProps(2)} />
                        {/* <Tab sx={{
                            textAlign: 'left !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: (value == 3) ? '#15c995 !important' : '',
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="Verfication" {...a11yProps(3)} /> */}
                        {/* <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: (value == 3) ? '#15c995 !important' : '',
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="Bank Details" {...a11yProps(3)} /> */}
                    </Tabs>
                    <TabPanel value={value} index={0} >
                        <h5 style={{marginLeft:'1.5rem'}}><b>Contact Details</b></h5>
                        <Container sx={{ width: '100%'}} >
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { my: 2 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField id="filled-basic" color="success" label="Name" variant="filled" value={contactDetails.name} fullWidth InputProps={{
                                    readOnly: true,
                                }} />
                                <TextField id="filled-basic" color="success" label="Contact Number" variant="filled" value={contactDetails.mobile_no} fullWidth InputProps={{
                                    readOnly: true,
                                }} />
                                <TextField id="filled-basic" color="success" label="Email Address" variant="filled" value={contactDetails.email_id} fullWidth InputProps={{
                                    readOnly: true,
                                }} />
                            </Box>
                            {/* <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 3, ml: 3, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success">
                                    SUBMIT
                                </Button>
                            </div> */}
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1} >
                        <h5 style={{marginLeft:'1.5rem'}}><b>Residential Address</b></h5>
                        <Container>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { mt: 1, width: '100%' },
                                    display: 'grid',
                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                    gap: 2,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" name="house_no" color="success" label="Appartment / House No" variant="outlined" value={address.house_no} onChange={(e) => {
                                        setaddress({
                                            ...address,
                                            house_no: e.target.value
                                        })
                                    }} />
                                    {formValidator.current.message(
                                        'house_no',
                                        address.house_no,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter House No',
                                            }
                                        }
                                    )}
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField multiline
                                        // rows={2}
                                        maxRows={4} id="outlined-basic" name="street_name" color="success" label="Street Name" variant="outlined" value={address.street_name} onChange={(e) => {
                                            setaddress({
                                                ...address,
                                                street_name: e.target.value
                                            })
                                        }} />
                                    {formValidator.current.message(
                                        'street_name',
                                        address.street_name,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Street Name',
                                            }
                                        }
                                    )}
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" name="city" color="success" label="City" variant="outlined" value={address.city} onChange={(e) => {
                                        setaddress({
                                            ...address,
                                            city: e.target.value
                                        })
                                    }} />
                                    {formValidator.current.message(
                                        'city',
                                        address.city,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter City',
                                            }
                                        }
                                    )}
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" name="state" color="success" label="State" variant="outlined" value={address.state} onChange={(e) => {
                                        setaddress({
                                            ...address,
                                            state: e.target.value
                                        })
                                    }} />
                                    {formValidator.current.message(
                                        'state',
                                        address.state,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter State',
                                            }
                                        }
                                    )}
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" name="pincode" color="success" label="Pincode" variant="outlined" value={address.pincode} onChange={(e) => {
                                        setaddress({
                                            ...address,
                                            pincode: e.target.value
                                        })
                                    }} />
                                    {formValidator.current.message(
                                        'pincode',
                                        address.pincode,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Pincode',
                                            }
                                        }
                                    )}
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" name="country" color="success" label="Country" variant="outlined" value={address.country} onChange={(e) => {
                                        setaddress({
                                            ...address,
                                            country: e.target.value
                                        })
                                    }} InputProps={{
                                        readOnly: true,
                                    }} />
                                    {formValidator.current.message(
                                        'country',
                                        address.country,
                                        'required',
                                        {
                                            className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Account Number',
                                            }
                                        }
                                    )}
                                </FormControl>
                            </Box>
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={onAddressSubmit}>
                                    SUBMIT
                                </Button>
                            </div>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <h5 style={{marginLeft:'1.5rem'}}><b>Change Password</b></h5>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { my: 2 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Container sx={{ width: '500px' }}>
                                <Typography variant='body2' paragraph style={{ marginTop: '15px' }}>Please use letters, numbers and accepted symbols only, with no spaces, minimum 6, maximum 32 characters.</Typography>
                                <Typography variant='body2' paragraph sx={{ marginTop: '15px', mb: '15px' }}>Your password should not contain your username, name, email address or year of birth. You can increase the strength of your password by using a mixture of letters, numbers and symbols. Please remember that passwords are case-sensitive.</Typography>
                            </Container> */}
                            <Container sx={{ width: '100%' }}>
                                {/* <Typography variant='body2'>The following symbols may be used as part of your password:</Typography> */}
                                {/* <Typography variant='subtitle1'>! " # $ % & ' ( ) * + , - . / : ; < = > ? _ @ [ \ ] ^ ` { | } ~</Typography> */}
                                <FormControl sx={{ m: 2 }} fullWidth color="success">
                                    <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        name="old_password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { handleClickShowPassword(1) }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    color="success"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Current Password"
                                        onChange={(e) => {
                                            setchangePassword({
                                                ...changePassword,
                                                old_password: e.target.value
                                            })
                                        }}
                                    />
                                    {passwordValidator.current.message(
                                        "old_password",
                                        changePassword.old_password,
                                        [
                                            "required",
                                            {
                                                regex:
                                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!/#%*?&])[A-Za-z\d@$!/#%*?&]{8,15}$/,
                                            },
                                        ],
                                        {
                                            className: `invalid_feedback 
														  ${passwordFormSubmit ? "show" : "hide"}`,
                                            messages: {
                                                required: "Please Enter Password",
                                                password: "Please enter valid password",
                                                // regex:
                                                //     "Minimum 8 and Maximum 15 characters,at least include one Uppercase letter, one Lowercase letter, one Number and one Special character (@$!/#%*?&)",
                                            },
                                        }
                                    )}
                                </FormControl>

                                <FormControl sx={{ m: 2 }} fullWidth color="success">
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        name="new_password"
                                        type={shownewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { handleClickShowPassword(2) }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    color="success"
                                                >
                                                    {shownewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                        onChange={(e) => {
                                            setchangePassword({
                                                ...changePassword,
                                                new_password: e.target.value
                                            })
                                        }}
                                    />
                                    {passwordValidator.current.message(
                                        "new_password",
                                        changePassword.new_password,
                                        [
                                            "required",
                                            {
                                                regex:
                                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!/#%*?&])[A-Za-z\d@$!/#%*?&]{8,15}$/,
                                            },
                                        ],
                                        {
                                            className: `invalid_feedback 
														  ${passwordFormSubmit ? "show" : "hide"}`,
                                            messages: {
                                                required: "Please Enter Password",
                                                password: "Please enter valid password",
                                                // regex:
                                                //     "Minimum 8 and Maximum 15 characters,at least include one Uppercase letter, one Lowercase letter, one Number and one Special character (@$!/#%*?&)",
                                            },
                                        }
                                    )}
                                </FormControl>
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <Button sx={{ mt: 3, ml: 3, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={onChangePasswordSubmit}>
                                        SUBMIT
                                    </Button>
                                </div>
                            </Container>
                        </Box>
                    </TabPanel>
                    {/* <TabPanel value={value} index={3}>
                        <h5><b>Bank Details</b></h5>
                        <Container>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { mt: 3, width: '40ch' },
                                    display: 'grid',
                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                    gap: 2,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl variant="standard">
                                    <TextField color="success" id="outlined-basic" label="Account Number" variant="outlined" />
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField color="success" id="outlined-basic" label="IFSC Code" variant="outlined" />
                                </FormControl>
                                <FormControl variant="standard">
                                    <TextField color="success" id="outlined-basic" label="Bank Name" variant="outlined" />
                                </FormControl>
                            </Box>
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success">
                                    SUBMIT
                                </Button>
                            </div>
                        </Container>
                    </TabPanel> */}
                </Box>
            </Container>
        </>
    );
}

export default MyAccount