import React, { useEffect, useRef, useState, useContext } from 'react';
import { Grid, Paper, Typography, TextField, Link, Button, Divider, Avatar } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import BreadCrumb from '../common/BreadCrumb';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slider from '@mui/material/Slider';
import { keyframes } from '@emotion/react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PercentIcon from '@mui/icons-material/Percent';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { RouletteWheel, RouletteTable } from 'react-casino-roulette';
import 'react-casino-roulette/dist/index.css';
import Rating from '@mui/material/Rating';
import { Rooms } from '../../services/socket/rooms';
import { roomsSelector } from '../../store/reducer/room';
import { loginSelector } from '../../store/reducer/user';
import { roulettesGameSelector } from '../../store/reducer/roulettes';
import Emitters from '../../services/socket/roulettes/emitters';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from "../websocket";
import { walletSelector } from '../../store/reducer/wallet';



function TabPanel({ value, index, children }) {
    return (
        <div hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function formatLabel(value) {
    const abbreviations = ['', 'k', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(value) / 3);
    const roundedValue = Math.round(value / Math.pow(10, magnitude * 3));
    return `${roundedValue}${abbreviations[magnitude]}`;
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;


const chipsMap = {
    whiteChip: {
        // icon: whiteChip,
        value: 1,
    },
    blueChip: {
        // icon: blueChip,
        value: 10,
    },
    blackChip: {
        // icon: blackChip,
        value: 100,
    },
    cyanChip: {
        // icon: cyanChip,
        value: 500,
    },
};

function Roulettes() {
    const { availableRooms } = useSelector(roomsSelector)
    const { sessionToken } = useSelector(loginSelector)
    const { userBalance } = useSelector(walletSelector);
    const { details, history, userBets, betss, betsCount } = useSelector(roulettesGameSelector)
    const [bets, setBets] = React.useState({});
    const [hoveredNumber, setHoveredNumber] = React.useState(null);
    const [value, setValue] = React.useState(0);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [noofbets, Setnoofbets] = React.useState('\u221E');
    const [onwinvalue, Setonwinvalue] = React.useState(0);
    const [onlosevalue, Setonlosevalue] = React.useState(0);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [betHistory, setBetHistory] = useState([]);
    const [activeChip, setActiveChip] = useState(Object.keys(chipsMap)[0]);
    const itemsPerPage = 3; // Number of items to display at a time
    const [isRouletteWheelSpinning, setIsRouletteWheelSpinning] = useState(false);
    const [rouletteWheelStart, setRouletteWheelStart] = useState(false);
    const [rouletteWheelBet, setRouletteWheelBet] = useState('-1');
    const ws = useContext(WebSocketContext);
    const data = ['1', '10', '100', '1k', '10k', '100k', '1M', '10M', '100M', '1B', '10B', '100B', '1T', '10T'];

    const imgLink =
        "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";


    const handleBet = ({ bet, payload, id }) => {
        if (!sessionToken) {
            toast.dismiss()
            toast.info('Login to place bets..')
            return
        }
        if (userBalance && userBalance.amount < payload.amount) {
            toast.dismiss()
            toast.info('Insufficient funds for placing a bet..')
            return
        }
        if (details && details.betPlaceStatus) {
            addBet(id);
         }
        // setIsRouletteWheelSpinning(true);
    };



  

    const addBet = (id) => {
        const { icon, value } = chipsMap[activeChip];

        setBetHistory((prevState) => [...prevState, { id, icon, value }]);

        setBets((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));

            if (state[id] !== undefined) {
                state[id] = {
                    ...state[id],
                    icon,
                    number: state[id].number + value,
                };
                return state;
            }

            state[id] = {
                icon,
                number: value,
            };

            return state;
        });
    }



    const displayedItems = data.slice(currentIndex, currentIndex + itemsPerPage);
    const [alignment, setAlignment] = React.useState('reset');
    const [alignment1, setAlignment1] = React.useState('reset');
    const [category, Setcategory] = React.useState('roulettes');
    const [tableevent, Settableevent] = React.useState('all');


    const handleChangetoggle = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleChangetoggle1 = (event, newAlignment) => {
        setAlignment1(newAlignment);
    };
    const handlecategoryevents = (event, newAlignment) => {
        Setcategory(newAlignment);
    };
    const handletableevents = (event, newAlignment) => {
        Settableevent(newAlignment);
    };



    useEffect(() => {
        if (Object.values(availableRooms).length) {
            Rooms.joinRoom(ws, { roomName: `${availableRooms && availableRooms.ROULETTE_GAME}` })
        }
    }, [availableRooms, sessionToken])

    useEffect(() => {
        return () => {
            Rooms.leaveRoom(ws, { roomName: `${availableRooms && availableRooms.ROULETTE_GAME}` })
        }
    }, [])


    useEffect(() => {
        if (rouletteWheelBet === '-1' || rouletteWheelStart === true) {
            return;
        }
        setRouletteWheelStart(true);
    }, [rouletteWheelBet, rouletteWheelStart]);

    useEffect(() => {
        if (isRouletteWheelSpinning === false) {
            return;
        }

        const prepare = async () => {
            const bet = details && details.numberVal
            setRouletteWheelStart(false);
            setRouletteWheelBet(bet);
            setIsRouletteWheelSpinning(false);
        };
        prepare();
    }, [isRouletteWheelSpinning]);




    const handleDoSpin = () => {
        setIsRouletteWheelSpinning(true);
    };

    const handleEndSpin = () => {
        setIsRouletteWheelSpinning(false);
    };

    useEffect(() => {
        if (sessionToken) {
            if (details && details.spin) {
                handleDoSpin()
                SetpayloadValue('');
                setBets({})
            }
        }
    }, [details])

    useEffect(() => {
        if (sessionToken) {
            if (details && details.gameStartWithin === 9) {
                Emitters(ws).placeBet({
                    amount: Payloadvalue.amount,
                    selectedValues: Payloadvalue.selectedValues,
                    betId: Payloadvalue.betId
                })
            }
        }
    }, [details])

    const [Payloadvalue, SetpayloadValue] = useState('');
    useEffect(() => {
        console.log(bets,'bets');
        if (bets["1ST_DOZEN"]) {
            var jsonData = {};
            var count = bets["1ST_DOZEN"].number;
            for (var i = 1; i <= 12; i++) {
                jsonData[i.toString()] = {
                    "number": count
                };
            } 
            Object.keys(bets).forEach(function(key) {
                if (key !== "1ST_DOZEN") {
                jsonData[key] = {
                    "number": bets[key].number
                };
            }
            });
            const selectedNumber = Object.keys(jsonData);
            var remainingSum = Object.values(jsonData).slice(12).reduce(function (acc, obj) {
                return acc + obj.number;
            }, 0);
            const payloadValue = {
                selectedValues: selectedNumber.map(Number),
                amount: remainingSum + count,
                betId: details && details._id
            };
            SetpayloadValue(payloadValue);
        } 
        else if (bets["2ND_DOZEN"]) {
            var jsonData = {};
            var count = bets["2ND_DOZEN"].number;
            for (var i = 13; i <= 24; i++) {
                jsonData[i.toString()] = {
                    "number": count
                };
            } 
            Object.keys(bets).forEach(function(key) {
                if (key !== "2ND_DOZEN") {
                jsonData[key] = {
                    "number": bets[key].number
                };
            }
            });
            const selectedNumber = Object.keys(jsonData);
            var remainingSum = Object.values(jsonData).slice(24).reduce(function (acc, obj) {
                return acc + obj.number;
            }, 0);
            const payloadValue = {
                selectedValues: selectedNumber.map(Number),
                amount: remainingSum + count,
                betId: details && details._id
            };
            SetpayloadValue(payloadValue);
        } 
        else if (bets["3RD_DOZEN"]) {
            var jsonData = {};
            var count = bets["3RD_DOZEN"].number;
            for (var i = 25; i <= 36; i++) {
                jsonData[i.toString()] = {
                    "number": count
                };
            } 
            Object.keys(bets).forEach(function(key) {
                if (key !== "3RD_DOZEN") {
                jsonData[key] = {
                    "number": bets[key].number
                };
            }
            });
            const selectedNumber = Object.keys(jsonData);
            var remainingSum = Object.values(jsonData).slice(36).reduce(function (acc, obj) {
                return acc + obj.number;
            }, 0);
            const payloadValue = {
                selectedValues: selectedNumber.map(Number),
                amount: remainingSum + count,
                betId: details && details._id
            };
            SetpayloadValue(payloadValue);
        } 
        else  {
            const selectedNumber = Object.keys(bets);
            const sum = Object.values(bets).reduce((acc, obj) => acc + obj.number, 0);
            const payloadValue = {
                selectedValues: selectedNumber.map(Number),
                amount: sum,
                betId: details && details._id
            };
            SetpayloadValue(payloadValue);
        }
    }, [bets, details])
   




    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
                <BreadCrumb
                    pages={[
                        {
                            name: 'Roulettes',
                            page_link: false,
                        },
                    ]}
                    sx={{ marginBottom: 15 }}
                />
                <br />
                <Grid container spacing={2}>
                    {/* Second Column */}
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={8}>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            {sessionToken == null ? '' : details == null ? 'No more bets. Please wait for next round..' : details && details.gameStartWithin === 0 ? "Bet's Closed" :
                                <span>Place Your Bets within{' '}<span style={{ color: 'red', margin: '0 5px' }}>{details && details.gameStartWithin}</span>{' '}sec's</span>
                            } </Grid>
                        <Paper elevation={0} sx={{ padding: '0px 8px', backgroundColor: '#040507' }}>
                            <Grid container spacing={1} style={{ marginTop: "10px", marginLeft: "10px" }}>
                                <RouletteWheel
                                    start={rouletteWheelStart}
                                    winningBet={rouletteWheelBet}
                                    onSpinningEnd={handleEndSpin}
                                />
                            </Grid>
                            <RouletteTable bets={bets} onBet={handleBet} />
                        </Paper>
                        <Box sx={{ marginTop: '70px', marginLeft: '25px' }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={category}
                                exclusive
                                onChange={handlecategoryevents}
                                aria-label="Platform">
                                <ToggleButton value="roulettes">Roulettes</ToggleButton>
                                <ToggleButton value="description">Description</ToggleButton>
                                <ToggleButton value="reviews" onClick={() => {
                                    setShowSpinner(true)
                                }}>Reviews</ToggleButton>
                            </ToggleButtonGroup>
                            <Typography>
                                {category === "roulettes" ?
                                    <>
                                        <br />
                                        <Typography><h4>Latest bet & Race</h4></Typography>
                                        <Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={tableevent}
                                                exclusive
                                                onChange={handletableevents}
                                                aria-label="Platform">
                                                <ToggleButton value="all">All bets</ToggleButton>
                                                <ToggleButton value="mybet">My bets</ToggleButton>
                                                <ToggleButton value="high">High rollers</ToggleButton>
                                                <ToggleButton value="wager">Wager contest</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Typography>
                                        {tableevent === 'all' ?
                                            <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Bet ID</TableCell>
                                                            <TableCell>Player</TableCell>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Payout</TableCell>
                                                            <TableCell>Profit</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>414291080788313985</TableCell>
                                                            <TableCell>Test</TableCell>
                                                            <TableCell>8.46:50 PM</TableCell>
                                                            <TableCell>1.36x</TableCell>
                                                            <TableCell>51.00</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer> : tableevent === 'mybet' ?
                                                <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
                                                    <Table>oops There is no data</Table>
                                                </TableContainer>
                                                : tableevent === 'high' ?
                                                    <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Game</TableCell>
                                                                    <TableCell>Player</TableCell>
                                                                    <TableCell>Bet Amount</TableCell>
                                                                    <TableCell>Multiplier</TableCell>
                                                                    <TableCell>Profit Amount</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>Roulette</TableCell>
                                                                    <TableCell>Test</TableCell>
                                                                    <TableCell>23.00</TableCell>
                                                                    <TableCell>1.36x</TableCell>
                                                                    <TableCell>51.00</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer> :
                                                    <><Box display="flex" flexDirection="row" gap={2} sx={{ marginTop: '30px' }}>
                                                        <Card sx={{ backgroundColor: '#494B58' }}>
                                                            <CardContent>
                                                                <Typography variant="h5" component="div">
                                                                    <EmojiEventsIcon sx={{ fontSize: 'large' }} />
                                                                    <span style={{ marginLeft: '20px' }}>Daily<br />
                                                                        <span style={{ marginLeft: '20px' }}>Contest prize pool</span><br />
                                                                        <span style={{ marginLeft: '20px' }}>$14,128.65</span></span>

                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                        <Card sx={{ backgroundColor: '#494B58' }}>
                                                            <CardContent>
                                                                <p>Who's the invincible champion at the high table?<br />
                                                                    Let's see what a badass you can be!</p>
                                                                <Button>Participate Now</Button>
                                                            </CardContent>
                                                        </Card>
                                                        <Card sx={{ backgroundColor: '#494B58' }}>
                                                            <CardContent>
                                                                Last Champion
                                                            </CardContent>
                                                        </Card>
                                                    </Box>
                                                        <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>#</TableCell>
                                                                        <TableCell>Player</TableCell>
                                                                        <TableCell>Wagered</TableCell>
                                                                        <TableCell>Prize</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>Roulette</TableCell>
                                                                        <TableCell>Hidden</TableCell>
                                                                        <TableCell>$23.00</TableCell>
                                                                        <TableCell>$70.36x(50%)</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer></>
                                        }</> : category === 'description' ?
                                        <Box sx={{ marginTop: '30px' }}>
                                            <Typography>
                                                <h2>About this game</h2>
                                            </Typography>

                                            Another exciting blockchain gambling game is <a target="_blank">Crash at BC.Game</a>.
                                            This crypto-based game is one of the simplest among all the blockchain casino games.
                                            This 2022 the number of crypto owners that have been playing Crash at BC.Game have increased to get the chance to
                                            increase the value of their digital currencies. <br /><br />
                                            If you are just starting to play crypto casinos, this game can be a good game for you because of its simplicity.
                                            Crash at BC.Game is one of the transparent and fairest ways to get rewards online. The game was developed with
                                            an easy-to-navigate user interface and exciting features that keep the players betting. <br /><br />
                                            It is an easy game to follow, and players do not need to follow certain instructions to play the game.
                                            The Crash game does not need to be downloaded, it can be played directly from a browser of a smartphone or computer.<br /><br />
                                            The Crash was one of the games lined up to be released when cryptocurrency was introduced to online casinos.
                                            If you are new to this, playing and betting are actually easy. You can even master the game by just playing a
                                            couple of rounds. Before you play, understand the game interface first since everything that is happening in
                                            the game is real-time.
                                            <Typography sx={{ marginTop: '30px' }}>
                                                <h2>Crash at BC.Game: Features</h2>
                                                <br />To start playing, you will be given time to pick a betting currency.
                                                The game is not limited to Bitcoin owners. It is also available to Etherium,
                                                Litecoin, and other digital currencies. If you are new, you can bet on the entry-level amount
                                                to avoid huge losses. You need to have a strategy for predicting where the line will crash.
                                                Remember to pick a spot before the line crashes. It can crash anytime, even if the game just
                                                started, so you need to pick the spot wisely. <br /><br />You will win the game once the line
                                                reaches your chosen spot or higher. Once the line crashes before it reaches your spot, you
                                                will lose. That is how easy this crypto game is. However, it is important to keep your
                                                instincts working. If you think it will crash before it reaches your chosen spot, you have
                                                the option to exit the game anytime. <br /><br />The Crash is a multiplayer game where you can
                                                choose between classic and Classic and Trenball modes. In classic mode, you are allowed to
                                                cash the money after betting. The amount you can earn will depend on how many players are
                                                betting on the round. For the Trenball mode, you will be required to bet on a line color
                                                which can be a factor in your winning.
                                            </Typography>
                                            <Typography sx={{ marginTop: '30px' }}>
                                                <h2>Crash in BC.Game: Final Verdict</h2>
                                                <br />If you are looking for an alternative crypto game, Crash is a great choice because it is
                                                fast-paced, and the rounds can end quickly if you want to play a new game once you lose, which
                                                adds more excitement to the game. If you are a beginner, you can try watching several rounds
                                                first or bet on a minimum amount.<br /><br />BC.Game has a user interface that provides enjoyment
                                                for every player, making it a top choice for crypto casino players. It also provides
                                                provably fair games and player privacy. This online casino website also offers promotions
                                                and limited bonuses that can increase your digital currency. <br />
                                            </Typography>
                                        </Box>
                                        :
                                        <>
                                            <Box>
                                                <Typography component="legend">Controlled</Typography>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                    }}
                                                />
                                            </Box>
                                            <h1>Comments</h1>
                                            <Paper style={{ padding: "40px 20px" }}>
                                                <Grid container wrap="nowrap" spacing={2}>
                                                    <Grid item>
                                                        <Avatar alt="Remy Sharp" src={imgLink} />
                                                    </Grid>
                                                    <Grid justifyContent="left" item xs zeroMinWidth>
                                                        <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
                                                        <p style={{ textAlign: "left" }}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                                                            luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                                                            Suspendisse congue vulputate lobortis. Pellentesque at interdum
                                                            tortor. Quisque arcu quam, malesuada vel mauris et, posuere
                                                            sagittis ipsum. Aliquam ultricies a ligula nec faucibus. In elit
                                                            metus, efficitur lobortis nisi quis, molestie porttitor metus.
                                                            Pellentesque et neque risus. Aliquam vulputate, mauris vitae
                                                            tincidunt interdum, mauris mi vehicula urna, nec feugiat quam
                                                            lectus vitae ex.{" "}
                                                        </p>
                                                        <p style={{ textAlign: "left", color: "gray" }}>
                                                            posted 1 minute ago
                                                        </p>
                                                        <IconButton color="primary">
                                                            <ThumbUpIcon />
                                                            <ReplyIcon style={{ marginLeft: '5px' }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
                                                <Grid container wrap="nowrap" spacing={2}>
                                                    <Grid item>
                                                        <Avatar alt="Remy Sharp" src={imgLink} />
                                                    </Grid>
                                                    <Grid justifyContent="left" item xs zeroMinWidth>
                                                        <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
                                                        <p style={{ textAlign: "left" }}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                                                            luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                                                            Suspendisse congue vulputate lobortis. Pellentesque at interdum
                                                            tortor. Quisque arcu quam, malesuada vel mauris et, posuere
                                                            sagittis ipsum. Aliquam ultricies a ligula nec faucibus. In elit
                                                            metus, efficitur lobortis nisi quis, molestie porttitor metus.
                                                            Pellentesque et neque risus. Aliquam vulputate, mauris vitae
                                                            tincidunt interdum, mauris mi vehicula urna, nec feugiat quam
                                                            lectus vitae ex.{" "}
                                                        </p>
                                                        <p style={{ textAlign: "left", color: "gray" }}>
                                                            posted 1 minute ago
                                                        </p>
                                                        <IconButton color="primary">
                                                            <ThumbUpIcon />
                                                            <ReplyIcon style={{ marginLeft: '5px' }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </>
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <br />
                </Grid>
            </Box>
        </>
    );
}

export default Roulettes;



// Side Columns
{/* <Grid item xs={12} md={3}>
                        <Card sx={{ width: 400, maxWidth: '100%', boxShadow: 'lg' }}>
                            <Paper elevation={0} sx={{
                                padding: '0px 8px', backgroundColor: '#494B58'
                            }}>
                                <Typography component="div" variant="h5" color="white">
                                    <Tabs value={value} onChange={handleChange} indicatorColor="primary"
                                        textColor="primary" aria-label="basic tabs example">
                                        <Tab label="Manual" sx={{ marginRight: '80px' }} />
                                        <Tab label="Auto" />
                                    </Tabs>
                                    <TabPanel value={value} index={0} sx={{ height: '100%' }}>
                                        <Typography>Chip value&nbsp;&nbsp;<CurrencyBitcoinIcon fontSize='small' style={{ color: '#FFBF39' }} />&nbsp;<CurrencyRupeeIcon fontSize='small' />36.41</Typography>
                                        <Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row', // Set to 'row' to display items horizontally
                                                    alignItems: 'center',
                                                    border: '1px solid white',
                                                    padding: '16px',
                                                    overflowX: 'auto',
                                                }}
                                            >
                                                <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                                {displayedItems.map((item, index) => (
                                                    <div key={index} style={{ marginLeft: '8px', marginRight: '8px' }}>
                                                        {item}
                                                    </div>
                                                ))}
                                                <IconButton onClick={handleNext} disabled={currentIndex >= data.length - itemsPerPage}>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Box>
                                        </Typography>

                                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                            Total Bit
                                            <span style={{ marginLeft: '185px' }}><DragHandleIcon /></span>
                                            0 BTC
                                        </Typography>
                                        <Typography>
                                            <Box sx={{
                                                border: '1px solid white',
                                            }}>
                                                <CurrencyRupeeIcon fontSize='small' sx={{ justifyContent: 'space-between', color: '#FFBF39' }} />0.000000000 &nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }}>1/2</Button>&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }}>2x</Button>
                                            </Box>
                                        </Typography>
                                        <Typography>
                                            <span sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100vh',
                                            }}>
                                                <Button
                                                    sx={{
                                                        animation: `${pulse} 1.5s infinite`,
                                                        backgroundColor: '#2A52BE',
                                                        color: 'white',
                                                        marginTop: '20px',
                                                        marginLeft: '90px'
                                                    }}
                                                >
                                                    Spin Now
                                                </Button></span>
                                        </Typography>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Typography>Chip value&nbsp;&nbsp;<CurrencyBitcoinIcon fontSize='small' style={{ color: '#FFBF39' }} />&nbsp;<CurrencyRupeeIcon fontSize='small' />36.41 </Typography>
                                        <Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row', // Set to 'row' to display items horizontally
                                                    alignItems: 'center',
                                                    border: '1px solid white',
                                                    padding: '16px',
                                                    overflowX: 'auto',
                                                }}
                                            >
                                                <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                                {displayedItems.map((item, index) => (
                                                    <div key={index} style={{ marginLeft: '8px', marginRight: '8px' }}>
                                                        {item}
                                                    </div>
                                                ))}
                                                <IconButton onClick={handleNext} disabled={currentIndex >= data.length - itemsPerPage}>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Box>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                            Total Bit
                                            <span style={{ marginLeft: '185px' }}><DragHandleIcon /></span>
                                            0 BTC
                                        </Typography>
                                        <Typography>
                                            <Box sx={{
                                                border: '1px solid white',
                                            }}>
                                                <CurrencyRupeeIcon fontSize='small' sx={{ justifyContent: 'space-between', color: '#FFBF39' }} />0.000000000 &nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }}>1/2</Button>&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }}>2x</Button>
                                            </Box>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            Number of Bets
                                        </Typography>
                                        <Typography>
                                            <Box sx={{
                                                border: '1px solid white',
                                            }}>
                                                <CurrencyRupeeIcon fontSize='small' sx={{ justifyContent: 'space-between', color: '#FFBF39' }} />{noofbets}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }} onClick={() => {
                                                    Setnoofbets('\u221E');
                                                }}>&#8734;</Button>&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }} onClick={() => {
                                                    Setnoofbets('10');
                                                }}>10</Button>&nbsp;&nbsp;
                                                <Button sx={{ backgroundColor: '#6b7180', color: 'white' }} onClick={() => {
                                                    Setnoofbets('100');
                                                }} >100</Button>
                                            </Box>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            On win
                                        </Typography>
                                        <Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={alignment}
                                                exclusive
                                                onChange={handleChangetoggle}
                                                aria-label="Platform">
                                                <ToggleButton value="reset">Reset</ToggleButton>
                                                <ToggleButton value="increase">Increase by</ToggleButton>
                                            </ToggleButtonGroup>&nbsp;&nbsp;
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '125px', height: '20px' }}
                                                value={onwinvalue}
                                                readOnly={alignment === 'reset' ? true : false}
                                                InputProps={{
                                                    endAdornment: (
                                                        <PercentIcon fontSize='small' style={{ color: '#FFBF39' }} />
                                                    ),
                                                }}
                                                onChange={(e) => {
                                                    if (alignment != 'reset') {
                                                        Setonwinvalue(e.target.value)
                                                    }
                                                }}
                                            />
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            On Lose
                                        </Typography>
                                        <Typography>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={alignment1}
                                                exclusive
                                                onChange={handleChangetoggle1}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton value="reset">Reset</ToggleButton>
                                                <ToggleButton value="increase">Increase by</ToggleButton>
                                            </ToggleButtonGroup>&nbsp;&nbsp;
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '125px', height: '20px' }}
                                                value={onlosevalue}
                                                InputProps={{
                                                    endAdornment: (
                                                        <PercentIcon fontSize='small' style={{ color: '#FFBF39' }} />
                                                    ),
                                                }}
                                                onChange={(e) => {
                                                    if (alignment1 != 'reset') {
                                                        Setonlosevalue(e.target.value)
                                                    }
                                                }}
                                            />
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            Stop on win
                                        </Typography>
                                        <Typography>
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <CurrencyBitcoinIcon fontSize='small' style={{ color: '#FFBF39' }} />
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                        <Typography sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            Stop on Lose
                                        </Typography>
                                        <Typography>
                                            <TextField
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <CurrencyBitcoinIcon fontSize='small' style={{ color: '#FFBF39' }} />
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                        <Typography>
                                            <span sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100vh',
                                            }}>
                                                <Button
                                                    sx={{
                                                        animation: `${pulse} 1.5s infinite`,
                                                        backgroundColor: '#2A52BE',
                                                        color: 'white',
                                                        marginTop: '20px',
                                                        marginLeft: '90px'
                                                    }}
                                                >
                                                    Start Auto Bet
                                                </Button></span>
                                        </Typography>
                                    </TabPanel>
                                </Typography>
                            </Paper>
                        </Card>
                    </Grid> */}