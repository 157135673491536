import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const namespace = "roulettes";

const initialState = {
    details: null,
    history: [],
    userBets: [],
    bets:[],
    betsCount:0
};

export const updateGameDetails = createAsyncThunk(
    `${namespace}/details`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const updateUserBets = createAsyncThunk(
    `${namespace}/user-bets`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const roulettesGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: (state) => {
            state.details = initialState.details
        }
    },
    extraReducers: {
        [updateGameDetails.fulfilled](state, { payload }) {
            if (payload?.gameStartWithin == 0)
                state.userBets = []
            state.details = payload
        },        
    },
});
export const { clearData } = roulettesGamesSlice.actions;

export const roulettesGameSelector = (state) => state.roulettesGames;

export default roulettesGamesSlice.reducer;
