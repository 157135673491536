import { useState, useEffect, useContext } from "react";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
    walletSelector
} from "../../store/reducer/wallet";
import { toast } from 'react-toastify'
import { ButtonGroup, Chip, Pagination, TableCell } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment'
import BreadCrumb from '../common/BreadCrumb'
import { Rooms } from '../../services/socket/rooms';
import { WebSocketContext } from "../websocket";
import { generateDiceDivId, getDiceImage, initialize } from "./Lib";
import { diceGameSelector, getdiceGameBets } from "../../store/reducer/dicegame";
import emitters from "../../services/socket/dice/emitters";
import { loginSelector } from "../../store/reducer/user";
import { roomsSelector } from "../../store/reducer/room";


const DemoPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    width: '100%',
    height: '100%',
    padding: '5px',
    margin: '5px',
    borderRadius: '10px',
    textAlign: 'center',
}));

const Item = styled(Paper)(({ theme }) => ({

    ...theme.typography.body2,
    padding: theme.spacing(1),
    backgroundImage: 'unset',
    textAlign: 'center',
}));

const theme = createTheme({
    components: {
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#ccc",
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: "#198754",
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: "#fff",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: "#198754",
                    },
                },
            },
        },
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, mt: -2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function DiceGame() {
    const ws = useContext(WebSocketContext);
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const limit = 10
    let loggedIn = localStorage.getItem('isAuthenticated');
    const [currentTab, setCurrentTab] = useState(0);
    const [payload, setPayload] = useState({
        numberVal: {
            one: null,
            two: null,
        },
        odds: 'under',
        amount: 5,
        winningAmount: 0,
        status: 'pending',
    })

    const [diceIds, setDiceIds] = useState({
        dice1: null,
        dice2: null,
        mobileDice1: null,
        mobileDice2: null
    })


    const { userBalance } = useSelector(walletSelector);
    const { availableRooms } = useSelector(roomsSelector)
    const { sessionToken } = useSelector(loginSelector)
    const { details, userBets, bets, betsCount } = useSelector(diceGameSelector);


    const playAgain = () => {
        setPayload({
            ...payload,
            numberVal: {
                one: null,
                two: null,
            },
            winningAmount: 0,
            status: 'pending',
        })
        betStateChange("place")
    }

    const newStack = () => {
        setPayload({
            ...payload,
            numberVal: {
                one: null,
                two: null,
            },
            winningAmount: 0,
            status: 'pending',
        })
    }


    const onChangeAddMinus = (type, amount) => {
        toast.dismiss()
        let tempPayload = payload
        if (type == "plus" && Number(tempPayload.amount) + amount > 1000) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        if (type == "minus" && Number(tempPayload.amount) - amount < 5) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }

        if (type == 'plus')
            tempPayload.amount = Number(tempPayload.amount) + amount
        if (type == 'minus')
            tempPayload.amount = Number(tempPayload.amount) - amount
        setPayload({
            ...payload,
            ...tempPayload
        })
    }




    const betStateChange = (betType) => {
        let tempPayload = payload
        if (betType == "place") {
            if (userBalance && userBalance.amount < tempPayload.amount) {
                toast.error("Insufficient wallet amount!")
            } else {
                setDiceIds({
                    ...diceIds,
                    dice1: generateDiceDivId(),
                    dice2: generateDiceDivId(),
                    mobileDice1: generateDiceDivId(),
                    mobileDice2: generateDiceDivId()
                })
                emitters(ws).placeBet({
                    amount: payload.amount,
                    odds: payload.odds
                })
            }
        }
    }

    useEffect(() => {
        initializeAllDices(diceIds)
    }, [diceIds])

    const initializeAllDices = async (diceIds) => {
        await Promise.all([
            initialize(diceIds.dice1, 50),
            initialize(diceIds.dice2, 50),
            initialize(diceIds.mobileDice1, 50),
            initialize(diceIds.mobileDice2, 50)
        ])
    }
    const handleChange = (event, newValue) => {
        if (!loggedIn && newValue == 1) {
            toast.dismiss()
            toast.info("Login to view my bets..")
            return
        } else if (newValue == 1) {
            getBetList({
                page: 1,
                limit
            })
        }
        setCurrentTab(newValue);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        getBetList({
            page: newPage,
            limit,
        })
    };
    const getBetList = ({
        page,
        limit,
    }) => {
        dispatch(
            getdiceGameBets({
                payload: {
                    page: page,
                    limit: limit,
                }
            })
        )
    }
    useEffect(() => {

        if (details && Object.values(details).length) {
            setPayload({
                ...payload,
                numberVal: {
                    one: details.numberVal.one,
                    two: details.numberVal.two,
                },
                winningAmount: details.winningAmount,
                status: details.status,
            })
            if (details.status == "failed" || details.status == "credited") {
                setDiceIds({
                    ...diceIds,
                    dice1: null,
                    dice2: null,
                    mobileDice1: null,
                    mobileDice2: null,
                })
            }
        }
    }, [details])

    useEffect(() => {
        if (Object.values(availableRooms).length) {
            Rooms.joinRoom(ws, { roomName: `${availableRooms && availableRooms.DICE_GAME}` })
        }
    }, [availableRooms, sessionToken])

    useEffect(() => {
        return () => {
            Rooms.leaveRoom(ws, { roomName: `${availableRooms && availableRooms.DICE_GAME}` })
        }
    }, [])





    return (
        <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
            <BreadCrumb pages={[
                {
                    name: 'Casino',
                    page_link: '/games/casino'
                },
                {
                    name: 'Dice',
                    page_link: false
                }
            ]}></BreadCrumb>
            <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={0} sx={{ padding: '0px 8px 0px 8px', backgroundColor: '#040507' }}>
                        <Typography component="div" variant="h7" >
                            Dice
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Paper sx={{
                height: { md: '85vh' },
                margin: { md: '40px 80px 0px 80px', xs: '0px' },
                backgroundColor: '#040507'
            }} elevation={0}>
                <Grid container sx={{ height: '100%', width: '100%' }}>
                    <Grid item md="7" sx={{ height: '100%', width: '100%', }}>
                        <Paper sx={{ width: '100%' }} elevation={1}>
                            <Grid container sx={{ display: { xs: 'none', md: 'flex' }, minHeight: '400px' }}>
                                <Grid item md={12} sx={{ display: { xs: 'none', md: 'flex' }, minHeight: '50px' }}>
                                </Grid>
                                <Grid item md={3}>
                                </Grid>
                                <Grid item md={3}>
                                    {diceIds.dice1 && (payload.status == "placed" || payload.status == "pending") ? <canvas id={diceIds.dice1} width="200" height="200" style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '150px',
                                        backgroundColor: '#393e41',
                                        padding: '20px'
                                    }}></canvas> : <img src={getDiceImage(payload?.numberVal?.one || 5)} style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '150px',
                                        backgroundColor: '#393e41',
                                        padding: '20px'
                                    }}></img>}
                                </Grid>
                                <Grid item md={3}>
                                    {diceIds.dice2 && (payload.status == "placed" || payload.status == "pending") ? <canvas id={diceIds.dice2} width="200" height="200" style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '150px',
                                        padding: '20px',
                                        backgroundColor: '#393e41',
                                    }}></canvas> : <img src={getDiceImage(payload?.numberVal?.two || 5)} style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '150px',
                                        backgroundColor: '#393e41',
                                        padding: '20px'
                                    }}></img>}
                                </Grid>
                                <Grid item md={3}>
                                </Grid>
                                <Grid item md={12} sx={{ display: { xs: 'none', md: 'flex' }, minHeight: '50px' }}>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ display: { xs: 'flex', md: 'none' }, padding: '15px' }}>
                                <Grid item xs={9}>
                                    {diceIds.mobileDice1 && (payload.status == "placed" || payload.status == "pending") ? <canvas id={diceIds.mobileDice1} width="200" height="200" style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '100px',
                                        backgroundColor: '#393e41',
                                    }}></canvas> : <img src={getDiceImage(payload?.numberVal?.one || 5)} style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '100px',
                                        backgroundColor: '#393e41',
                                        width: '75%'
                                    }}></img>}
                                </Grid>
                                <Grid item xs={3}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={3}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={9}>
                                    {diceIds.mobileDice2 && (payload.status == "placed" || payload.status == "pending") ? <canvas id={diceIds.mobileDice2} width="200" height="200" style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '100px',
                                        backgroundColor: '#393e41',
                                        float: 'right'
                                    }}></canvas> : <img src={getDiceImage(payload?.numberVal?.two || 5)} style={{
                                        border: '10px solid #00cd6dad',
                                        borderRadius: '100px',
                                        backgroundColor: '#393e41',
                                        float: 'right',
                                        width: '75%'
                                    }}></img>}
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            {payload.status == "pending" ? <Grid container>
                                <Grid item xs={1} md={2}>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{ backgroundColor: '#1b1c1d', borderRadius: '9px', margin: '1px' }}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Grid container>
                                                <DemoPaper variant="elevation" >
                                                    <Grid item xs={12} md={12}>
                                                        <Chip variant="filled" color={payload.odds == 'under' ? "success" : "default"} label="Under x2.3" sx={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => {
                                                            setPayload({
                                                                ...payload,
                                                                odds: 'under'
                                                            })
                                                        }}></Chip>
                                                        <Chip variant="filled" color={payload.odds == 'seven' ? "success" : "default"} label="Seven x5.8" sx={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => {
                                                            setPayload({
                                                                ...payload,
                                                                odds: 'seven'
                                                            })
                                                        }}></Chip>
                                                        <Chip variant="filled" color={payload.odds == 'over' ? "success" : "default"} label="Over x2.3" sx={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => {
                                                            setPayload({
                                                                ...payload,
                                                                odds: 'over'
                                                            })
                                                        }}></Chip>
                                                    </Grid>
                                                </DemoPaper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Grid container>
                                                <DemoPaper variant="elevation" >
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label="Amount"
                                                            value={payload.amount}
                                                            onChange={(e) => {
                                                                setPayload({
                                                                    ...payload,
                                                                    amount: Number(e.target.value)
                                                                })
                                                            }}
                                                            focused sx={{ width: '100%', color: '#00fe76' }}
                                                            placeholder="Rs."
                                                            variant="standard"
                                                            color="success" />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <ButtonGroup
                                                            size='small'
                                                            disableElevation
                                                            variant="outlined"
                                                            color="success"
                                                            sx={{ float: 'left', width: '100%', paddingTop: '4px' }}
                                                        >
                                                            <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => {
                                                                onChangeAddMinus("minus", 5)
                                                            }}>-5</Button>
                                                            <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                onChangeAddMinus("plus", 50)
                                                            }}>50</Button>
                                                            <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                onChangeAddMinus("plus", 100)
                                                            }}>100</Button>
                                                            <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                onChangeAddMinus("plus", 250)
                                                            }}>250</Button>
                                                            <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                onChangeAddMinus("plus", 500)
                                                            }}>500</Button>
                                                            <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => {
                                                                onChangeAddMinus("plus", 5)
                                                            }}>+5</Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </DemoPaper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Grid container >
                                                <DemoPaper variant="elevation" sx={{ cursor: 'pointer' }} onClick={() => {
                                                    if (!loggedIn) {
                                                        toast.dismiss();
                                                        toast.error('Please login to place bet!');
                                                        return;
                                                    }

                                                    betStateChange("place")
                                                }}>
                                                    <Grid item xs={12} md={12} >
                                                        <Button variant="text" color="success" >Spin Dice</Button>
                                                    </Grid>
                                                </DemoPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} md={2}>
                                </Grid>
                            </Grid> : ''}
                            {payload.status == "placed" ?
                                <Grid container >
                                    <DemoPaper variant="elevation">
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6" component="div" sx={{ color: '#00fe76' }}>Dice is spinning</Typography>
                                        </Grid>
                                    </DemoPaper>
                                </Grid>
                                : ''}
                            {payload.status == "failed" ?
                                <Grid container >
                                    <DemoPaper variant="elevation">
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6" component="div" color={"white"} >Better luck next time!</Typography>
                                            <Typography variant="subtitle2" component="div" color={"white"} >Try Again</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider></Divider>
                                            <br></br>
                                            <ButtonGroup
                                                orientation="horizontal"
                                                aria-label="vertical contained button group"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <Button onClick={() => {
                                                    playAgain()
                                                }}>Play Again ({payload.amount} ₹)</Button>
                                                <Button onClick={() => {
                                                    newStack()
                                                }}>New Stack</Button>
                                            </ButtonGroup>
                                        </Grid>

                                    </DemoPaper>
                                </Grid>
                                : ''}
                            {payload.status == "credited" ?
                                <Grid container >
                                    <DemoPaper variant="elevation">
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6" component="div" color={"green"} >Win!</Typography>
                                            <Typography variant="subtitle2" component="div" color={"white"} >You won {payload.winningAmount}₹</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Divider></Divider>
                                            <br></br>
                                            <ButtonGroup
                                                orientation="horizontal"
                                                aria-label="vertical contained button group"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <Button onClick={() => {
                                                    playAgain()
                                                }}>Play Again ({payload.amount} ₹)</Button>
                                                <Button onClick={() => {
                                                    newStack()
                                                }}>New Stack</Button>
                                            </ButtonGroup>
                                        </Grid>

                                    </DemoPaper>
                                </Grid>
                                : ''}
                        </Paper>

                    </Grid>
                    <Grid item md="5" sx={{ height: '100%', width: '100%' }}>
                        <Paper sx={{ height: '100%', width: '100%', margin: { md: '0px 0px 0px 15px', xs: '0px 0px 0px 0px' } }} elevation={2}>
                            <Box component="div">
                                <Tabs
                                    value={currentTab}
                                    onChange={handleChange}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: '#15c995'
                                        },
                                    }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    sx={{
                                        borderRight: 1, borderColor: 'divider',
                                        width: '100%'
                                    }}
                                >
                                    <Tab sx={{
                                        alignItems: 'flex-start !important',
                                        color: '#15c995 !important',
                                        "&:hover": {
                                            color: '#15c995'
                                        },
                                        fontWeight: 600
                                    }}
                                        label="Recent Bets" {...a11yProps(0)} />
                                    <Tab sx={{
                                        alignItems: 'flex-start !important',
                                        color: '#15c995 !important',
                                        "&:hover": {
                                            color: '#15c995'
                                        },
                                        fontWeight: 600
                                    }} label="My Bets" {...a11yProps(1)} />

                                </Tabs>
                                <TabPanel value={currentTab} index={0} >
                                    <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
                                        <Table sx={{ minWidth: { md: 650 } }} size="medium" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Player</TableCell>
                                                    <TableCell>Amount / Winning Amount</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userBets && userBets.map(item => {
                                                    return <TableRow
                                                        sx={{ border: 0 }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {item.username}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.status == 'credited' ? `₹ ${Number(item.amount).toFixed(2)} / ₹ ${Number(item.winningAmount).toFixed(2)}` : `₹ ${Number(item.amount).toFixed(2)}`}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                            {item.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                            {item.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                            {item.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                            {item.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value={currentTab} index={1} >
                                    <TableContainer component={Paper}>
                                        <Table size="medium" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Trade On</TableCell>
                                                    <TableCell>Amount / Winning Amount</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {bets && bets.map(item => {
                                                    return <TableRow
                                                        sx={{ border: 0 }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {moment(item.placedOn).format('DD-MM-YYYY')}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.odds.on == "under"?<Chip variant="filled" size="small" color="success" label="Under x2.3"></Chip>:''}
                                                            {item.odds.on == "seven"?<Chip variant="filled" size="small" color="success" label="Seven x5.8"></Chip>:''}
                                                            {item.odds.on == "over"?<Chip variant="filled" size="small" color="success" label="Over x2.3"></Chip>:''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.status == 'credited' ? `₹ ${Number(item.amount).toFixed(2)} / ₹ ${Number(item.winningAmount).toFixed(2)}` : `₹ ${Number(item.amount).toFixed(2)}`}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                            {item.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                            {item.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                            {item.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                            {item.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                                        </TableCell>

                                                    </TableRow>
                                                })}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                    <Pagination
                                        sx={{ marginTop: '10px' }}
                                        size="medium"
                                        variant="outlined"
                                        color="primary"
                                        count={Math.ceil(betsCount / 10)}
                                        page={page}
                                        onChange={handleChangePage}
                                    />
                                </TabPanel>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default DiceGame
