import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';



import { Button, Grid, Paper, Typography, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CasinoImage from '../../assets/images/casino.webp'
import { gamesList } from '../../services/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { gameSelector } from '../../store/reducer/games';
import IconBreadcrumbs from '../common/BreadCrumb';

const GameTypeItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 10,
    cursor: 'pointer',
}));

const GameNameItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
    padding: 5
}));




export default function Index() {

    const navigate = useNavigate()
    const params = useParams()
    const { games } = useSelector(gameSelector)
    const [gamesFilter, setGamesFilter] = useState([])

    useEffect(() => {
        let tempGames = games.filter((item) => item.gameType == params.type)
        setGamesFilter([...tempGames])
    }, [games, params.type])

    return (
        <>
            <Box component="main">
                <IconBreadcrumbs pages={[
                    {
                        name: params.type,
                        page_link: false
                    }
                ]}></IconBreadcrumbs>
                <Grid container sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={0} sx={{ padding: '4px 8px 4px 8px', backgroundColor: '#040507' }}>
                            <Typography component="div" variant="h7" textTransform={"capitalize"}>
                                {params.type}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                {gamesFilter && gamesFilter.length ? <Grid container sx={{ height: '100%', width: '100%' }}>
                    {gamesFilter.map((item) => {
                        return <Grid item md="1.5" xs="6">
                            <GameTypeItem onClick={() => {
                                if (item.status == 1)
                                    navigate(`/${item.website_url}`)
                                else toast.info("Coming Soon..")
                            }} sx={{
                                backgroundColor: '#1b1c1d82',
                                "&:hover": {
                                    border: '1px solid #1b1c1d'
                                }
                            }}>
                                <img src={item.image} style={{
                                    width: '100%',
                                    height: '120px',
                                }}
                                ></img>
                                <GameNameItem className="name-item" sx={{ backgroundColor: '#1b1c1d00' }}>
                                    <Grid container>
                                        <Grid item md="12" xs="12" textAlign={"center"}>
                                            {item.status == 2 ? <Button color="inherit" disabled variant="text" size="small">
                                                Coming Soon
                                            </Button> : <Button color="success" variant="text" size="small">
                                                Play Now
                                            </Button>}
                                        </Grid>
                                    </Grid>
                                </GameNameItem>
                            </GameTypeItem>
                        </Grid>
                    })}
                </Grid> : <div
                    style={{
                        background: "linear-gradient(to bottom right,#303d39,#353535)",
                        height: "150px",
                        marginLeft: "10px",
                        textAlign: 'center',
                        position: 'relative',
                        padding: '30px 15px',
                        borderRadius: '5px',
                        marginTop: '30px'
                    }}
                >
                    <p style={{ textAlign: "center", marginBottom: "50px" }}>
                        Coming Soon...!
                    </p>
                </div>}

            </Box>
        </>
    );
}

