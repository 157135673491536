import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MyBets from "../mybets/mybets";
import { Button, Fab, Toolbar } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

export default function BetsDrawer({
    drawerOpen
}) {
    let [openBetsDrawer, setOpenBetsDrawer] = drawerOpen
    return (
        <Drawer
            anchor="right"
            sx={{
                display: { xs: 'flex', md: 'flex' },
                width: { xs: '100%', md: "40%" },
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: { xs: '100%', md: "40%" }, boxSizing: 'border-box' },
            }}
            open={openBetsDrawer}
        >
            <Box sx={{ marginTop: '70px' }}>
                {/* <Toolbar sx={{ minHeight: { xs: '60px', md: '0px' }, display: { xs: 'flex', md: 'none' } }} /> */}
                <Fab size="small" color="success" variant='extended' sx={{ margin: '0px 0px 7px 7px' }} onClick={() => {
                    setOpenBetsDrawer(false)
                }}>
                    <ArrowBack /> My Bets
                </Fab>
                <Divider></Divider>
                <MyBets drawer={true}></MyBets>
            </Box>

        </Drawer>
    );
}