import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate, useHref } from "react-router-dom";
import React, { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
//import PrivateRoute from './PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import ResponsiveAppBar from './view/header'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/home';
import InPlay from './components/inplay';
import InPlayEvent from './components/inplay/event';
import teal from '@mui/material/colors/teal';
import JoinNow from './view/joinnow';
import Mybets from './components/mybets/mybets';
import Transactions from './components/wallet/transactions';
import {
  getSessions,
  loginSelector,
  logOut
} from "./store/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import Deposit from './components/wallet/Deposit';
import MyAccount from './components/common/MyAccount';
import Banks from './components/wallet/Banks';
import CricketX from './components/cricketX/CricketX';
import { BottomNavigation, BottomNavigationAction, Grid, Paper, Toolbar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCurrentPage, getPages } from './config';
import DiceGame from './components/Dice';
import Roulettes from './components/Roulettes/Roulettes';
import FastParity from './components/FastParity';
import Games from './components/Games';
import { getGames } from './store/reducer/games';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  verticalAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  backgroundColor: 'unset',
}));
const darkTheme = createTheme({
  typography: {
    fontFamily: 'system-ui',
  },
  palette: {
    mode: 'dark',
    background: {
      default: "#040507",
      paper: "#040507",

    }
  }
});
function App() {
  let pages = getPages()

  const dispatch = useDispatch();
  let loggedIn = localStorage.getItem('isAuthenticated');

  useEffect(() => {
    if (loggedIn) {
      dispatch(getSessions({}))
    }
  }, [])

  const RouteComponent = ({
    Component,
    ...props
  }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let [currentPage, setCurrentPage] = useState('/')
    const history = useHref()
    useEffect(() => {
      let page = getCurrentPage(history)
      setCurrentPage(page)
    }, [history])

    const GamesListDispatch = async () => {
      dispatch(getGames({
        payload: {
          page: 1,
          limit: 1000,
          additionalPayload: {
            website: true
          }
        }
      }))
    }
    useEffect(() => {
      GamesListDispatch()
    }, [])

    return <>
      <Component {...props}>
      </Component>
      <Paper sx={{ display: { xs: 'block', md: 'none' }, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={16}>
        <BottomNavigation
          sx={{
            backgroundColor: '#494949'
          }}
          value={currentPage}
          onChange={(event, newValue) => {
            if (newValue == 'back') {
              window.history.back()
            } else {
              setCurrentPage(newValue);
              navigate(newValue)
            }

          }}
        >
          <BottomNavigationAction value='back' icon={<ArrowBackIcon />} />
          {pages && pages.length && pages.map((item, i) => {
            return <BottomNavigationAction value={item.value} icon={<item.icon />} />
          })}
        </BottomNavigation>
      </Paper>
    </>
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <ThemeProvider theme={darkTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <ResponsiveAppBar></ResponsiveAppBar>
            <Routes>
              <Route exact path="/" element={<RouteComponent Component={Home}></RouteComponent>} />
              <Route exact path="/in-play" element={<RouteComponent Component={InPlay} home={false}></RouteComponent>} />
              <Route exact path="/in-play-event/:game/:event_id" element={<RouteComponent Component={InPlayEvent}></RouteComponent>} />
              <Route exact path="/joinnow" element={<RouteComponent Component={JoinNow}></RouteComponent>} />
              <Route exact path="/my-bets" element={<RouteComponent Component={Mybets}></RouteComponent>} />
              <Route exact path="/transactions" element={<RouteComponent Component={Transactions}></RouteComponent>} />
              <Route exact path="/deposit" element={<RouteComponent Component={Deposit}></RouteComponent>} />
              <Route exact path="/my-account" element={<RouteComponent Component={MyAccount}></RouteComponent>} />
              <Route exact path="/banks" element={<RouteComponent Component={Banks}></RouteComponent>} />
              <Route exact path="/game" element={<RouteComponent Component={CricketX}></RouteComponent>} />
              <Route exact path="/dice-game" element={<RouteComponent Component={DiceGame}></RouteComponent>} />
              <Route exact path="/roulettes-game" element={<RouteComponent Component={Roulettes}></RouteComponent>} />
              <Route exact path="/fastparity" element={<RouteComponent Component={FastParity}></RouteComponent>} />
              <Route exact path="/games/:type" element={<RouteComponent Component={Games}></RouteComponent>} />
            </Routes>
          </Box>
        </ThemeProvider>
      </Router>
    </Fragment>
  );
}

export default App;
