import { toast } from "react-toastify";
import { updateGameDetails, updateUserBets } from "../../../store/reducer/dicegame";
import { listeners } from "./emitter-listener-types"

export default ({
    socket,
    dispatch,
    res
}) => {
    let { data, error, message, type, statusCode } = res
    switch (type) {
        case "dice-game-push-data":
            dispatch(updateGameDetails(data))
            break;
        case "dice-game-user-bets":
            dispatch(updateUserBets(data))
            break;
    }

}
