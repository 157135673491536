import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    doLogin,
    loginSelector,
    logOut,
    registerNow,
} from "../../store/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils/constants";
import {
    getBalance,
    getTransaction,
    getPaymentQR,
    walletSelector,
    uploadQRtransaction
} from "../../store/reducer/wallet";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import tronImg from "../../assets/images/tron2.png";
import razorImg from "../../assets/images/razor4.png";
import cashfreeImg from "../../assets/images/Cashfree_Logo.jpg";
import qrImg from "../../assets/images/qr-code.png";
import bitcoinIMg from "../../assets/images/bitcoin.png";
import paytmImg from "../../assets/images/paytm.png";
import gpayImg from "../../assets/images/gpay.png";
import upiImg from "../../assets/images/upi.png";
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 530,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Uploadstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 450,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function PaymentMethods() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formValidator = useRef(new SimpleReactValidator());
    const [modalopen, setmodalopen] = useState(false);
    const [uploadopen, setuploadopen] = useState(false);
    const [alertModal, setalertModal] = useState(false);
    const [open, setOpen] = useState(true);
    const [payment_qr, setpayment_qr] = useState('');
    const { balanceloading, transactionloading, userBalance, transactionlist, paymentQR, uploadQRloading } = useSelector(walletSelector);
    const [value, setValue] = useState(0);
    const [transactionUpload, setTransactionUpload] = useState({ qr_img: '', qr_path: '', amount: '', utr_no: '' });
    const [AddFormSubmit, setAddFormSubmit] = useState(false)

    const handleChange = (event, newValue) => {
        console.log(newValue, 'tabs=');
        setValue(newValue);
    };

    const uploadOpenFunc = () => setuploadopen(true);
    const uploadClose = () => {
        setuploadopen(false); setAddFormSubmit(false); setTransactionUpload({ qr_img: '', qr_path: '', amount: '', utr_no: '' })
    }

    const handleClose = () => {
        setOpen(false);
        navigate("/");
    };

    const ModalOpenFunc = () => setmodalopen(true);
    const ModalClose = () => setmodalopen(false);

    const AlertOpen = () => setalertModal(true);
    const AlertClose = () => setalertModal(false);

    const handleFileUpload = (e) => {
        e.preventDefault();

        const image = e.target.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Select Valid Image - (jpg,jpeg,png)')
            return false;
        } else {
            const reader = new FileReader();
            const file = e.target.files[0];

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                const binaryStr = reader.result
                setTransactionUpload({
                    ...transactionUpload,
                    qr_path: binaryStr,
                    qr_img: file.name
                })
                // setfileUpload(binaryStr)
                // setFileName(file.name)
            }
            reader.readAsDataURL(file);
        }
    }


    const qrTrasanctionSubmit = () => {
        setAddFormSubmit(true);

        let postData = transactionUpload;

        if (formValidator.current.allValid()) {
            dispatch(uploadQRtransaction({ postData }));
            // toast.success('QR Transaction uploaded successfully.!');
            setuploadopen(false)
            setTransactionUpload({
                amount: '',
                qr_img: '',
                qr_path: '',
                utr_no: ''
            })
        }
    }

    useEffect(() => {
        console.log(uploadQRloading, "uploadQRloading")
        if (uploadQRloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success('QR Transaction uploaded successfully.!');
        }
        if (uploadQRloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('Upload Failed! Please check uploaded File');
        }
    }, [uploadQRloading]);

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (paymentQR) {
            setpayment_qr(paymentQR)
        }
    }, [paymentQR])

    useEffect(() => {
        let payload = {
            pincode: "641023"
        }
        dispatch(getPaymentQR({
            payload
        }))
    }, [])
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.up('md'));

    return (
        <div style={{width:'100%'}}>
            <Container maxWidthwidth="100%" sx={{p:0,pt:2}}>
                <>
                    <div>
                        <Typography variant="h6" >ACCOUNT <b>617500231</b></Typography>
                        <Typography variant="body2" >Select payment method to top up your account:</Typography>
                    </div>
                    { matches ? 
                    <Box
                    sx={{ flexGrow: 1,width:'100%', bgcolor: 'background.paper', display: 'flex', height: 280, margin: '10px' }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#198754",
                            },
                        }}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider', width: value == 0 ? '360px' : '205px' }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: (value == 0) ? '#15c995 !important' : '',
                            fontWeight: (value == 0) ? 600 : ''
                        }} label="All Methods" {...a11yProps(0)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 1 ? '#15c995 !important' : '',
                            fontWeight: (value == 1) ? 600 : ''
                        }} label="QR Scanner" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 2 ? '#15c995 !important' : '',
                            fontWeight: (value == 2) ? 600 : ''
                        }} label="CashFree Payment" {...a11yProps(2)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 3 ? '#15c995 !important' : '',
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="CRYPTOCURRENCY" {...a11yProps(3)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 4 ? '#15c995 !important' : '',
                            fontWeight: (value == 4) ? 600 : ''
                        }} label="RazorPay" {...a11yProps(4)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <h5 style={{ paddingBottom: '10px' }}><b>All Methods</b></h5>
                        <Grid container spacing={3}>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={qrImg} style={{ width: "37%",  padding: "5%" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 14, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={ModalOpenFunc}
                                        >
                                            <b>SCAN WITH QR</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={cashfreeImg} style={{ width: "100%", padding: "5%"}} />
                                    </div>
                                    <div style={{ marginTop: '16%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={razorImg} style={{ width: "73%", padding: "5%" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="small" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={tronImg} style={{ width: "60%", padding: "5%"}} />
                                    </div>
                                    <div style={{ marginTop: '8%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={bitcoinIMg} style={{ width: "45%", marginLeft: "36px", marginTop: '8%' }} />
                                    </div>
                                    <div style={{ marginTop: '4%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={upiImg} style={{ width: "95%", padding: '2px' }} />
                                    </div>
                                    <div style={{ marginBottom: '6%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={paytmImg} style={{ width: "98%", padding: "7px", marginTop: '12%' }} />
                                    </div>
                                    <div style={{ marginTop: '8%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3} >
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={gpayImg} style={{ width: "95%", padding: "5px", marginTop: '8%' }} />
                                    </div>
                                    <div style={{ marginTop: '5%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h5 style={{ paddingBottom: '10px' }}><b>QR Scanner</b></h5>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={qrImg} style={{ width: "50%", marginLeft: "25%", marginTop: '8%', padding: "6px" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 14, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={ModalOpenFunc}
                                        >
                                            <b>SCAN WITH QR</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <h5 style={{ paddingBottom: '10px' }}><b>CashFree Payment</b></h5>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={cashfreeImg} style={{ width: "100%", padding: "6px", marginTop: '15%' }} />
                                    </div>
                                    <div style={{ marginTop: '16%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <h5 style={{ paddingBottom: '10px' }}><b>TRON</b></h5>
                        <Grid container spacing={2}>
                        <Grid item xs={4} md={3}>
                            <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                    <img src={tronImg} style={{ width: "100%", padding: "8px", marginTop: '12%' }} />
                                </div>
                                <div style={{ marginTop: '8%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <h5 style={{ paddingBottom: '10px' }}><b>RazorPay</b></h5>
                        <Grid container spacing={2}>
                        <Grid item xs={4} md={3}>
                            <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                    <img src={razorImg} style={{ width: "100%", padding: "6px" }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    {/* <Grid container rowSpacing={2} columnSpacing={{ xs: 6, sm: 6, md: 1 }}>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <div>
                                    <img src={qrImg} style={{ width: "50%", marginLeft: "25%", marginTop: '8%' }} />
                                </div>
                                <div style={{ marginTop: '9%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={ModalOpenFunc}
                                    >
                                        <b>SCAN WITH QR</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <div>
                                    <img src={cashfreeImg} style={{ width: "100%", padding: "6px", marginTop: '25%' }} />
                                </div>
                                <div style={{ marginTop: '18%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <img src={razorImg} style={{ width: "100%", padding: "6px", marginTop: '25%' }} />
                                <div style={{ marginTop: '18%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <img src={tronImg} style={{ width: "100%", padding: "8px", marginTop: '21%' }} />
                                <div style={{ marginTop: '11%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid> */}
                </Box>
                    :
                    <Box
                    sx={{
                        flexGrow: 1,
                        maxWidth: { xs: 320, sm: 480 },
                        bgcolor: 'background.paper',
                      }}
                >
                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#198754",
                            },
                        }}
                        aria-label="Vertical tabs example"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: (value == 0) ? '#15c995 !important' : '',
                            fontWeight: (value == 0) ? 600 : ''
                        }} label="All Methods" {...a11yProps(0)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 1 ? '#15c995 !important' : '',
                            fontWeight: (value == 1) ? 600 : ''
                        }} label="QR Scanner" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 2 ? '#15c995 !important' : '',
                            fontWeight: (value == 2) ? 600 : ''
                        }} label="CashFree Payment" {...a11yProps(2)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 3 ? '#15c995 !important' : '',
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="CRYPTOCURRENCY" {...a11yProps(3)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            color: value == 4 ? '#15c995 !important' : '',
                            fontWeight: (value == 4) ? 600 : ''
                        }} label="RazorPay" {...a11yProps(4)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <h5 style={{ paddingBottom: '10px' }}><b>All Methods</b></h5>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={qrImg} style={{ width: "50%", marginLeft: "25%", marginTop: '8%', padding: "6px" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 14, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={ModalOpenFunc}
                                        >
                                            <b>SCAN WITH QR</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={cashfreeImg} style={{ width: "100%", padding: "6px", marginTop: '15%' }} />
                                    </div>
                                    <div style={{ marginTop: '16%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={razorImg} style={{ width: "100%", padding: "6px" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={tronImg} style={{ width: "100%", padding: "8px", marginTop: '12%' }} />
                                    </div>
                                    <div style={{ marginTop: '8%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={bitcoinIMg} style={{ width: "45%", marginLeft: "36px", marginTop: '8%' }} />
                                    </div>
                                    <div style={{ marginTop: '4%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={upiImg} style={{ width: "95%", padding: '2px' }} />
                                    </div>
                                    <div style={{ marginBottom: '6%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={paytmImg} style={{ width: "98%", padding: "7px", marginTop: '12%' }} />
                                    </div>
                                    <div style={{ marginTop: '8%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={gpayImg} style={{ width: "95%", padding: "5px", marginTop: '8%' }} />
                                    </div>
                                    <div style={{ marginTop: '5%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h5 style={{ paddingBottom: '10px' }}><b>QR Scanner</b></h5>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={qrImg} style={{ width: "50%", marginLeft: "25%", marginTop: '8%', padding: "6px" }} />
                                    </div>
                                    <div >
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 14, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={ModalOpenFunc}
                                        >
                                            <b>SCAN WITH QR</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <h5 style={{ paddingBottom: '10px' }}><b>CashFree Payment</b></h5>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                    <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img src={cashfreeImg} style={{ width: "100%", padding: "6px", marginTop: '15%' }} />
                                    </div>
                                    <div style={{ marginTop: '16%' }}>
                                        <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                            onClick={AlertOpen}
                                        >
                                            <b>PAY</b>
                                        </Button>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <h5 style={{ paddingBottom: '10px' }}><b>TRON</b></h5>
                        <Grid container spacing={2}>
                        <Grid item xs={4} sm={3}>
                            <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                    <img src={tronImg} style={{ width: "100%", padding: "8px", marginTop: '12%' }} />
                                </div>
                                <div style={{ marginTop: '8%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <h5 style={{ paddingBottom: '10px' }}><b>RazorPay</b></h5>
                        <Grid container spacing={2}>
                        <Grid item xs={4} sm={3}>
                            <Card sx={{ maxWidth: '100%', height: 150, background: 'white',display:'flex',flexDirection:'column', justifyContent:'space-between' }}>
                                <div class="payment-img" style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                    <img src={razorImg} style={{ width: "100%", padding: "6px" }} />
                                </div>
                                <div>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        </Grid>
                    </TabPanel>
                    {/* <Grid container rowSpacing={2} columnSpacing={{ xs: 6, sm: 6, md: 1 }}>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <div>
                                    <img src={qrImg} style={{ width: "50%", marginLeft: "25%", marginTop: '8%' }} />
                                </div>
                                <div style={{ marginTop: '9%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={ModalOpenFunc}
                                    >
                                        <b>SCAN WITH QR</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <div>
                                    <img src={cashfreeImg} style={{ width: "100%", padding: "6px", marginTop: '25%' }} />
                                </div>
                                <div style={{ marginTop: '18%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <img src={razorImg} style={{ width: "100%", padding: "6px", marginTop: '25%' }} />
                                <div style={{ marginTop: '18%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card sx={{ maxWidth: 200, height: 200 }}>
                                <img src={tronImg} style={{ width: "100%", padding: "8px", marginTop: '21%' }} />
                                <div style={{ marginTop: '11%' }}>
                                    <Button variant="contained" color="success" size="large" sx={{ fontSize: 15, width: '100%', padding: '20px', backgroundColor: '#198754', color: 'white' }}
                                        onClick={AlertOpen}
                                    >
                                        <b>PAY</b>
                                    </Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid> */}
                </Box>
                    }
                </>
                <Modal
                    open={modalopen}
                    onClose={ModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <IconButton
                            aria-label="close"
                            onClick={ModalClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: "grey",
                                "&:hover": {
                                    color: '#15c995'

                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Divider sx={{ color: 'black', margin: '10px' }} />
                        {payment_qr ? <img src={payment_qr} height={300} width={350} style={{ margin: '10px' }} /> : ''}
                        <Divider sx={{ color: 'black', marginTop: '10px' }} />
                        <Typography variant="h5" sx={{ color: 'black', marginLeft: '25%', paddingTop: '15px' }}><b>Scan QR To Pay</b></Typography>
                        <Divider sx={{ color: 'black', margin: '10px' }} />
                        <Button
                            variant="contained"
                            color="success"
                            component="label"
                            style={{ marginLeft: '15%' }}
                            onClick={uploadOpenFunc}
                            startIcon={<FileUploadOutlinedIcon />}
                        >
                            Upload QR Transactions
                            {/* <input
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                            /> */}
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={uploadopen}
                    onClose={uploadClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box component="form" sx={Uploadstyle}>
                        <IconButton
                            aria-label="close"
                            onClick={uploadClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: "grey",
                                "&:hover": {
                                    color: '#15c995'

                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput1" class="form-label">Enter Amount<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" class="form-control" name="amount" id="exampleFormControlInput1" placeholder="Amount Rs" onChange={(e) => {
                                setTransactionUpload({
                                    ...transactionUpload,
                                    amount: e.target.value
                                })
                            }} />
                            {formValidator.current.message(
                                'amount',
                                transactionUpload.amount,
                                'required|numeric',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Amount',
                                        numeric: 'Please Enter Number',
                                    }
                                }
                            )}
                        </div>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput1" class="form-label">Unique Transaction Reference(UTR) No<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" class="form-control" name="utr_no" id="exampleFormControlInput1" placeholder="UTR No" onChange={(e) => {
                                setTransactionUpload({
                                    ...transactionUpload,
                                    utr_no: e.target.value
                                })
                            }} />
                            {formValidator.current.message(
                                'utr_no',
                                transactionUpload.utr_no,
                                'required',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter UTR No',
                                    }
                                }
                            )}
                        </div>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput2" class="form-label">Upload QR Transactions<span style={{ color: 'red' }}>*</span></label>
                            <Button
                                variant="contained"
                                color="success"
                                component="label"
                                sx={{ width: '43ch' }}
                                id="exampleFormControlInput2"
                                startIcon={transactionUpload.qr_path ? <DownloadDoneRoundedIcon /> : <FileUploadOutlinedIcon />}
                            >
                                {transactionUpload.qr_path ? 'Uploaded' : 'Upload'}
                                <input
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    hidden
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            {formValidator.current.message(
                                'image',
                                transactionUpload.qr_path,
                                'required',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Upload Screenschot',
                                    }
                                }
                            )}
                        </div>
                        <Divider sx={{ color: 'black', marginTop: '30px' }} />
                        <button type="button" class="offset-md-4 col-md-4 mt-3 btn btn-success" onClick={qrTrasanctionSubmit}>Submit</button>
                    </Box>
                </Modal>
                <Snackbar open={alertModal} autoHideDuration={3000} onClose={AlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={AlertClose} severity="info" sx={{ width: '100%' }}>
                        This Feature is <b>coming soon!..</b>
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    )
}

export default PaymentMethods
