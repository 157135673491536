import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Breadcrumbs, Button, ButtonGroup, Chip, Grid, LinearProgress, Pagination, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, styled } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { toast } from 'react-toastify';
import { Rooms } from '../../services/socket/rooms';
import { roomsSelector } from '../../store/reducer/room';
import { loginSelector } from '../../store/reducer/user';
import { fastparityGameSelector, getFastParityGameBets } from '../../store/reducer/fastpartity';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from "../websocket";
import SimpleDialog from './dialog';
import Emitters from '../../services/socket/fastparity/emitters';
import { walletSelector } from '../../store/reducer/wallet';
import IconBreadcrumbs from '../common/BreadCrumb';
import moment from 'moment';
const ColorItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 100,
    lineHeight: '60px',
    margin: 5
}));

const NumberItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 40,
    margin: 5
}));

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const DemoPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    width: '100%',
    height: '100%',
    padding: '5px',
    margin: '5px',
    borderRadius: '10px',
    textAlign: 'center',
}));

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ width: '100%', backgroundColor: '#111214' }}>
            <Typography
                padding={1}
                fontWeight={300}
                fontSize={16}
                variant="subtitle2"
                sx={{ backgroundColor: '#111214', animation: 'animate 1.5s linear infinite' }}>
                {props.betPlaceStatus ? `Place your bets with in ${props.gameStartWithin - 4} seconds` : 'No more bets'}
            </Typography>
            <LinearProgress color={props.betPlaceStatus ? "success" : "warning"} variant="determinate" {...props} />
        </Box>
    );
}

const records = (history, numberVal) => {
    let array = []
    for (let item of history) {
        const randomVal = item.numberVal
        let color = item.color
        array.push(<Grid item xs={1.5} md={0.75} sx={{ margin: '5px 0px 0px 0px' }}>
            <Chip sx={{
                fontSize: 17,
                fontWeight: 'bold',
                backgroundColor: color
            }} label={randomVal}></Chip>
        </Grid>)
    }
    return <Box style={{ maxHeight: '160px', overflow: 'auto', margin: 2 }}><Grid container>
        {!numberVal ? <Grid item xs={1.5} md={0.75} sx={{ margin: '5px 0px 0px 0px' }}>
            <Chip color="default" label={'?'} sx={{
                fontSize: 17,
                fontWeight: 'bold',
                color:'white'
            }}></Chip>
        </Grid> : ''}
        {
            array.map(item => item)
        }
    </Grid></Box>
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, mt: -2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Index() {
    const { availableRooms } = useSelector(roomsSelector)
    const { sessionToken } = useSelector(loginSelector)
    const { details, history, userBets, bets, betsCount } = useSelector(fastparityGameSelector)
    const { userBalance } = useSelector(walletSelector);
    const [currentTab, setCurrentTab] = useState(0);
    let loggedIn = localStorage.getItem('isAuthenticated');
    const ws = useContext(WebSocketContext);
    const [page, setPage] = useState(1)
    const limit = 10
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        amount: 5,
        type: '',
        value: ''
    })
    const [winningAmountModalOpen, setWinningAmountModalOpen] = useState(false)
    const onValueChange = (e) => {
        toast.dismiss()
        let tempPayload = payload
        if (e.target.value > 1000) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        tempPayload.amount = e.target.value
        setPayload({
            ...payload,
            ...tempPayload
        })
    }

    const onChangeAddMinus = (type, amount) => {
        toast.dismiss()
        let tempPayload = payload
        if (type == "plus" && Number(tempPayload.amount) + amount > 1000) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        if (type == "minus" && Number(tempPayload.amount) - amount < 5) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        if (type == 'plus')
            tempPayload.amount = Number(tempPayload.amount) + amount
        if (type == 'minus')
            tempPayload.amount = Number(tempPayload.amount) - amount
        setPayload({
            ...payload,
            ...tempPayload
        })
    }

    const placeBet = (details, payload) => {
        if (!sessionToken) {
            toast.dismiss()
            toast.info('Login to place bets..')
            return
        }
        if (userBalance && userBalance.amount < payload.amount) {
            toast.dismiss()
            toast.info('Insufficient funds for placing a bet..')
            return
        }
        if (details?.betPlaceStatus && details?._id)
            Emitters(ws).placeBet({
                type: payload.type,
                amount: payload.amount,
                value: payload.value,
                betId: details?._id
            })
        else {
            toast.dismiss()
            toast.error('No more bets. Please wait for next round..')
        }
    }

    const handleChange = (event, newValue) => {
        if (!loggedIn && newValue == 1) {
            toast.dismiss()
            toast.info("Login to view my bets..")
            return
        } else if (newValue == 1) {
            getFastParityGameBetsList({
                page: 1,
                limit
            })
        }
        setCurrentTab(newValue);
    };


    const getFastParityGameBetsList = ({
        page,
        limit,
    }) => {
        dispatch(
            getFastParityGameBets({
                payload: {
                    page: page,
                    limit: limit,
                }
            })
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        getFastParityGameBetsList({
            page: newPage,
            limit,
        })
    };

    useEffect(() => {
        if (details?.gameStartWithin == 0) {
            setWinningAmountModalOpen(true)
            setTimeout(() => {
                setWinningAmountModalOpen(false)
            }, 1500);
        }
    }, [details])

    useEffect(() => {
        if (Object.values(availableRooms).length) {
            Rooms.joinRoom(ws, { roomName: `${availableRooms && availableRooms.FAST_PARITY_GAME}` })
        }
    }, [availableRooms, sessionToken])

    useEffect(() => {
        return () => {
            Rooms.leaveRoom(ws, { roomName: `${availableRooms && availableRooms.FAST_PARITY_GAME}` })
        }
    }, [])



    return (
        <>

            <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
                <IconBreadcrumbs pages={[
                    {
                        name: 'Casino',
                        page_link: '/games/casino'
                    },
                    {
                        name: 'Fast Parity',
                        page_link: false
                    }
                ]}></IconBreadcrumbs>
                <Grid container sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={0} sx={{ padding: '4px 8px 4px 8px', backgroundColor: '#040507' }}>
                            <Typography component="div" variant="h7" >
                                Fast Parity
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Paper sx={{
                    height: { md: '85vh' },
                    margin: { md: '40px 80px 0px 80px', xs: '0px' },
                    backgroundColor: '#040507'
                }} elevation={0}>
                    <SimpleDialog
                        color={details?.color}
                        numberVal={details?.numberVal}
                        open={winningAmountModalOpen}
                    />
                    <Grid container sx={{ height: '100%', width: '100%' }}>
                        <Grid item md="7" sx={{ height: '100%', width: '100%', }}>
                            <Paper sx={{ height: { md: '45%', xs: '48%' }, width: '100%' }} elevation={1}>
                                <Box component="div" sx={{ padding: { md: '20px 40px 0px 40px', xs: '2px' } }}>
                                    <Grid container>
                                        <Grid item md="6" sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <Typography variant="inherit" color={"white"}>Period</Typography>
                                        </Grid>
                                        <Grid item md="6" sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <Typography variant="inherit" sx={{ float: 'right' }} color={"white"}>Countdown</Typography>
                                        </Grid>
                                        <Grid item md="6" sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <Typography variant="inherit" fontSize={"small"} fontWeight={"bold"} color={"white"}>{details?.hash}</Typography>
                                        </Grid>

                                        <Grid item md="6" sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <Typography variant="inherit" fontSize={"small"} fontWeight={"bold"} sx={{ float: 'right' }} color={"white"}>{details?.gameStartWithin}s</Typography>
                                        </Grid>
                                        <Grid item md="4" xs="4" onClick={() => {
                                            setPayload({
                                                ...payload,
                                                type: 'color',
                                                value: 'green'
                                            })
                                            placeBet(details, {
                                                ...payload,
                                                type: 'color',
                                                value: 'green'
                                            })
                                        }}>
                                            <ColorItem elevation={0} sx={{
                                                backgroundColor: '#00cd6d40',
                                                '&:hover': {
                                                    border: '1px solid #00cd6d',
                                                }
                                            }}>
                                                <RocketLaunchIcon sx={{
                                                    fontSize: '50px',
                                                    '&:hover': {
                                                        fontSize: '55px',
                                                    }
                                                }} color="success"></RocketLaunchIcon>
                                                <br></br>
                                                <Typography variant="h6" color={"#00cd6d"}>{`Join Green`}</Typography>
                                            </ColorItem>
                                        </Grid>
                                        <Grid item md="4" xs="4" onClick={() => {
                                            setPayload({
                                                ...payload,
                                                type: 'color',
                                                value: 'violet'
                                            })
                                            placeBet(details, {
                                                ...payload,
                                                type: 'color',
                                                value: 'violet'
                                            })
                                        }}>
                                            <ColorItem elevation={0} sx={{
                                                backgroundColor: '#e273d729',
                                                '&:hover': {
                                                    border: '1px solid #e273d7',
                                                }
                                            }}>
                                                <RocketLaunchIcon sx={{
                                                    fontSize: '50px',
                                                    '&:hover': {
                                                        fontSize: '55px',
                                                    }
                                                }} color="secondary"></RocketLaunchIcon>
                                                <br></br>
                                                <Typography variant="h6" color={"#e273d7"}>{`Join Violet`}</Typography>
                                            </ColorItem>
                                        </Grid>
                                        <Grid item md="4" xs="4" onClick={() => {
                                            setPayload({
                                                ...payload,
                                                type: 'color',
                                                value: 'red'
                                            })
                                            placeBet(details, {
                                                ...payload,
                                                type: 'color',
                                                value: 'red'
                                            })
                                        }}>
                                            <ColorItem elevation={0} sx={{
                                                backgroundColor: '#ff002933',
                                                '&:hover': {
                                                    border: '1px solid #ff0029',
                                                }
                                            }}>
                                                <RocketLaunchIcon sx={{
                                                    fontSize: '50px',
                                                    '&:hover': {
                                                        fontSize: '55px',
                                                    }
                                                }} color="error"></RocketLaunchIcon>
                                                <br></br>
                                                <Typography variant="h6" color={"#ff0029"}>{`Join Red`}</Typography>
                                            </ColorItem>
                                        </Grid>
                                        <Grid item md="4" xs="4">
                                            <Typography variant="subtitle1" textAlign={"center"} color={"white"}>{`1.95X`}</Typography>
                                        </Grid>
                                        <Grid item md="4" xs="4">
                                            <Typography variant="subtitle1" textAlign={"center"} color={"white"}>{`4.5X`}</Typography>
                                        </Grid>
                                        <Grid item md="4" xs="4">
                                            <Typography variant="subtitle1" textAlign={"center"} color={"white"}>{`1.95X`}</Typography>
                                        </Grid>
                                        <br></br>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(item => {
                                            return <Grid item md="2.4" xs="6" onClick={() => {
                                                setPayload({
                                                    ...payload,
                                                    type: 'number',
                                                    value: item
                                                })
                                                placeBet(details, {
                                                    ...payload,
                                                    type: 'number',
                                                    value: item
                                                })
                                            }}>
                                                <NumberItem elevation={0} sx={{
                                                    backgroundColor: '#232325d9',
                                                    padding: { md: '10px', xs: '5px' },
                                                    '&:hover': {
                                                        fontWeight: 'bold',
                                                        backgroundColor: '#ffffff2e'
                                                    }
                                                }}>
                                                    {item}
                                                </NumberItem>
                                            </Grid>
                                        })}
                                        <Grid item md="12" xs="12">
                                            <Typography variant="subtitle1" textAlign={"center"} color={"white"}>{`9X`}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                            <LinearProgressWithLabel
                                value={(details && details?.gameStartWithin / 15) * 100}
                                betPlaceStatus={details && details?.betPlaceStatus}
                                gameStartWithin={details && details?.gameStartWithin}
                            />
                            <Paper sx={{ height: '50%', width: '100%', padding: { md: '15px 0px 0px 0px', xs: '2px 0px 0px 0px' } }} elevation={8}>
                                <Box component="div">
                                    <Grid container>
                                        <Grid item xs={12} md={12} sx={{ margin: '10px' }}>
                                            <Grid container>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12}>
                                                            <Typography marginLeft={"8px"} color={"gray"} variant="subtitle2">Amount</Typography>
                                                        </Grid>
                                                        <DemoPaper variant="elevation" >
                                                            <Grid item xs={12} md={12}>
                                                                <TextField
                                                                    id="standard-basic"
                                                                    value={payload.amount}
                                                                    onChange={(e) => { onValueChange(e) }}
                                                                    focused sx={{ width: '100%', color: '#00fe76' }}
                                                                    placeholder="Rs."
                                                                    variant="standard"
                                                                    color="success" />
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <ButtonGroup
                                                                    size='small'
                                                                    disableElevation
                                                                    variant="outlined"
                                                                    color="success"
                                                                    sx={{ float: 'left', width: '100%', paddingTop: '4px' }}
                                                                >
                                                                    <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => {
                                                                        onChangeAddMinus("minus", 5)
                                                                    }}>-5</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                        onChangeAddMinus("plus", 50)
                                                                    }}>50</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                        onChangeAddMinus("plus", 100)
                                                                    }}>100</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                        onChangeAddMinus("plus", 250)
                                                                    }}>250</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => {
                                                                        onChangeAddMinus("plus", 500)
                                                                    }}>500</Button>
                                                                    <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => {
                                                                        onChangeAddMinus("plus", 5)
                                                                    }}>+5</Button>
                                                                </ButtonGroup>
                                                            </Grid>
                                                        </DemoPaper>
                                                        <Grid item xs={12} md={12}>
                                                            <Typography marginLeft={"8px"} color={"gray"} variant="subtitle2">Fast Parity Record</Typography>
                                                        </Grid>
                                                        <DemoPaper variant="elevation" elevation={12} >
                                                            {records(history, details?.numberVal)}
                                                        </DemoPaper>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md="5" sx={{ height: '100%', width: '100%' }}>
                            <Paper sx={{ height: '100%', width: '100%', margin: { md: '0px 0px 0px 15px', xs: '0px 0px 0px 0px' } }} elevation={2}>
                                <Box component="div">
                                    <Tabs
                                        value={currentTab}
                                        onChange={handleChange}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: '#15c995'
                                            },
                                        }}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        sx={{
                                            borderRight: 1, borderColor: 'divider',
                                            width: '100%'
                                        }}
                                    >
                                        <Tab sx={{
                                            alignItems: 'flex-start !important',
                                            color: '#15c995 !important',
                                            "&:hover": {
                                                color: '#15c995'
                                            },
                                            fontWeight: 600
                                        }}
                                            label="Current Stakes" {...a11yProps(0)} />
                                        <Tab sx={{
                                            alignItems: 'flex-start !important',
                                            color: '#15c995 !important',
                                            "&:hover": {
                                                color: '#15c995'
                                            },
                                            fontWeight: 600
                                        }} label="My Bets" {...a11yProps(1)} />

                                    </Tabs>
                                    <TabPanel value={currentTab} index={0} >
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: { md: 650 } }} size="medium" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Player</TableCell>
                                                        <TableCell align="right">Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {userBets && userBets.map(item => {
                                                        return <TableRow
                                                            sx={{ border: 0 }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {item.username}
                                                            </TableCell>
                                                            <TableCell align="right">{`₹ ${Number(item.amount).toFixed(2)}`}</TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1} >
                                        <TableContainer component={Paper}>
                                            <Table size="medium" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Trade On</TableCell>
                                                        <TableCell>Amount / Winning Amount</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bets && bets.map(item => {
                                                        return <TableRow
                                                            sx={{ border: 0 }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {item.betDetails?.hash}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {moment(item.placedOn).format('DD-MM-YYYY')}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {item.type == "color" ? <Chip size="small" sx={{
                                                                    backgroundColor: item.value
                                                                }} label={item.value}></Chip> : <Chip size="small" label={`Number ${item.value}`}></Chip>}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {item.status == 'credited' ? `₹ ${Number(item.amount).toFixed(2)} / ₹ ${Number(item.winningAmount).toFixed(2)}` : `₹ ${Number(item.amount).toFixed(2)}`}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {item.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                                {item.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                                {item.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                                {item.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                                {item.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                                            </TableCell>

                                                        </TableRow>
                                                    })}
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                        <Pagination
                                            sx={{ marginTop: '10px' }}
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            count={Math.ceil(betsCount / 10)}
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </TabPanel>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>

            </Box>

        </>
    );
}

