import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { walletBalance, getTransactionList, paymentQRlist, uploadQR, QRtransactionList, addwithdrawRequest, withdrawList } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
const namespace = "wallet";

const initialState = {
    balanceloading: "initial",
    transactionloading: "initial",
    paymentQRloading: "initial",
    uploadQRloading: "initial",
    listQRloading: "initial",
    withdrawlistLoading: "initial",
    withdrawReqloading: "initial",
    userBalance: null,
    transactionlist: null,
    transactionlistCount: 0,
    paymentQR: null,
    uploadQRlist: null,
    withdrawlist: null,
    withdrawlistCount: 0,
    uploadQRlistCount: 0
};

export const getBalance = createAsyncThunk(
    `${namespace}/getBalance`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await walletBalance();
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const walletAmountUpdate = createAsyncThunk(
    `${namespace}/walletAmountUpdate`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getTransaction = createAsyncThunk(
    `${namespace}/getTransaction`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getTransactionList({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getPaymentQR = createAsyncThunk(
    `${namespace}/getPaymentQR`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await paymentQRlist({ payload: payload });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const uploadQRtransaction = createAsyncThunk(
    `${namespace}/uploadQRtransaction`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await uploadQR({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const listQRtransaction = createAsyncThunk(
    `${namespace}/listQRtransaction`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await QRtransactionList({ payload: payload });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getwithdrawList = createAsyncThunk(
    `${namespace}/getwithdrawList`,
    async ({ payload, userID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(userID,'userId==');
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await withdrawList({ payload: payload, userID });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const withdrawRequest = createAsyncThunk(
    `${namespace}/withdrawRequest`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await addwithdrawRequest({ payload: payload });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const walletSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        // clearAboutDeleteLoading: (state) => {
        //     state.deleteloading = "initial";
        // },
        // clearErrormsg: (state) => {
        //     state.errorMessage = null;
        // }
    },
    extraReducers: {
        [getBalance.pending](state) {
            state.balanceloading = API_STATUS.PENDING;
        },
        [getBalance.fulfilled](state, { payload }) {
            state.balanceloading = API_STATUS.FULFILLED;
            state.userBalance = payload?.data.data.wallet
        },
        [walletAmountUpdate.fulfilled](state, { payload }) {
            state.balanceloading = API_STATUS.FULFILLED;
            state.userBalance = {
                amount: payload.amount
            }
        },
        [getBalance.rejected](state, action) {
            state.balanceloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getTransaction.pending](state) {
            state.transactionloading = API_STATUS.PENDING;
        },
        [getTransaction.fulfilled](state, { payload }) {
            state.transactionloading = API_STATUS.FULFILLED;
            state.transactionlist = payload?.data.data.result
            state.transactionlistCount = payload?.data.data.count
        },
        [getTransaction.rejected](state, action) {
            state.transactionloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getPaymentQR.pending](state) {
            state.paymentQRloading = API_STATUS.PENDING;
        },
        [getPaymentQR.fulfilled](state, { payload }) {
            state.paymentQRloading = API_STATUS.FULFILLED;
            state.paymentQR = payload?.data.data.result_sample
            console.log({ payload });
        },
        [getPaymentQR.rejected](state, action) {
            state.paymentQRloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [uploadQRtransaction.pending](state) {
            state.uploadQRloading = API_STATUS.PENDING;
        },
        [uploadQRtransaction.fulfilled](state) {
            state.uploadQRloading = API_STATUS.FULFILLED;
        },
        [uploadQRtransaction.rejected](state, action) {
            state.uploadQRloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [listQRtransaction.pending](state) {
            state.listQRloading = API_STATUS.PENDING;
        },
        [listQRtransaction.fulfilled](state, { payload }) {
            state.listQRloading = API_STATUS.FULFILLED;
            state.uploadQRlist = payload?.data.data.result
            state.uploadQRlistCount = payload?.data.data.count
        },
        [listQRtransaction.rejected](state, action) {
            state.listQRloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getwithdrawList.pending](state) {
            state.withdrawlistLoading = API_STATUS.PENDING;
        },
        [getwithdrawList.fulfilled](state, { payload }) {
            state.withdrawlistLoading = API_STATUS.FULFILLED;
            state.withdrawlist = payload?.data.data.result
            state.withdrawlistCount = payload?.data.data.count
        },
        [getwithdrawList.rejected](state, action) {
            state.withdrawlistLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [withdrawRequest.pending](state) {
            state.withdrawReqloading = API_STATUS.PENDING;
        },
        [withdrawRequest.fulfilled](state) {
            state.withdrawReqloading = API_STATUS.FULFILLED;
        },
        [withdrawRequest.rejected](state, action) {
            state.withdrawReqloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData } = walletSlice.actions;

export const walletSelector = (state) => state.wallet;

export default walletSlice.reducer;
