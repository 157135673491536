import React, {
    useEffect,
    useState,
    useContext,
    useRef,
    useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { Box, Toolbar, Divider, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    getBalance,
    getTransaction,
    getPaymentQR,
    walletSelector
} from "../../store/reducer/wallet";
import { API_STATUS } from "../../utils/constants";
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TablePaginationActions from "../../utils/pagination";

function Transactions({ profileTab }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [walletBalance, setwalletBalance] = useState({ walletID: '', balance: '' });
    const [transactionDatas, settransactionDatas] = useState([]);
    const { balanceloading, transactionloading, userBalance, transactionlist, transactionlistCount } = useSelector(walletSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [porfilePage, setporfilePage] = useState(false);
    let [drawerOpen, setdrawerOpen] = useState(false);
    let [limit, setlimit] = useState(10);

    useEffect(() => {
        // if (loggedIn) {
        dispatch(getBalance({}))
        // }
    }, [])

    const transactionDispatch = () => {
        let postData = {
            walletId: walletBalance.walletID,
            paginateOptions: {
                limit: rowsPerPage,
                page: page + 1
            }
        }
        dispatch(getTransaction({
            postData
        }))
    };

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        transactionDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        transactionDispatch()
    };

    useEffect(() => {
        if (userBalance) {
            setwalletBalance({
                ...walletBalance,
                walletID: userBalance._id,
                balance: userBalance.amount
            }
            )
            let postData = {
                walletId: userBalance._id,
                paginateOptions: {
                    limit: rowsPerPage,
                    page: page + 1
                }
            }
            dispatch(getTransaction({
                postData
            }))
        }
    }, [userBalance])

    console.log(transactionlist, 'transactionlist==');
    useEffect(() => {
        if (transactionlist) {
            if (transactionlist) {
                settransactionDatas(transactionlist)
            }
        }
    }, [transactionlist])

    useEffect(() => {
        console.log(transactionloading, "balanceloading")
        if (transactionloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('No transactions found!');
        }
    }, [transactionloading]);


    useEffect(() => {
        if (profileTab == true) {
            setporfilePage(true)
            setdrawerOpen(false)

        } else {
            setporfilePage(false)
            setdrawerOpen(true)
        }
    }, [profileTab])

    return (
        // <Drawer
        //     variant="permanent"
        //     open={porfilePage ? false : drawerOpen}
        //     anchor="right"
        //     sx={{
        //         width: 550,
        //         flexShrink: 0,
        //         padding: 10,
        //         [`& .MuiDrawer-paper`]: { width: 550, boxSizing: 'border-box' },
        //     }}
        // >
        <Box sx={{marginTop:{xl:0,lg:0,md:0,sm:0,xs:5}}}>
            {porfilePage ? '' : <Typography variant="h6" ><b>My Transcations</b></Typography>}
            <div className="col-md-12">
                <div className={porfilePage ? "card p-2" : ''}>
                    <TableContainer
                        sx={{
                            height: '100%',
                            width: '100%', 
                            overflowX: 'scroll'
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="">
                                        <TableSortLabel hideSortIcon={true}>
                                            <span style={{ fontSize: porfilePage ? '14px' : '' }}>Amount</span>
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel hideSortIcon={true}>
                                            <span style={{ fontSize: porfilePage ? '14px' : '' }}>Transaction</span>
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel hideSortIcon={true}>
                                            <span style={{ fontSize: porfilePage ? '14px' : '' }}>Type</span>
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel hideSortIcon={true}>
                                            <span style={{ fontSize: porfilePage ? '14px' : '' }}>Date</span>
                                        </TableSortLabel>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionDatas && transactionDatas.length ? transactionDatas.map((item) => {
                                    return (
                                        <StyledTableRow >
                                            <StyledTableCell align="">
                                                {item.type == 'credit' ? <span style={{ color: '#29b589', fontSize: porfilePage ? '13px' : '' }}>+{item.amount}</span> : <span style={{ color: 'red', fontSize: porfilePage ? '13px' : '' }}>-{item.amount}</span>}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {item.referenceType ?
                                                    <span style={{ color: porfilePage ? 'black' : '', fontSize: porfilePage ? '13px' : '' }}>{item.referenceType}</span>
                                                    : (item.type == 'credit') ? <span style={{ color: porfilePage ? 'black' : '', fontSize: porfilePage ? '13px' : '' }}>Deposit</span> : <span style={{ color: porfilePage ? 'black' : '' }}>-</span>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <span style={{ color: porfilePage ? 'black' : '', fontSize: porfilePage ? '12px' : '' }}>{item.type.toUpperCase()}</span>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <span style={{ color: porfilePage ? 'black' : '', fontSize: porfilePage ? '12px' : '' }}>{dayjs(item.transactionAt).format('DD-MM-YYYY hh:mm:ss A')}</span>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                }) : ''
                                }
                            </TableBody>
                            {porfilePage ? '' : <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        colSpan={8}
                                        count={transactionlistCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>}
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Box>
        // </Drawer>
    )
}

export default Transactions