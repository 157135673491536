import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    walletSelector,
    withdrawRequest
} from "../../store/reducer/wallet";
import { API_STATUS } from "../../utils/constants";
import SimpleReactValidator from "simple-react-validator";
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";

function WithdrawRequest({ walletBalance }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userID = localStorage.getItem('userID')
    const formValidator = useRef(new SimpleReactValidator());
    const withdrawValidator = useRef(new SimpleReactValidator());
    const upiValidator = useRef(new SimpleReactValidator());
    const [withdrawRadioValue, setwithdrawRadioValue] = useState('');
    const [withDrawReq, setwithDrawReq] = useState({
        userId: userID ? userID : '',
        amount: '',
        isUpi: false,
        isBank: false,
        upiId: '',
        bankaddr: {
            ifsc_code: '',
            holder_name: '',
            account_no: '',
        }
    });
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [withdrawFormSubmit, setwithdrawFormSubmit] = useState(false)
    const [upiFormSubmit, setupiFormSubmit] = useState(false)
    const { withdrawReqloading } = useSelector(walletSelector);

    console.log(withDrawReq, 'withDrawReq=');

    const withdrawSubmit = () => {
        setwithdrawFormSubmit(true);
        if (withDrawReq.isBank) {
            setAddFormSubmit(true)
        } else {
            setupiFormSubmit(true)
        }

        let payload = withDrawReq;

        if (withdrawValidator.current.allValid() && (upiValidator.current.allValid() || formValidator.current.allValid())) {
            dispatch(withdrawRequest({ payload }));
            setwithdrawFormSubmit(false)
            setAddFormSubmit(false)
            setupiFormSubmit(false)
            setwithDrawReq({
                userId:userID,
                amount: '',
                isUpi: false,
                isBank: false,
                upiId: '',
                bankaddr: {
                    ifsc_code: '',
                    holder_name: '',
                    account_no: '',
                }
            })
        }
    }


    const withdrawRadioChange = (event) => {
        if (event.target.value == 'upi') {
            setwithDrawReq({
                ...withDrawReq,
                isUpi: true,
                isBank: false,
            })
        } else {
            setwithDrawReq({
                ...withDrawReq,
                isBank: true,
                isUpi: false
            })
        }

        setwithdrawRadioValue(event.target.value);
    }

    useEffect(() => {
        console.log(withdrawReqloading, "withdrawReqloading")
        if (withdrawReqloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success('Withdraw Requested successfully.!');
        }
        if (withdrawReqloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('Withdraw Requested! Please check bank details');
        }
    }, [withdrawReqloading]);

    useEffect(() => {
        formValidator.current.showMessages()
        upiValidator.current.showMessages()
        withdrawValidator.current.showMessages()
    }, [])

    return (
        <div>
            <div>
                <div style={{ float: 'left' }}>
                    <h5><b>Withdraw Request</b></h5>
                </div>
                <div style={{ marginLeft: '60%' }}>
                    <Chip label={`Withdrawable : Rs ${parseFloat(walletBalance).toFixed(2)}`} color="success" icon={<InfoOutlinedIcon />} variant="outlined" />
                </div>
            </div>
            <Container fixed>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { mt: 3, width: '45ch' },
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr 1fr' },
                        gap: 2,
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <FormControl variant="standard">
                        <TextField color="success" name='amount' id="outlined-basic" label="Withdraw Amount" variant="outlined" placeholder="Enter Amount Rs" value={withDrawReq.amount} onChange={(e) => {
                            setwithDrawReq({
                                ...withDrawReq,
                                amount: e.target.value
                            })
                        }} />
                        {withdrawValidator.current.message(
                            'amount',
                            withDrawReq.amount,
                            'required|numeric',
                            {
                                className: `invalid_feedback
														  ${withdrawFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Please Enter Amount',
                                    numeric: 'Please Enter Number',
                                }
                            }
                        )}
                    </FormControl>
                </Box>
                <h5 style={{ marginTop: '3%' }}>Choose Mode of Payment</h5>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={withdrawRadioValue}
                    sx={{ marginTop: 2 }}
                    onChange={withdrawRadioChange}
                >
                    <FormControlLabel value="account" control={<Radio color="success" />} label="Bank Account" />
                    <FormControlLabel value="upi" control={<Radio color="success" />} label="UPI" />
                </RadioGroup>
                {withdrawRadioValue == 'upi' ?
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                // display: 'grid',
                                // gridTemplateColumns: { sm: '1fr 1fr' },
                                // gap: 8,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl variant="standard">
                                <TextField color="success" id="outlined-basic" name="upi" label="UPI ID" variant="outlined" value={withDrawReq.upiId} onChange={(e) => {
                                    setwithDrawReq({
                                        ...withDrawReq,
                                        upiId: e.target.value
                                    })
                                }} />
                            </FormControl>
                            {upiValidator.current.message(
                                'upi',
                                withDrawReq.upiId,
                                'required',
                                {
                                    className: `invalid_feedback
														  ${upiFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter UPI ID',
                                    }
                                }
                            )}
                        </Box>
                    </div>
                    :
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { mt: 3, width: '40ch' },
                            display: 'grid',
                            gridTemplateColumns: { sm: '1fr 1fr' },
                            gap: 2,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl variant="standard">
                            <TextField color="success" name='accountno' id="outlined-basic" label="Account Number" variant="outlined" value={withDrawReq.bankaddr.account_no} onChange={(e) => {
                                setwithDrawReq({
                                    ...withDrawReq,
                                    bankaddr: {
                                        ...withDrawReq.bankaddr,
                                        account_no: e.target.value
                                    }
                                })
                            }} />
                            {formValidator.current.message(
                                'accountno',
                                withDrawReq.bankaddr.account_no,
                                'required',
                                {
                                    className: `invalid_feedback
                                                       ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Account Number',
                                    }
                                }
                            )}
                        </FormControl>
                        <FormControl variant="standard">
                            <TextField color="success" name="ifsc" id="outlined-basic" label="IFSC Code" variant="outlined" value={withDrawReq.bankaddr.ifsc_code} onChange={(e) => {
                                setwithDrawReq({
                                    ...withDrawReq,
                                    bankaddr: {
                                        ...withDrawReq.bankaddr,
                                        ifsc_code: e.target.value
                                    }
                                })
                            }} />
                            {formValidator.current.message(
                                'ifsc',
                                withDrawReq.bankaddr.ifsc_code,
                                'required',
                                {
                                    className: `invalid_feedback
                                                       ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter IFSC Code',
                                    }
                                }
                            )}
                        </FormControl>
                        <FormControl variant="standard">
                            <TextField color="success" name="holder" id="outlined-basic" label="Account Holder Name" variant="outlined" value={withDrawReq.bankaddr.holder_name} onChange={(e) => {
                                setwithDrawReq({
                                    ...withDrawReq,
                                    bankaddr: {
                                        ...withDrawReq.bankaddr,
                                        holder_name: e.target.value
                                    }
                                })
                            }} />
                            {formValidator.current.message(
                                'holder',
                                withDrawReq.bankaddr.holder_name,
                                'required',
                                {
                                    className: `invalid_feedback
                                                       ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Account Holder Name',
                                    }
                                }
                            )}
                        </FormControl>
                    </Box>
                }
                <div className="row" style={{ justifyContent: 'center' }}>
                    <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={withdrawSubmit}>
                        SUBMIT REQUEST
                    </Button>
                </div>
            </Container>
        </div>
    )
}

export default WithdrawRequest
