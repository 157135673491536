import { emitters } from "./emitter-listener-types"


export default ({
    socket
}) => {
    const placeBet = (data) => {
        socket.emit(emitters.PlaceBet, data)
    }

    return {
        placeBet
    }
}
