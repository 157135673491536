import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import loginReducer from "./reducer/user";
import inplayReducer from "./reducer/inplay";
import commonReducer from "./reducer/common";
import betReducer from "./reducer/bets";
import walletReducer from "./reducer/wallet";
import sportReducer from "./reducer/sports";
import roomReducer from "./reducer/room";
import burstGamesReducer from "./reducer/burstGames";
import fastPatityGamesReducer from "./reducer/fastpartity";
import diceGameReducer from "./reducer/dicegame";
import gameReducer from "./reducer/games";
import roulettesGamesReducer from "./reducer/roulettes";

export const reducer = {
  common: commonReducer,
  login: loginReducer,
  inplay: inplayReducer,
  burstgames: burstGamesReducer,
  bet: betReducer,
  wallet: walletReducer,
  sports: sportReducer,
  rooms: roomReducer,
  fastPatityGames: fastPatityGamesReducer,
  diceGame: diceGameReducer,
  game: gameReducer,
  roulettesGames: roulettesGamesReducer
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
