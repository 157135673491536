import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sportsList, mainBannerlist, subBannerlist } from "../../services/api";
import { API_STATUS } from "../../utils/constants";

const namespace = "sports";

const initialState = {
    sportlistloading: "initial",
    sportsList: null,
    sportsListCount: null,
    mainbannerloading: "initial",
    mainbannerList: null,
    mainbannerCount: null,
    subbannerloading: "initial",
    subbannerList: null,
    subbannerCount: null,
};

export const getSportList = createAsyncThunk(
    `${namespace}/getSportList`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await sportsList({});
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const getMainBanner = createAsyncThunk(
    `${namespace}/getMainBanner`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await mainBannerlist({});
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



export const getSubBanner = createAsyncThunk(
    `${namespace}/getSubBanner`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subBannerlist({});
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



const sportSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getSportList.pending](state) {
            state.sportlistloading = API_STATUS.PENDING;
        },
        [getSportList.fulfilled](state, { payload }) {
            state.sportlistloading = API_STATUS.FULFILLED;
            state.sportsList = payload?.data.data.result
        },
        [getSportList.rejected](state, action) {
            state.sportlistloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getMainBanner.pending](state) {
            state.mainbannerloading = API_STATUS.PENDING;
        },
        [getMainBanner.fulfilled](state, { payload }) {
            state.mainbannerloading = API_STATUS.FULFILLED;
            state.mainbannerList = payload?.data.data.result
        },
        [getMainBanner.rejected](state, action) {
            state.mainbannerloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getSubBanner.pending](state) {
            state.subbannerloading = API_STATUS.PENDING;
        },
        [getSubBanner.fulfilled](state, { payload }) {
            state.subbannerloading = API_STATUS.FULFILLED;
            state.subbannerList = payload?.data.data.result
        },
        [getSubBanner.rejected](state, action) {
            state.subbannerloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData } = sportSlice.actions;

export const sportSelector = (state) => state.sports;

export default sportSlice.reducer;
