export const emitters = {
    InPlayFilter: 'inplay:list:request',
    InPlayEvent: 'inplay:event:request',
    JoinRoomRequest: 'join-room:request',
    LeaveRoomRequest: 'leave-room:request',
}

export const listeners = {
    InPlayFilter: 'inplay:list:response',
    InPlayEvent: 'inplay:event:response',
    BurstGameEmitValue: 'burst-game:emit:odds',
    AvailableRooms: 'available-rooms',
    notification: 'notification'
}