import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Chip, Grid } from '@mui/material';


function SimpleDialog(props) {
    const { open, color, numberVal } = props;



    return (
        <Dialog open={open}>
            <Grid container sx={{
                padding: '10px'
            }}>
                <Grid item md="12" xs="12" sx={{ textAlign: 'center' }}>
                    <DialogTitle>Winning Number</DialogTitle>
                    <Chip sx={{
                        fontSize: 17,
                        fontWeight: 'bold',
                        backgroundColor: color
                    }} label={numberVal}></Chip>
                </Grid>
            </Grid>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default SimpleDialog