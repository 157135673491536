import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { betList, betPlace } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import { toast } from "react-toastify";
const namespace = "bet";

const initialState = {
    betloading: "initial",
    betlistloading: "initial",
    errorMessage: null,
    betlist: null,
    betlistCount: 0,
};

export const placeBet = createAsyncThunk(
    `${namespace}/placeBet`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            const data = await betPlace({ payload: postData });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getBetList = createAsyncThunk(
    `${namespace}/getBetList`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            const data = await betList({ payload: postData });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const betSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [placeBet.pending](state) {
            state.betloading = API_STATUS.PENDING;
        },
        [placeBet.fulfilled](state, { payload }) {
            if (payload?.data?.data?.message) {
                toast.dismiss();
                toast.error(payload?.data?.data?.message)
            } else {
                toast.dismiss();
                toast.success('Bet placed successfully!')
            }
            state.betloading = API_STATUS.FULFILLED;
        },
        [placeBet.rejected](state, action) {
            state.betloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getBetList.pending](state) {
            state.betlistloading = API_STATUS.PENDING;
        },
        [getBetList.fulfilled](state, { payload }) {
            state.betlistloading = API_STATUS.FULFILLED;
            state.betlist = payload?.data.data.result
            state.betlistCount = payload?.data.data.count
        },
        [getBetList.rejected](state, action) {
            state.betlistloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData } = betSlice.actions;

export const betSelector = (state) => state.bet;

export default betSlice.reducer;
