import React, {
  useEffect,
  useState,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { WebSocketContext } from "../../websocket";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Fab,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import { API_STATUS } from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import { createTheme } from "@mui/material/styles";
import { Emitters } from "../../../services/socket/emitters";
import { commonSelector } from "../../../store/reducer/common";
import { inplaySelector } from "../../../store/reducer/inplay";
import { toast } from "react-toastify";
import { placeBet, betSelector } from "../../../store/reducer/bets";
import {
  getBalance,
  walletSelector
} from "../../../store/reducer/wallet";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cricketbg from '../../../assets/images/cricket-bg1.png'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  verticalAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  backgroundColor: 'unset',
}));

export default function InPlayEventCricket({ setOverViewData, eventId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  let loggedIn = localStorage.getItem('isAuthenticated');
  const ws = useContext(WebSocketContext);
  const { socketState } = useSelector(commonSelector);
  const { inplayEvent } = useSelector(inplaySelector);
  const { betloading } = useSelector(betSelector);
  const { balanceloading, userBalance } = useSelector(walletSelector);
  const [betModal, setbetModal] = useState(false);
  const [betActive, setbetActive] = useState(true);
  const [marketName, setmarketName] = useState('');
  const [optionDatas, setoptionDatas] = useState('');
  const [walletBalance, setwalletBalance] = useState(0);
  const [betData, setbetData] = useState({
    id: "",
    eventId: "",
    referenceId: "",
    optionId: "",
    amount: "",
  });
  const [overViewDetails, setOverViewDetails] = useState({
    name: "",
    last_deliveries: "",
    homeTeamDetails: {
      name: "",
      score: "",
    },
    awayTeamDetails: {
      name: "",
      score: "",
    },
  });
  const [marketData, setMarcketData] = useState([]);

  const handleClose = () => {
    setbetData({
      id: "",
      eventId: "",
      referenceId: "",
      optionId: "",
      amount: "",
    });
    setbetModal(false);
    // setbetActive(false);
  };

  const onbetValue = (itemData, optionItems, refereceID, optionID) => {
    setbetData({
      ...betData,
      referenceId: refereceID,
      optionId: optionID,
      eventId: eventId,
    });
    setoptionDatas(optionItems)
    setmarketName(itemData.name)
    setbetModal(true);
  };

  const onPlaceBet = () => {
    let postData = betData
    dispatch((placeBet({ postData })))
    setbetModal(false)
  }

  useEffect(() => {
    if (loggedIn)
      dispatch(getBalance({}))
  }, [loggedIn])

  useEffect(() => {
    let eventData = inplayEvent;
    if (eventData && eventData.data && eventData.data.length) {
      setMarcketData([...eventData.data]);
      setOverViewDetails({
        ...overViewDetails,
        ...eventData.overViewData,
      });
      setOverViewData({
        ...eventData.overViewData,
        success: eventData.overViewData ? true : false,
      });
      setbetData({
        ...betData,
        id: eventData.id
      })
    }
  }, [inplayEvent]);

  useEffect(() => {
    let triggerEvent = () => {
      Emitters.InPlayEvent(ws, { FI: eventId });
    };
    if (socketState && socketState.connected) {
      triggerEvent();
      let interval = setInterval(() => {
        triggerEvent();
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [socketState]);

  useEffect(() => {
    if (balanceloading === API_STATUS.FULFILLED) {
      setwalletBalance(userBalance && userBalance.amount)
    }
    if (balanceloading === API_STATUS.REJECTED) {
      toast.dismiss();
    }
  }, [balanceloading]);

  useEffect(() => {
    if (balanceloading === API_STATUS.FULFILLED) {
      setwalletBalance(userBalance && userBalance.amount)
    }
    if (balanceloading === API_STATUS.REJECTED) {
      toast.dismiss();
    }
  }, [balanceloading]);

  useEffect(() => {
    if (betloading === API_STATUS.REJECTED) {
      toast.dismiss();
      toast.error('Bet not available!')
    }
  }, [betloading]);


  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <> <Grid xs={16} md={16} lg={16} >
            <Card
              sx={{
                ':hover': {
                  boxShadow: 15
                },
                background: 'none',
                display: 'flex'
              }}>
              <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
                <Grid container spacing={1}>
                  <Grid sx={{
                    height: { xs: '30vh', md: '60vh' },
                    padding: '0',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundImage: `url(${cricketbg})`
                  }} xs={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                      <Grid sx={{
                        height: { xs: '5vh', md: '15vh' },
                        padding: '0',
                      }} xs={12} md={12} lg={12}>
                        {/* <Item elevation={0}>
                          <Typography variant="span" sx={{ fontWeight: '500' }} component="div">
                            {overViewDetails?.name}
                          </Typography>
                        </Item> */}
                      </Grid>
                      <Grid sx={{
                        height: { xs: '7.5vh', md: '15vh' },
                        padding: '0',
                      }} xs={12} md={12} lg={12}>
                        <Item elevation={0}>
                          <Typography variant="span" sx={{ fontWeight: '500' }} component="div">
                            {overViewDetails?.homeTeamDetails?.name}
                            <Divider sx={{ visibility: 'hidden' }}></Divider>
                            {overViewDetails?.homeTeamDetails?.score ? overViewDetails?.homeTeamDetails?.score.trim() : '0/0'}
                          </Typography>
                        </Item>
                      </Grid>
                      <Grid sx={{
                        height: { xs: '7.5vh', md: '10vh' },
                        padding: '0',
                      }} xs={12} md={12} lg={12}>
                      </Grid>
                      <Grid sx={{
                        height: { xs: '7.5vh', md: '15vh' },
                        padding: '0',
                      }} xs={12} md={12} lg={12}>
                        <Item elevation={0}>
                          <Typography variant="span" sx={{ fontWeight: '500' }} component="div">
                            {overViewDetails?.awayTeamDetails?.name}
                            <Divider sx={{ visibility: 'hidden' }}></Divider>
                            {overViewDetails?.awayTeamDetails?.score ? overViewDetails?.awayTeamDetails?.score.trim() : '0/0'}
                          </Typography>

                        </Item>
                      </Grid>
                      <Grid sx={{
                        height: { xs: '5vh', md: '15vh' },
                        padding: '0',
                      }} xs={12} md={12} lg={12}>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6} lg={6} sx={{ maxHeight: { md: '60vh', sx: '100%' }, overflow: 'auto' }}>
                    <Divider >
                      <Chip label="Odds" color="default" />
                    </Divider>
                    {marketData &&
                      marketData.length ?
                      marketData.map((item, index) => {
                        return (
                          <>
                            <Typography variant="span" sx={{ fontWeight: '500', fontStyle: 'italic' }} component="div">
                              {item.name}
                            </Typography>
                            <List dense={false}>
                              {item.options &&
                                item.options.length &&
                                item.options.map((optionitem, index) => {
                                  return (
                                    <>
                                      <ListItem
                                        sx={{
                                          cursor: 'pointer',
                                          backgroundColor: '#1b1c1d',
                                          marginTop: '4px',
                                          padding: '4px 4px 4px 4px'
                                        }}
                                        onClick={() => {
                                          if (item.suspended) {
                                            toast.dismiss()
                                            toast.error("Bet was suspended..")
                                            return
                                          }
                                          if (!loggedIn) {
                                            toast.dismiss();
                                            toast.error('Please login to place bet!');
                                            return
                                          }
                                          onbetValue(item, optionitem, item.reference, optionitem.id);
                                        }}
                                      >
                                        <ListItemText
                                          primary={`${optionitem.name}`}
                                        />
                                        <Button color="success" edge="end" sx={{ borderRadius: 'unset', backgroundColor: '#b9bfc514', minWidth: '60px' }}>
                                          <span style={{ fontSize: '12px' }}>x</span>&nbsp;{`${optionitem.odds}`}
                                        </Button>
                                      </ListItem>
                                    </>
                                  )
                                })}
                            </List>
                          </>
                        );
                      }) : ''}
                  </Grid>

                </Grid>
                <Divider sx={{ visibility: 'hidden' }}></Divider>
                <Divider sx={{ visibility: 'hidden' }}></Divider>
                <Divider></Divider>
              </CardContent>
            </Card>
          </Grid>
          </>
        </Grid>
      </Box>
      <Popover
        PaperProps={{
          sx: {
            width: { xs: '100%', md: '50%', lg: '40%' },
            maxHeight: 500,
          },
        }}

        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={betModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        {/* {betActive ? (
          <DialogTitle
            sx={{ color: "white", height: "4ch", background: "#198754" }}
          >
            <Typography
              sx={{ paddingTop: "3px", textAlign: "center", color: "white" }}
              variant="body1"
              display="block"
              color={"black"}
            >
              The price of your selection changed
            </Typography>
          </DialogTitle>
        ) : (
          ""
        )} */}
        <DialogContent dividers sx={{ background: "white" }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                // position: 'absolute',
                // right: ,
                bottom: 2,
                ml: -3,
                color: "grey",
                height: '10px',
                "&:hover": {
                  color: '#15c995'

                }
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle1" color={"black"} gutterBottom>
              {/* <b>5th Delivery 10th Over Derbyshire - Over 0.5</b> */}
              <b>{marketName} - {optionDatas && optionDatas.name}</b>&nbsp;&nbsp;
              {/* <b>{overViewDetails && overViewDetails.last_deliveries}</b> */}
              {betActive ? (
                <Button
                  sx={{
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#198754",
                    },
                    background: "#198754",
                    color: "black",
                    fontSize: "15px",
                  }}
                  size="small"
                >
                  <b>{optionDatas && optionDatas.odds}</b>
                </Button>
              ) : (
                <strong style={{ color: "#198754" }}>{optionDatas && optionDatas.odds}</strong>
              )}
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="center" gap={1}>
            <Typography variant="subtitle2" color={"black"} gutterBottom>
              {/* Match Winner 2 - Way */}
              {marketName}
            </Typography>
            <Typography variant="subtitle2" color={"black"} gutterBottom>
              {overViewDetails && overViewDetails.homeTeamDetails && overViewDetails.homeTeamDetails.name} VS {overViewDetails && overViewDetails.awayTeamDetails && overViewDetails.awayTeamDetails.name}
            </Typography>
          </Stack>
        </DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: "black",
                padding: 0.8,
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={7}>
                  {betActive ? (
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      onChange={(e) => {
                        if (e.target.value > walletBalance) {
                          toast.dismiss()
                          toast.error('Insufficient wallet balance!')
                        } else {
                          setbetData({ ...betData, amount: e.target.value })
                        }
                      }
                      }
                      value={betData.amount}
                      label={
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#198754",
                          }}
                        >
                          Stake Rs.
                        </Typography>
                      }
                      color="success"
                      sx={{ input: { color: "#198754", fontWeight: "bold" } }}
                      focused
                      autoFocus
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      style={{ marginTop: "15px", marginLeft: "5px" }}
                      sx={{ "&:hover": { color: "white", cursor: "pointer" } }}
                      color="#198754"
                      onClick={(e) => {
                        setbetActive(true);
                      }}
                    >
                      <b>Set Stake</b>
                    </Typography>
                  )}
                </Grid>

              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: "black",
              }}
            >
              <Button
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: betActive ? "#198754" : "grey",
                  },
                  width: "100%",
                  height: "75px",
                  background: betActive ? "#198754" : "grey",
                }}
                onClick={onPlaceBet}
              >
                {betActive ? (
                  <span>
                    <Typography
                      style={{
                        fontSize: "0.7rem",
                        color: "white",
                        paddingTop: "7px",
                      }}
                    >
                      <b>
                        Accept Change and <br />
                        <p style={{ fontSize: "1rem" }}>Place Bet</p>
                      </b>
                    </Typography>
                  </span>
                ) : (
                  <b style={{ color: "white" }}>Place Bet</b>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
