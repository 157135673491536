import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Toolbar,
    Divider,
    Tabs,
    Tab,
} from '@mui/material';
import RightDrawer from '../common/rightdrawer';
import InPlayCricket from './cricket';
import { games } from '../../config/index'
import { getSportList, sportSelector } from '../../store/reducer/sports';
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from '../common/BreadCrumb'
import Fab from '@mui/material/Fab';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import BetsDrawer from '../common/betsDrawer';
export default function InPlay({ home }) {
    let loggedIn = localStorage.getItem('isAuthenticated');
    const dispatch = useDispatch();
    const [currentGame, setCurrentGame] = React.useState('cricket');
    const [homePage, setHomePage] = React.useState(false);
    const { sportlistloading, sportsList } = useSelector(sportSelector);
    const [openBetsDrawer, setOpenBetsDrawer] = React.useState(false)
    const handleChange = (event, newValue) => {
        setCurrentGame(newValue);
    };

    useEffect(() => {
        dispatch(getSportList({}))
    }, [])

    console.log(sportsList, 'sportsList===');

    useEffect(() => {
        if (home == true) {
            setHomePage(true)
        } else {
            setHomePage(false)
        }
    }, [home])

    const isSelectedTab = (value) => {
        return currentGame == value
    }

    return (
        <>
            <Box component="main" sx={homePage ? {} : { flexGrow: 1, pt: 1 }}>
                {homePage ? '' : <BreadCrumb pages={[
                    {
                        name: 'Live Sports',
                        page_link: false
                    }
                ]}></BreadCrumb>}
                {loggedIn && !homePage ? <Fab color="success" sx={{ float: 'right', display: { xs: 'none', md:'flex' } }} size="small" variant="extended" aria-label="add" onClick={() => {
                    setOpenBetsDrawer(true)
                }}>
                    <CurrencyExchangeTwoToneIcon />
                </Fab> : ''}


                <Box>
                    <Tabs sx={{ marginTop: { xs: "0px" } }} value={currentGame} onChange={handleChange} variant="scrollable"
                        scrollButtons="auto"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#15c995'
                            }
                        }}
                        aria-label="scrollable auto tabs example">
                        {games && games.length && games.map((item, i) => {
                            return <Tab key={i} value={item.key} icon={<span style={isSelectedTab(item.key) ? { color: '#00cd6d', fontWeight: 'bold', fontSize: 'larger' } : { fontWeight: 'bold' }}><item.icon /></span>} label={<span style={isSelectedTab(item.key) ? { fontSize: 'small', color: '#00cd6d', fontWeight: 'bold' } : { fontSize: 'x-small', fontWeight: 'bold' }}>{item.name}</span>} />
                        })}
                    </Tabs>
                    <Divider></Divider>
                </Box>
                {currentGame == 'cricket' ? <InPlayCricket></InPlayCricket> :
                    <div
                        style={{
                            background: "linear-gradient(to bottom right,#303d39,#353535)",
                            height: "150px",
                            marginLeft: "10px",
                            textAlign: 'center',
                            position: 'relative',
                            padding: '30px 15px',
                            borderRadius: '5px',
                            marginTop: homePage ? '30px' : '15px'
                        }}
                    >
                        <p style={{ textAlign: "center", marginBottom: "50px" }}>
                            Coming Soon...!
                        </p>
                    </div>
                }

            </Box>
            <BetsDrawer drawerOpen={[openBetsDrawer, setOpenBetsDrawer]}></BetsDrawer>
            {/* {homePage ? '' : <RightDrawer drawerWidth={300}></RightDrawer>} */}
        </>
    );
}