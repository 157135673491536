import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { Box, Toolbar, Divider, Tabs, Tab, Chip, Avatar } from "@mui/material";
import { mybets } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { getBetList, betSelector } from "../../store/reducer/bets";
import Transactions from "../wallet/transactions";
import TablePaginationActions from "../../utils/pagination";
import BreadCrumb from '../common/BreadCrumb'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActions } from '@mui/material';
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { proceedCashout } from "../../services/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CurrencyExchangeTwoTone } from "@mui/icons-material";
// const tableStaticData = [
//   {
//     betNo: "001",
//     name: "Derbyshire vs Leicestershire - CC Div 2",
//     status: "Confirmed",
//     bet: "Win",
//   },
//   {
//     betNo: "002",
//     name: "Derbyshire vs Leicestershire - CC Div 2",
//     status: "Confirmed",
//     bet: "Win",
//   },
//   {
//     betNo: "003",
//     name: "Leicestershire vs Derbyshire - CC Div 2",
//     status: "Confirmed",
//     bet: "Loose",
//   },
//   {
//     betNo: "004",
//     name: "Derbyshire vs Leicestershire - CC Div 2",
//     status: "Confirmed",
//     bet: "Win",
//   },
//   {
//     betNo: "005",
//     name: "Derbyshire vs Leicestershire - CC Div 2",
//     status: "Confirmed",
//     bet: "Loose",
//   },
// ];


function Mybets({
  drawer
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentGame, setCurrentGame] = useState("all");
  const [betDatas, setbetDatas] = useState([]);
  const { betlistloading, betlist, betlistCount } = useSelector(betSelector);
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChange = (event, newValue) => {
    setCurrentGame(newValue);
    console.log(newValue, 'newValue=');
    setPage(0);
    setRowsPerPage(100)
    let postData = {
      limit: 100,
      page: 1,
      filter: {
        status: newValue,
      }
    }
    dispatch(
      getBetList({
        postData
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    getBetsDispatch();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 100)
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0);
    getBetsDispatch();
  };


  const getBetsDispatch = () => {
    let postData = {
      limit: rowsPerPage,
      page: page + 1,
    }
    dispatch(getBetList({
      postData
    }))
  };

  const cashoutOnClick = async (id) => {
    try {
      await proceedCashout({
        payload: {
          id
        }
      })
      toast.success('Sell request submitted successfully..')
      getBetsDispatch()
    } catch (error) {
      getBetsDispatch()
    }
  }


  useEffect(() => {
    getBetsDispatch()
  }, [])

  useEffect(() => {
    if (betlist) {
      setbetDatas(betlist)
    }
  }, [betlist]);

  const comp = () => {
    return <Box sx={{ width: "100%", bgcolor: "#000000" }}>
      <Grid container spacing={0}>
        {betDatas &&
          betDatas.length ?
          betDatas.map((item, index) => {
            return (
              <Grid key={index} xs={12} md={4} lg={4}>
                <Paper sx={{ padding: '5px' }} elevation={0}>
                  <Card
                    sx={{
                      backgroundColor: '#292a2c',
                      display: 'flex'
                    }}>
                    <CardContent sx={{ flex: '1 0 auto', width: '100%', padding: '5px' }}>
                      <Grid container spacing={0}>
                        <Grid key={index} xs={6} md={6} lg={6}>
                          <Typography component="div" sx={{ fontSize: "small" }} variant="span">
                            {dayjs(item.createdOn).format('DD-MM-YYYY hh:mm:ss A')}
                          </Typography>
                        </Grid>
                        <Grid key={index} xs={6} md={6} lg={6}>
                          {
                            item.oddStatus && item.status == 'placed' ? <>
                              {/* true ? <> */}
                              <Button variant="contained" onClick={() => {
                                cashoutOnClick(item._id)
                              }} color="error" size="small" sx={{ fontSize: 12, fontWeight: 'bold', float: 'right' }} >
                                Sell
                              </Button></> : ''
                          }
                        </Grid>

                      </Grid>

                       <Typography component="span" color={"GrayText"} sx={{ fontSize: '13px' }}>
                        {item.league}
                      </Typography>
                      <Divider></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>

                      <Typography component="div" sx={{fontSize:'smaller'}} variant="h7">
                        {item.text} : {item.selectedOption}
                      </Typography>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <Divider sx={{ visibility: 'hidden' }}></Divider>
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: '25%' }}>Stake</TableCell>
                              <TableCell sx={{ width: '25%' }}>Odds</TableCell>
                              <TableCell sx={{ width: '50%' }}>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {`Rs. ${item.amount}`}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {`${item.odds}`}
                              </TableCell>
                              <TableCell >
                                {item.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                {item.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                {item.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                {item.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                {item.status == 'credited' ? <><Chip size="small" color="success" label="Succuss" /></> : ''}
                                {item.status == 'cashout-credited' ? <><Chip size="small" color="success" label="Sold" /></> : ''}
                                {item.status == 'cashout' ? <><Chip size="small" color="success" label={"Sold"} /></> : ''}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {item.status == 'cashout-credited' || item.status == 'credited' ?
                        <Grid key={index} xs={12} md={12} lg={12}>
                          <Paper elevation={16} sx={{ padding: '2px', background: '#00cd6d38' }}>
                            <Grid container spacing={0}>
                              <Grid key={index} xs={8} md={8} lg={8}>
                                <Typography component="div" variant="h7">
                                  Credited to your account
                                </Typography>
                              </Grid>
                              <Grid key={index} xs={4} md={4} lg={4}>
                                <Typography component="div" sx={{float:'right', fontWeight:'bold'}} variant="h7">
                                  Rs. 1000
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        : item.status == 'cashout' || item.status == 'succedded' ? <Grid key={index} xs={12} md={12} lg={12}>
                        <Paper elevation={16} sx={{ padding: '2px', background: '#ffc10726',  color:'#d1c045' }}>
                          <Grid container spacing={0}>
                            <Grid key={index} xs={12} md={12} lg={12}>
                              <Typography component="div" variant="h7">
                                Transaction in progress
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>:''}
                    </CardContent>
                    <CardActions>

                    </CardActions>
                  </Card>
                </Paper>
              </Grid>

            );
          }) : (
            <Grid xs={16} md={16} lg={16}>
              <Paper sx={{ backgroundColor: 'GrayText' }}>
                <Card
                  sx={{
                    display: 'flex'
                  }}>
                  <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
                    <Typography component="div" variant="h6">
                      {'No Records found'}
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          )}
      </Grid>
    </Box>
  }

  return (
    drawer ? comp() :
      <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper elevation={0} sx={{ padding: '0px 8px 0px 8px', backgroundColor:'#040507' }}>
            <Typography component="div" variant="h7" >
              My Bets
            </Typography>
          </Paper>
        </Box>
        {comp()}
      </Box>
  );
}

export default Mybets;
