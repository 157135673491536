import React, { createContext, useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux';
import { listeners } from '../services/socket/emitter-listener-types';
import { Listeners } from '../services/socket/listeners';
// import { updateChatLog } from './actions';
import { socketStatusUpdate, commonSelector } from '../store/reducer/common';
import { getWebSocketUrl } from '../services/config';
import { loginSelector } from '../store/reducer/user';
const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
    let ws;
    const socketRef = useRef()
    const { socketState } = useSelector(commonSelector);
    const { sessionToken } = useSelector(loginSelector);
    const dispatch = useDispatch();
    const socketCallBack = () => {
        let payload = {
            id: socketRef?.socket.id,
            connected: socketRef?.socket.connected,
        }
        localStorage.setItem('socket', JSON.stringify(payload))
        dispatch(socketStatusUpdate(payload))
    }
    const connectSocket = (sessionToken) => {
        const socket = io(getWebSocketUrl(), {
            extraHeaders: sessionToken ? {
                'socket-auth': localStorage.getItem('authToken')
            } : {}
        }).connect()
        socket.on('connect', socketCallBack);
        socket.on('close', socketCallBack);
        socket.on('disconnect', socketCallBack)
        socket.on("connect_error", () => {
            setTimeout(() => {
                socket.connect();
            }, 1000);
        });
        socketRef.socket = socket
        Listeners({
            socket: socket,
            dispatch: dispatch
        })
    }
    useEffect(() => {
        if (socketRef?.socket)
            socketRef.socket.close()
        connectSocket(sessionToken)
    }, [sessionToken])

    // useEffect(() => {
    //     if (socketRef?.socket){
    //         Listeners({
    //             socket: socketRef?.socket,
    //             dispatch: dispatch
    //         })
    //     }
    // }, [socketRef?.socket])
    ws = {
        socket: socketRef?.socket
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}