import { emitters } from "./emitter-listener-types"
export const Emitters = class Emitters {
    static checkConnectionEstablished(socket) {
        if (socket && socket.id) {
            return true
        } else throw 'Connection not established'
    }
    static InPlayFilter(ws, {
        sport_id,
        league_id,
        skip_esports
    }) {
        try {
            Emitters.checkConnectionEstablished(ws.socket)
            ws.socket.emit(emitters.InPlayFilter, JSON.stringify({
                sport_id,
                league_id,
                skip_esports
            }))
        } catch (error) {
            console.log(error, 'error')
        }
    }
    static InPlayEvent(ws, {
        FI,
        stats,
        lineup,
        raw
    }) {
        try {
            Emitters.checkConnectionEstablished(ws.socket)
            ws.socket.emit(emitters.InPlayEvent, JSON.stringify({
                FI,
                stats,
                lineup,
                raw
            }))
        } catch (error) {
            console.log(error, 'error')
        }
    }
}