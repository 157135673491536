import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import {
    doLogin,
    loginSelector,
    logOut,
    registerNow,
} from "../store/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../utils/constants";
import { SportsCricket } from "@mui/icons-material";

function JoinNow() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formValidator = useRef(new SimpleReactValidator());
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setshowconfirmPassword] = useState(false);
    const [checkboxTrue, setcheckboxTrue] = useState(false);
    const { registerloading } = useSelector(loginSelector);
    const [joinForm, setjoinForm] = useState({
        name: "",
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
        referal_code: "",
        acceptTerms: false,
    });
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickShowPassword = (type) => {
        if (type == 1) {
            setShowPassword((show) => !show);
        } else {
            setshowconfirmPassword((shownewPassword) => !shownewPassword)
        }
    }


    useEffect(() => {
        handleClickOpen();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClose = () => {
        setOpen(false);
        navigate("/");
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const OnjoinSubmit = () => {
        if (joinForm.acceptTerms) {
            if (formValidator.current.allValid()) {
                let postData = joinForm;
                delete postData.referal_code
                delete postData.confirm_password
                dispatch(registerNow({ postData }));
            } else {
                setAddFormSubmit(true);
            }
        } else {
            toast.error("Please Agree Terms & Conditions");
        }
    };

    useEffect(() => {
        formValidator.current.showMessages();
    }, []);

    useEffect(() => {
        console.log(registerloading, "loading");
        if (registerloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Registered Successfully!");
            navigate("/");
        }
        if (registerloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error("Register Failed! Please check username and password");
        }
    }, [registerloading]);

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        background: "#198754",
                    }}
                >
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 1.5,
                                    // display: { xs: "none", md: "flex" },
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    letterSpacing: ".2rem",
                                    color: "#ffffff",
                                    textDecoration: "none",
                                    "&:hover": {
                                        color: '#00e471'
                                    },
                                }}
                            >

                                {/* Odds11 */}
                                BCASINO
                                {/* <SportsCricket /> */}
                            </Typography>
                            <Typography
                                sx={{ ml: 2, flex: 1 }}
                                variant="h6"
                                component="div"
                            ></Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{
                                    "&:hover": {
                                        color: '#15c995'
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        "& .MuiTextField-root": {
                            mt: 2,
                            mb: 3,
                            width: "100%",
                            color: 'black'
                        },
                        textAlign: 'center',
                        width: { lg: "calc(100% - 500px)", md: "calc(100% - 500px)", sm: "calc(100% - 150px)" },
                        margin: '0 auto',
                        mt: 8,
                        padding: '1rem'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography sx={{ mt: { xs: 1, md: 3, lg: 3 } }} variant="h5" gutterBottom>
                        <b>Register</b>
                    </Typography>

                    {/* <Typography
                        
                        variant="button"
                        display="block"
                        gutterBottom
                    >
                        Get Help with this form —{" "}
                        <Link
                            style={{ color: '#15c995' }}
                            sx={{ mr: 1, width: "10ch" }}
                            component="button"
                            // variant="body2"
                            underline="hover"
                            onClick={() => {
                                window.location.href = "/joinnow";
                            }}
                        >
                            Contact Us
                        </Link>
                    </Typography> */}

                    <div>
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            name="name"
                            variant="outlined"
                            color="success"
                            onChange={(e) => {
                                setjoinForm({
                                    ...joinForm,
                                    name: e.target.value,
                                });
                            }}
                        />
                        <div style={{ marginBottom: "5px" }}>
                            {formValidator.current.message(
                                "name",
                                joinForm.name,
                                "required|alpha_space",
                                {
                                    className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                    messages: {
                                        required: "Please Enter Name",
                                        alpha_space: "Please Enter Valid Input",
                                    },
                                }
                            )}
                        </div>
                    </div>
                    {/* <Divider /> */}
                    {/* <Typography
                        sx={{ mt: 1 }}
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                    >
                        <b>CREATE LOGIN</b>
                        <span style={{ color: "red" }}>*</span>
                    </Typography> */}
                    <div>
                        <TextField
                            id="outlined-basic"
                            label="Mobile No"
                            name="mobile"
                            color="success"
                            variant="outlined"
                            value={joinForm.mobile}
                            onChange={(e) => {
                                setjoinForm({
                                    ...joinForm,
                                    mobile: e.target.value,
                                });
                            }}
                        />
                        <div >
                            {formValidator.current.message(
                                "mobile",
                                joinForm.mobile,
                                "required|phone",
                                {
                                    className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                    messages: {
                                        required: "Please Enter Mobile No",
                                        phone: "Please Enter Valid Mobile Number",
                                    },
                                }
                            )}
                        </div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            label="Email ID"
                            name="email"
                            color="success"
                            variant="outlined"
                            value={joinForm.email}
                            onChange={(e) => {
                                setjoinForm({
                                    ...joinForm,
                                    email: e.target.value,
                                });
                            }}
                        />
                        <div >
                            {formValidator.current.message(
                                "email",
                                joinForm.email,
                                "required|email",
                                {
                                    className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                    messages: {
                                        required: "Please Enter Email",
                                        email: "Please Enter Valid Email",
                                    },
                                }
                            )}
                        </div>
                    </div>
                    <div>
                        <FormControl
                            sx={{ mt: 2, mb: 1.5, width: '100%' }}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password" color="success">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { handleClickShowPassword(1) }}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                color="success"
                                label="Password"
                                onChange={(e) => {
                                    setjoinForm({
                                        ...joinForm,
                                        password: e.target.value,
                                    });
                                }}
                            />
                        </FormControl>
                        <div >
                            {formValidator.current.message(
                                "password",
                                joinForm.password,
                                [
                                    "required",
                                    {
                                        regex:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!/#%*?&])[A-Za-z\d@$!/#%*?&]{8,15}$/,
                                    },
                                ],
                                {
                                    className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                    messages: {
                                        required: "Please Enter Password",
                                        password: "Please enter valid password",
                                        regex:
                                            "Minimum 8 and Maximum 15 characters,at least include one Uppercase letter, one Lowercase letter, one Number and one Special character (@$!/#%*?&)",
                                    },
                                }
                            )}
                        </div>
                    </div>
                    <div>
                        <FormControl
                            sx={{ mt: 2, mb: 1.5, width: '100%' }}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password" color="success">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                name="confirm_password"
                                type={showconfirmPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { handleClickShowPassword(2) }}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            color="success"
                                        >
                                            {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                color="success"
                                label="Confirm Password"
                                onChange={(e) => {
                                    setjoinForm({
                                        ...joinForm,
                                        confirm_password: e.target.value,
                                    });
                                }}
                            />
                        </FormControl>
                        <div >
                            {formValidator.current.message(
                                "confirm_password",
                                joinForm.confirm_password,
                                ["required", `in:${joinForm.password}`],
                                {
                                    className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                    messages: {
                                        required: "Please Enter Confirm Password",
                                        in: "New Password and Confirm password doesn't match",
                                    },
                                }
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div style={{ marginTop: "5px" }}>
                        <TextField
                            id="outlined-basic"
                            label="Referal Code"
                            variant="outlined"
                            color="success"
                            onChange={(e) => {
                                setjoinForm({
                                    ...joinForm,
                                    referal_code: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <Typography variant="subtitle1" gutterBottom>
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            onClick={(e) => {
                                setjoinForm({
                                    ...joinForm,
                                    acceptTerms: e.target.checked,
                                });
                            }}
                        />
                        &nbsp;I am at least 18 years of age and I have read, accept and
                        agree to the Cookies Policy and policies relating to age and
                        identity verification.
                    </Typography>
                    <div >
                        {formValidator.current.message(
                            "acceptTerms",
                            joinForm.acceptTerms,
                            "required",
                            {
                                className: `invalid_feedback 
														  ${AddFormSubmit ? "show" : "hide"}`,
                                messages: {
                                    required: "Please Agree Terms to Proceed",
                                },
                            }
                        )}
                    </div>
                    <div>
                        <Button
                            sx={{ mb: 4, width: "100%", background: "#198754", fontSize: '17px' }}
                            variant="contained"
                            size="medium"
                            color="success"
                            onClick={OnjoinSubmit}
                        >
                            Join Now
                        </Button>
                    </div>
                </Box>
            </Dialog>
        </>
    );
}

export default JoinNow;
