import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import casinoImage from '../assets/images/banner_casino.png'
import banner1 from '../assets/images/banner-1.jpg'
import banner2 from '../assets/images/banner-2.jpg'
import banner7 from '../assets/images/banner-7.png'
import banner3 from '../assets/images/banner_sports.png'
import LeftDrawer from './common/leftdrawer';
import RightDrawer from './common/rightdrawer';
import Carousel1 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { TextField, Typography } from "@mui/material";
import InPlay from './inplay/index'
import ProfileDrawer from './common/profiledrawer'
import {
    getMainBanner,
    sportSelector
} from "../store/reducer/sports";
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from "../services/config";
import CasinoImage from '../assets/images/casino.webp'
import LotterImage from '../assets/images/lottery.webp'
import RacingImage from '../assets/images/racing.webp'
import SportsImage from '../assets/images/sports.webp'
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { ArrowLeftRounded, ArrowRightAltRounded } from '@mui/icons-material';
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '60px',
    margin: 10
}));
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const GameTypeItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 10,
    cursor: 'pointer',
}));

const GameNameItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '25px',
    fontWeight: 'bold',
    margin: 0,
    padding: 5
}));


export default function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { mainbannerloading, mainbannerList } = useSelector(sportSelector);
    const [mainBannerData, setmainBannerData] = useState([])
    useEffect(() => {
        dispatch(getMainBanner({}))
    }, [])

    useEffect(() => {
        if (mainbannerList && mainbannerList.length) {
            let tempArr = mainbannerList.filter(item => item.status == 1)
            tempArr.slice().sort((a, b) => {
                return a.priority - b.priority;
            });
            setmainBannerData([...tempArr])
        }
    }, [mainbannerList])

    return (
        <>
            <Box component="main" >
                <Grid container>
                    <Grid item md="1.5" sx={{ display: { md: 'block', xs: 'none' } }}>
                        <Paper sx={{
                            minHeight: '100vh',
                            backgroundColor: '#1b1c1d00',
                            paddingRight: '5px',
                            paddingLeft: '5px'
                        }}>
                            <NavBar></NavBar>
                        </Paper>
                    </Grid>
                    <Grid item md="9" xs="12" sx={{ marginTop: { md: '40px', xs: '0px' }, marginLeft: { md: '100px', xs: '0px' } }}>
                        <Carousel1
                            swipeable={true}
                            showDots={true}
                            draggable={false}
                            responsive={responsive}>
                            {
                                mainBannerData && mainBannerData.map((item, i) => {
                                    return (
                                        <div>
                                            <Item elevation={12}>
                                                <Card>
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            height="220"
                                                            image={`${assestURL}static/${item.promoUrl}`}
                                                            alt="green iguana"
                                                        />
                                                    </CardActionArea>
                                                </Card>
                                            </Item>
                                        </div>
                                    )
                                }
                                )}
                        </Carousel1>
                        <Box>
                            <Grid container>
                                <Grid item md="3" xs="6">
                                    <GameTypeItem onClick={() => {
                                        navigate(`/games/casino`)
                                    }} sx={{
                                        backgroundColor: '#69009870',
                                        "&:hover": {
                                            border: '1px solid #690098'
                                        }
                                    }}>
                                        <img src={CasinoImage} width="90%"></img>
                                        <GameNameItem className="name-item" sx={{ backgroundColor: '#69009870', fontSize: { xs: '18px', md: '25px' } }}>
                                            CASINO
                                            <Typography sx={{ display: { xs: 'none', md: 'block' } }} fontWeight={300} textAlign={"left"} whiteSpace={'nowrap'} color={"white"} variant="subtitle2" fontSize={"12px"}>
                                                Dive into our in-house games, live casino and slots
                                            </Typography>
                                        </GameNameItem>
                                    </GameTypeItem>

                                </Grid>
                                <Grid item md="3" xs="6">
                                    <GameTypeItem onClick={() => {
                                        navigate(`/games/sports`)
                                    }} sx={{
                                        backgroundColor: '#1394544d', "&:hover": {
                                            border: '1px solid #139454'
                                        }
                                    }}>
                                        <img src={SportsImage} width="90%"></img>
                                        <GameNameItem className="name-item" sx={{ backgroundColor: '#1394544d', fontSize: { xs: '18px', md: '25px' } }}>
                                            SPORTS
                                            <Typography sx={{ display: { xs: 'none', md: 'block' } }} fontWeight={300} textAlign={"left"} whiteSpace={'nowrap'} color={"white"} variant="subtitle2" fontSize={"12px"}>
                                                Bet on Football, Cricket, NFL, eSports & over 80 sports
                                            </Typography>
                                        </GameNameItem>
                                    </GameTypeItem>
                                </Grid>
                                <Grid item md="3" xs="6">
                                    <GameTypeItem onClick={() => {
                                        navigate(`/games/lottery`)
                                    }} sx={{
                                        backgroundColor: '#5455dc6b', "&:hover": {
                                            border: '1px solid #5455dc'
                                        }
                                    }}>
                                        <img src={LotterImage} width="90%"></img>
                                        <GameNameItem className="name-item" sx={{ backgroundColor: '#5455dc6b', fontSize: { xs: '18px', md: '25px' } }}>
                                            LOTTERY
                                            <Typography sx={{ display: { xs: 'none', md: 'block' } }} fontWeight={300} textAlign={"left"} whiteSpace={'nowrap'} color={"white"} variant="subtitle2" fontSize={"12px"}>
                                                Try your luck and seize the opportunity to win big
                                            </Typography>
                                        </GameNameItem>
                                    </GameTypeItem>
                                </Grid>
                                <Grid item md="3" xs="6">
                                    <GameTypeItem
                                        onClick={() => {
                                            navigate(`/games/racing`)
                                        }}
                                        sx={{
                                            backgroundColor: '#782a0370', "&:hover": {
                                                border: '1px solid #782a03'
                                            }
                                        }}>
                                        <img src={RacingImage} width="90%"></img>
                                        <GameNameItem className="name-item" sx={{ backgroundColor: '#782a0370', fontSize: { xs: '18px', md: '25px' } }}>
                                            RACING
                                            <Typography sx={{ display: { xs: 'none', md: 'block' } }} fontWeight={300} textAlign={"left"} whiteSpace={'nowrap'} color={"white"} variant="subtitle2" fontSize={"12px"}>
                                                Experience the thrill of horse racing and enjoy the winnings
                                            </Typography>
                                        </GameNameItem>
                                    </GameTypeItem>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ minHeight: 150 }}>
                            <InPlay home={true} />
                        </Box>
                    </Grid>
                </Grid>

            </Box>
            {/* <RightDrawer drawerWidth={300} drawerOpen={true} /> */}
        </>
    );
}

