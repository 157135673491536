import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#ffffff",
    // color: "#000000",
    padding: "10px",
    fontSize: '16px',
    fontWeight: "bold",
    "&:hover": {
      color: '#15c995'
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    // color: 'black',
    fontWeight: '500'
  },
}));

export const whiteTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    padding: "10px",
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    color: 'black',
    fontWeight: '500'
  },
}));

export const whiteTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "#F1F8FF",
    // color: "#000000",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "#F1F8FF",
    // color: "#000000",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));