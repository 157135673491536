import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Fab, Grid } from "@mui/material";
import { useHref, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { toast } from 'react-toastify';
import { gameTypes } from '../config';
import {
  doLogin,
  loginSelector,
  logOut
} from "../store/reducer/user";
import {
  getBalance,
  getTransaction,
  walletSelector
} from "../store/reducer/wallet";
import { API_STATUS } from "../utils/constants";
import SimpleReactValidator from 'simple-react-validator';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import styled from "@mui/system/styled";
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha, createTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ProfileDrawer from "../components/common/profiledrawer";
import RightDrawer from "../components/common/rightdrawer";
import LeftDrawer from "../components/common/leftdrawer";
import ProfileImg from "../assets/images/profile.jpg";
import {
  Tabs,
  Tab,
} from '@mui/material';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import { getCurrentPage, getPages } from "../config";
import { CurrencyExchangeTwoTone, Wallet } from "@mui/icons-material";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    alignItems: "flex-start"
  }
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },

}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const theme = createTheme({
  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#3E68A8',
          },
          '& label.Mui-focused': {
            color: '#3E68A8',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#3E68A8',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#3E68A8',
            },
            '&:hover fieldset': {
              borderColor: '#3E68A8',
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3E68A8',
            },
          },
        },
      },
    },
  }
})

function ResponsiveAppBar() {
  let loggedIn = localStorage.getItem('isAuthenticated');
  let pages = getPages()
  const formValidator = useRef(new SimpleReactValidator());

  const settings = ["Join", "Login"];
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [currentHeader, setcurrentHeader] = useState('/');
  const [currentGameType, setCurrentGameType] = useState('');
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [walletBalance, setwalletBalance] = useState(0);
  const [loginForm, setloginForm] = useState({ username: '', password: '' })
  const [showPassword, setShowPassword] = useState(false);
  const { loginData, loading } = useSelector(loginSelector);
  const { balanceloading, transactionloading, userBalance, transactionlist } = useSelector(walletSelector);
  const [AddFormSubmit, setAddFormSubmit] = useState(false)
  // let temp_token = localStorage.getItem('temporaryToken');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setopen] = useState(false);
  // const open = Boolean(anchorEl);
  const [expanded, setIsexpanded] = useState(true);
  const history = useHref()
  useEffect(() => {
    let page = getCurrentPage(history)
    setcurrentHeader(page)
  }, [history])

  useEffect(() => {
    let findPage = gameTypes.find(item => history.includes(item.key))
    setCurrentGameType(findPage ? findPage.key : '')
  }, [history])

  const handleProfileClick = (event) => {
    setopen(!open);
  };
  const handleProfile = () => {
    setAnchorEl(null);
  };
  console.log(loggedIn, 'loggedIn==');
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const preventDefault = (event) => event.preventDefault();

  // const Onlogout = () => {
  //   dispatch(logOut());
  // }

  console.log(walletBalance, 'walletBalance==');

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    if (path == 'Login') {
      setLoginModal(true)
    } else if (path == 'Join') {
      navigate('/joinnow')
    }
    else {
      navigate(path)
    }
  };

  const goToGamesList = (path) => {
    navigate(`/games/${path}`)
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const OnsiginSubmit = () => {
    if (formValidator.current.allValid()) {
      let postData = loginForm
      // postData.temp_token = temp_token
      dispatch(doLogin({ postData }))
    }
    else {
      setAddFormSubmit(true);
    }
  }

  useEffect(() => {
    formValidator.current.showMessages()
  }, [])

  const handleClose = () => {
    setLoginModal(false);
    setloginForm({ username: '', password: '' });
    setAddFormSubmit(false)
  }
  // const onClose = () => setLoginModal(false);


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (loggedIn) {
      dispatch(getBalance({}))
    }
  }, [loggedIn])

  useEffect(() => {
    console.log(balanceloading, "balanceloading")
    if (balanceloading === API_STATUS.FULFILLED) {
      setwalletBalance(userBalance && userBalance.amount)
    }
    if (balanceloading === API_STATUS.REJECTED) {
      toast.dismiss();
    }
  }, [balanceloading]);

  useEffect(() => {
    console.log(loading, "loading")
    if (loading === API_STATUS.FULFILLED) {
      setLoginModal(false)
      toast.dismiss();
      toast.success("Loggedin Successfully!");
      // if (loginData) {
      // window.setTimeout(function () {
      //   window.location.reload()
      // }, 1000);
      // }
    }
    if (loading === API_STATUS.REJECTED) {
      toast.dismiss();
      toast.error('Login Failed! Please check username and password');
    }
  }, [loading]);

  return (
    <>
      <LeftDrawer drawerWidth={expanded ? 0 : 220}></LeftDrawer>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} component="nav">
        <Toolbar disableGutters sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <div className="menu-wrapper">
            {/* <div>
                <MenuIcon sx={{mr: 1.5 }} onClick={() => setIsexpanded(prevState => !prevState)} />
              </div> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 1.5,
                // display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "#00e471",
                textDecoration: "none",
                "&:hover": {
                  color: '#00e471'
                },
              }}
            >
              {/* Odds11 */}
              BCASINO
              {/* <SportsCricketIcon /> */}
            </Typography>
          </div>
          <Box
            sx={{
              flexGrow: 2.5,
              display: { xs: "none", md: "flex" },
              width: "auto",
            }}
          ></Box>
          <Box
            sx={{
              flexGrow: 4,
              display: { xs: "none", md: "flex" },
              width: "auto",
            }}
          >
            <Tabs
              value={currentGameType}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#00cd6d',
                  display: 'none'
                }
              }}
              textColor="inherit"
              onChange={(e, value) => { setCurrentGameType(value); goToGamesList(value) }}
            >
              {gameTypes && gameTypes.map((item) => {
                let Icon = item.icon
                return <Tab sx={{
                  color: (currentGameType == item.key) ? '#00cd6d' : '#ffffff',
                  backgroundColor: (currentGameType == item.key) ? '#0405079c' : 'unset',
                  borderRadius: '5px',
                  margin: '2px',
                  minHeight: '40px',
                  "&:hover": {
                    color: '#00cd6d',
                  }
                }} key={item.key} iconPosition="start" value={item.key} icon={<Icon></Icon>} label={item.name} />
              })}
            </Tabs>
            {/* <Tabs value={currentHeader}
              onChange={(e, value) => { console.log(e, value); setcurrentHeader(value); handleCloseNavMenu(value) }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#00cd6d'
                }
              }}
              textColor="inherit"
            >
              {pages && pages.length && pages.map((item, i) => {
                if (item != '') {
                  return <Tab sx={{
                    color: (currentHeader == item.path) ? '#00cd6d' : '#ffffff',
                    fontWeight: 600,
                    "&:hover": {
                      color: '#00cd6d',
                      fontWeight: (currentHeader !== item.path) ? 600 : ""
                    }
                  }} key={i} value={item.path} label={item.title} />
                }
              })}
            </Tabs> */}
          </Box>
          {loggedIn == 'true' ?
            <Box sx={{ flexGrow: 0, display: { md: "flex", marginLeft: 'auto' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', }}>
                <Fab size="small"
                  sx={{
                    fontSize: 'small',
                    backgroundColor: '#d1d7e347', color: '#ffffff',
                    "&:hover": {
                      backgroundColor: '#d1d7e347', color: '#ffffff',
                    }
                  }}
                  variant='extended'>
                  ₹ {parseFloat(userBalance && userBalance.amount).toFixed(2)}
                </Fab>
                <Fab
                  color="success"
                  size="small"
                  variant="extended"
                  onClick={() => {
                    navigate('/deposit')
                  }}
                >Deposit</Fab>

                <Tooltip title="My Account">
                  <IconButton
                    onClick={handleProfileClick}
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <Avatar sx={{ width: 32, height: 32 }} src={ProfileImg}></Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              {open ? <ProfileDrawer profileOpen={true} /> : ''
              }

            </Box>
            :
            <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
              <Button
                size="small"
                onClick={() => { handleCloseNavMenu('Join') }}
                color="warning"
                variant="contained"
                sx={{
                  float: 'right',
                  marginRight: '4px'
                }}
              >
                Join
              </Button>
              <Button
                size="small"
                onClick={() => { handleCloseNavMenu('Login') }}
                variant="contained"
                color="success"
                sx={{
                  float: 'right',
                  marginRight: '4px'
                }}
              >
                Login
              </Button>
            </Box>
          }
        </Toolbar>
      </AppBar>

      <StyledDialog
        open={loginModal}
        onClose={handleClose} fullWidth={false}
        maxWidth={'sm'}

      >
        <DialogTitle sx={{ background: '#198754', color: "white", height: '5ch' }}>
          <Typography variant="h6" gutterBottom>
            LOGIN IN
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers
        >
          <div>
            <TextField
              sx={{
                width: '100%',
              }} label="Username" name="username" color="success" onChange={(e) => {
                setloginForm({
                  ...loginForm,
                  username: e.target.value
                })
              }} />
            <div >
              {formValidator.current.message(
                'username',
                loginForm.username,
                'required',
                {
                  className: `invalid_feedback 
                                ${AddFormSubmit ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Please Enter User Name',
                  }
                }
              )}
            </div>
          </div>
          <div>
            <FormControl sx={{ width: '100%', mt: 2 }} color="success">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="success"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => {
                  setloginForm({
                    ...loginForm,
                    password: e.target.value
                  })
                }}
              />
            </FormControl>
            <div >
              {formValidator.current.message(
                'password',
                loginForm.password,
                'required',
                {
                  className: `invalid_feedback 
                                ${AddFormSubmit ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Please Enter Password',
                  }
                }
              )}
            </div>
          </div>
          <div>
            <Button sx={{ mt: 2, width: '100%', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={OnsiginSubmit}>
              SIGN IN
            </Button>
          </div>
          <div>
            <Box
              sx={{
                typography: 'body1',
                mt: 1.5,
                textAlign: 'center'
              }}
            >
              <Link
                style={{ color: '#15c995' }}
                href="/joinnow"
                underline="hover"
              >
                Join Now
              </Link>
              <Link
                style={{ color: '#15c995', display: 'block' }}
                underline="hover"
              >
                Having trouble logging in?
              </Link>
            </Box>
          </div>
        </DialogContent>
      </StyledDialog>
    </>

  );
}
export default ResponsiveAppBar;
