import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
const namespace = "rooms";

const initialState = {
    availableRooms: {}
};

export const saveSocketRooms = createAsyncThunk(
    `${namespace}/filter`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            console.log(data,'rooms')
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const roomsSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },
    extraReducers: {
        [saveSocketRooms.fulfilled](state, { payload }) {
            state.availableRooms = payload
        },
    },
});

export const { clearData } = roomsSlice.actions;

export const roomsSelector = (state) => state.rooms;

export default roomsSlice.reducer;
