import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    doLogin,
    loginSelector,
    logOut,
    registerNow
} from "../../store/reducer/user";
import {
    getSubBanner,
    sportSelector
} from "../../store/reducer/sports";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { styled, useTheme } from '@mui/material/styles';
import casino from '../../assets/images/casino.jpg'
import cricket from '../../assets/images/cricket.jpg'
import { auto } from '@popperjs/core';
import { assestURL, secretKey } from "./../../services/config";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));


export default function RightDrawer({
    drawerWidth,
    drawerOpen
}) {
    const theme = useTheme();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const formValidator = useRef(new SimpleReactValidator());
    const [showPassword, setShowPassword] = useState(false);
    const [checkboxTrue, setcheckboxTrue] = useState(false)
    const { registerloading } = useSelector(loginSelector);
    const { subbannerloading, subbannerList } = useSelector(sportSelector);
    const [joinForm, setjoinForm] = useState({ name: '', mobile: '', password: '', confirm_password: '', referal_code: '', acceptTerms: false })
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = React.useState(false)
    const [profileTrue, setprofileTrue] = React.useState(false);
    const [subBannerData, setsubBannerData] = useState([])

    console.log(subbannerList, 'subbannerList===');
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        dispatch(getSubBanner({}))
    }, [])


    useEffect(() => {
        if (subbannerList && subbannerList.length) {
            let tempArr = subbannerList.filter(item=> item.status == 1)
            tempArr.slice().sort((a, b) => {
                return a.priority - b.priority;
            });
            setsubBannerData([...tempArr])
        }
    }, [subbannerList])

    useEffect(() => {
        if (drawerOpen == true) {
            setprofileTrue(true)
        } else {
            setprofileTrue(false)
        }
    }, [drawerOpen])

    return (
        <div id='rightDrawer'>
            <Drawer
                variant="permanent"
                anchor="right"
                sx={{
                    display:{xs:'none',md:'flex'},
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
                open={profileTrue}
            >
                <Box sx={{ marginTop: '55px' }}>
                    <Divider />
                    {subBannerData && subBannerData.map((item, i) => {
                        return (
                            <Card sx={{ margin: 1 }}>
                                <CardContent>
                                    <img src={`${assestURL}static/${item.promoUrl}`} style={{ width: "100%", "height": "auto" }} />
                                    <h6 style={{ marginTop: 5 }}>{item.promoName}</h6>
                                    <Divider />
                                    {/* <p style={{ margin: 0 }}>Initial Deposit Bonus 50</p>
                                    <Divider /> */}
                                    <small style={{ margin: 0, fontSize: 12, lineHeight: 0.1 }}>{item.promoDescription}</small><br />
                                    {/* <small style={{ margin: 0, fontSize: 12, lineHeight: 0.1 }}>With over 150 games to play, including our live Dealers, there's something for everyone.</small><br /> */}
                                    <Button variant="contained" color="success" size="small" sx={{ fontSize: 10 }}>
                                        Play
                                    </Button>
                                </CardContent>
                            </Card>
                        )
                    }
                    )}
                    {/* // <Card sx={{ margin: 1, backgroundColor: '#116e51' }}>
                    //     <CardContent>
                    //         <img src={casino} style={{ width: "100%", "height": "auto" }} />
                    //         <h6 style={{ marginTop: 5 }}>CASINO</h6>
                    //         <Divider />
                    //         <p style={{ margin: 0 }}>Initial Deposit Bonus 50</p>
                    //         <Divider />
                    //         <small style={{ margin: 0, fontSize: 12, lineHeight: 0.1 }}>With over 150 games to play, including our live Dealers, there's something for everyone.</small><br />
                    //         <Button variant="contained" color="success" size="small" sx={{ fontSize: 10 }}>
                    //             Play
                    //         </Button>
                    //     </CardContent>
                    // </Card> */}
                </Box>

            </Drawer>
        </div>
    );
}