import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import {
  getBalance,
  getTransaction,
  walletSelector
} from "../../store/reducer/wallet";
import { API_STATUS } from "../../utils/constants";
import Transactions from "../wallet/transactions";
import {
  getSessions,
  loginSelector,
  logOut
} from "../../store/reducer/user";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#198754",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#fff",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#198754",
          },
        },
      },
    },
  },
});

//Tab Panel Value Box
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, ml: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

export default function ProfileDrawer({ profileOpen }) {

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [AddFormSubmit, setAddFormSubmit] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [checkedSwitch, setcheckedSwitch] = useState(false);
  const [droptruea, setdroptruea] = React.useState(false);
  const [droptrueb, setdroptrueb] = React.useState(false);
  const [droptruec, setdroptruec] = React.useState(false);
  const [checked, setChecked] = React.useState(["wifi"]);
  const [profileTrue, setprofileTrue] = React.useState(false);
  const [walletBalance, setwalletBalance] = useState({ walletAmount: 0, withdrawable: 0 });
  const [userDatas, setuserDatas] = useState([]);
  const { balanceloading, transactionloading, userBalance, transactionlist } = useSelector(walletSelector);
  const { sessionToken, userData, sessionloading } = useSelector(loginSelector);

  console.log(userDatas, 'userDatas=');
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleClicka = () => {
    setdroptruea(!droptruea);
  };
  const handleClickb = () => {
    setdroptrueb(!droptrueb);
  };
  const handleClickc = () => {
    setdroptruec(!droptruec);
  };

  const handleChecked = (value) => {
    console.log(value, "value==");
    setcheckedSwitch(value);
  };
  const handleChange = (event, newValue) => {
    console.log(newValue, 'newValue==');
    setValue(newValue);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Onlogout = () => {
    dispatch(logOut({}));
  };

  useEffect(() => {
    if (userData) {
      setuserDatas(userData)
    }
  }, [userData])

  useEffect(() => {
    dispatch(getBalance({}))
  }, [])

  useEffect(() => {
    if (profileOpen == true) {
      setprofileTrue(true);
    } else {
      setprofileTrue(false);
    }
  }, [profileOpen]);

  useEffect(() => {
    console.log(balanceloading, "balanceloading")
    if (balanceloading === API_STATUS.FULFILLED) {
      setwalletBalance({
        ...walletBalance,
        walletAmount: userBalance.amount,
        withdrawable: userBalance.withdraw_amount
      })
    }
    if (balanceloading === API_STATUS.REJECTED) {
      toast.dismiss();
    }
  }, [balanceloading]);

  return (
    <Drawer
      // variant="permanent"
      anchor="right"
      sx={{

        // height: '20px',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: { xl: "500px", lg: '500px', md: '450px', sm: '450px', xs: '100%' },
          minHeight: "calc(100vh)",
          boxSizing: "border-box",
          backgroundColor: "#ffff",
        },
      }}
      open={profileTrue}
    >
      <Box sx={{ marginTop: "65px", color: "black", padding: '1rem' }}>
        <Stack
          direction="row"
        >
          <Typography variant="body1" component="body1">
            <Typography
              variant="button"
              // component="button"
              style={{ fontSize: "12px" }}
            >
              <b>{userDatas && userDatas.name}</b>
            </Typography>
            <br />
            <b>Rs {parseFloat(walletBalance.walletAmount).toFixed(2)}</b>
          </Typography>
          <Button
            variant="outlined"
            color="success"
            startIcon={<VerticalAlignBottomIcon />}
            onClick={() => { setprofileTrue(false); navigate('/deposit') }}
          >
            Deposit
          </Button>
        </Stack>
        <Stack
          direction="row"
          // justifyContent="center"
          // alignItems="center"
          m={1}
          spacing={6}
        >
          <Typography variant="body1" component="body1">
            <Typography
              variant="body2"
              component="body2"
              style={{
                fontSize: "11px",
                whiteSpace: "nowrap",
                lineHeight: "17px",
              }}
            >
              Withdrawable
            </Typography>
            <br />
            <b>Rs {parseFloat(walletBalance.withdrawable).toFixed(2)}</b>
          </Typography>
          <Typography variant="body1" component="body1">
            <Typography
              variant="body2"
              component="body2"
              style={{
                fontSize: "11px",
                whiteSpace: "nowrap",
                lineHeight: "17px",
              }}
            >
              Bet Credits
            </Typography>
            <br />
            <b>Rs 0.00</b>
          </Typography>
        </Stack>
        <Divider />
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              // scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#198754",
                },
              }}
              variant="scrollable"
              // visibleScrollbar
              aria-label="wrapped label tabs example"
              textColor="inherit"
            >
              <Tab
                label="Account"
                {...a11yProps(0)}
                style={{ width: "3%", height: "5px", fontSize: "11px" }}
              />
              <Tab
                label="Transactions"
                {...a11yProps(1)}
                style={{ width: "3%", height: "5px", fontSize: "11px" }}
              />
              {/* <Tab
                label="Alerts"
                {...a11yProps(2)}
                style={{ width: "3%", height: "5px", fontSize: "11px" }}
              /> */}
              <Tab
                label="My Offers"
                {...a11yProps(3)}
                style={{
                  width: "-1px !important",
                  height: "5px",
                  fontSize: "11px",
                }}
              />
              {/* <Tab
                label="Preferences"
                {...a11yProps(4)}
                style={{ width: "5px", height: "5px", fontSize: "11px" }}
              /> */}
            </Tabs>
            <Divider />
          </Box>
          <TabPanel value={value} index={0}>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                marginBottom: "15px",
                marginLeft: "5px",
              }}
            >
              <div className="tab-one">
                <Grid container rowSpacing={4} spacing={6}>
                  <Grid item xs={4}>
                    <div onClick={() => { navigate('/banks'); setprofileTrue(false) }} className="bank-img"></div>
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Bank
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="message-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Messages
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div onClick={() => { navigate('/my-account'); setprofileTrue(false) }} className="account-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      My Account
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="gamble-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Gambling Controls
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="activity-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      My Activity
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="history-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      History
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div onClick={() => { navigate('/game'); setprofileTrue(false) }} className="ball-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Ball Game
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="deposit-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Deposit Limits
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="closure-img" />
                    <Typography
                      sx={{ flexWrap: "wrap", fontSize: "13px" }}
                      variant="caption"
                    >
                      Account Closure
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Divider />
            <Box
              sx={{
                marginRight: "15px",
                marginTop: "2px",
                justifyContent: "right",
              }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#simple-list">
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "15px" }}
                      primary="Responsile Gambling"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#simple-list">
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "15px" }}
                      primary="Help"
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton component="a" onClick={Onlogout}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "15px" }}
                      primary="Log Out"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Transactions profileTab={true} />
            <Button variant="contained" color="success" size="small" sx={{ fontSize: 12, marginTop: '5px' }} onClick={(e) => {
              window.location.href = '/my-bets'
            }}>
              View All Transactions
            </Button>
          </TabPanel>
          {/* <div style={{ background: "#f3f5f4" }}>
            <TabPanel value={value} index={2}>
              <div className="alerts-tab">
                <div className="alerts-name">Winning Bets</div>
                <div className="alerts-toggle">
                  <Switch
                    checked={true}
                    onChange={(e) => {
                      handleChecked(e.target.checked);
                    }}
                    color="success"
                  />
                </div>
              </div>
              <div className="alerts-tab">
                <div className="alerts-name">Auto Cash Out</div>
                <div className="alerts-toggle">
                  <Switch color="success" checked={true} />
                </div>
              </div>
              <div className="alerts-tab">
                <div className="alerts-name">Bonus Bets</div>
                <div className="alerts-toggle">
                  <Switch color="success" />
                </div>
              </div>
              <div className="alerts-tab">
                <div className="alerts-name">Event Alerts</div>
                <div className="alerts-toggle">
                  <Switch color="success" checked={true} />
                </div>
              </div>
            </TabPanel>
          </div> */}
          <div style={{ background: "#f3f5f4" }}>
            <TabPanel value={value} index={2}>
              <div className="norecords">
                <p style={{ textAlign: "center", marginBottom: "50px", color: 'white' }}>
                  You Currently have no offers available
                </p>
              </div>
              {/* <Typography variant="body1">
                You Currently have no offers available
              </Typography> */}
            </TabPanel>
          </div>
          {/* <div style={{ background: "#f3f5f4" }}>
            <TabPanel value={value} index={4}>
              <List
                sx={{ width: "100%", fontSize: "12px" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              // subheader={
              //     <ListSubheader component="div" id="nested-list-subheader">
              //         Nested List Items
              //     </ListSubheader>
              // }
              >
                <ListItemButton onClick={handleClicka}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    primary="Time Zone"
                  />
                  {droptruea ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={droptruea} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="CST"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="IST"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="PST"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="EST"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <Divider />

                <ListItemButton onClick={handleClickb}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    primary="Odds Display"
                  />
                  {droptrueb ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={droptrueb} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="Fractional"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="Decimal"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="American"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="American/Fractional"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <Divider />

                <ListItemButton onClick={handleClickc}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    primary="Max Inactivity Time"
                  />
                  {droptruec ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={droptruec} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="20 Minutes"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="1 Hour"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="2 Hours"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="3 Hours"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="6 Hours"
                      />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "13px" }}
                        primary="12 Hours"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <Divider />

                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    id="switch-list-label-wifi"
                    primary="Show Balance"
                  />
                  <Switch
                    color="success"
                    edge="end"
                    onChange={handleToggle("wifi")}
                    checked={checked.indexOf("wifi") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-wifi",
                    }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    id="switch-list-label-bluetooth"
                    primary="Skip Bet Confirmation"
                  />
                  <Switch
                    edge="end"
                    color="success"
                    onChange={handleToggle("bluetooth")}
                    checked={checked.indexOf("bluetooth") !== -1}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-bluetooth",
                    }}
                  />
                </ListItem>
              </List>
            </TabPanel>
          </div> */}
        </Box>
        <Divider />
      </Box>
    </Drawer >
  );
}
