import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { emitters } from "../../services/socket/emitter-listener-types";
import { Emitters } from "../../services/socket/emitters";
import { commonSelector } from "../../store/reducer/common";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../websocket";
import { inplaySelector } from "../../store/reducer/inplay";
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActions } from '@mui/material';


export default function InPlayCricket() {
  let loggedIn = localStorage.getItem('isAuthenticated');
  const ws = useContext(WebSocketContext);
  const { socketState } = useSelector(commonSelector);
  const { inplayFilter } = useSelector(inplaySelector);
  const navigate = useNavigate();
  const [cricketMatches, setCricketMatches] = useState([]);
  useEffect(() => {
    if (inplayFilter.length > 0) {
      let cricketMatches = _.filter(inplayFilter, { sport_id: "3" }).map(
        (item) => {
          return {
            ...item,
            league_id: item.league.id,
          };
        }
      );
      setCricketMatches([...cricketMatches]);
    }
  }, [inplayFilter]);
  useEffect(() => {
    let triggerEvent = () => {
      Emitters.InPlayFilter(ws, { sport_id: 3 });
    };
    if (socketState && socketState.connected) {
      triggerEvent();
      let interval = setInterval(() => {
        triggerEvent();
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [socketState]);

  const onClickFun = (item) => {
    navigate(`/in-play-event/cricket/${item.id}`)
    // if (loggedIn) {
    //   navigate(`/in-play-event/cricket/${item.id}`)
    // } else {
    //   toast.dismiss();
    //   toast.error('Please login to place bet!');
    // }
  }

  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Grid container spacing={0}>
          {cricketMatches &&
            cricketMatches.length ?
            cricketMatches.map((item, index) => {
              return (
                <Grid key={index} xs={16} md={3} lg={4}>
                  <Paper sx={{ padding: '5px' }} elevation={0}>
                    <Card onClick={() => {
                      onClickFun(item)
                    }} sx={{
                      cursor: 'pointer',
                      ':hover': {
                        boxShadow: 15
                      },
                      display: 'flex'
                    }}>
                      <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
                        <Typography component="div" variant="h7">
                          <>
                            <span>{item.home.name}</span>
                            {item.away && item.away.name ? <span>{` vs ${item.away && item.away.name}`}</span> : ''}
                          </>
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontSize: '13px' }} color="text.secondary" component="div">
                          {item && item.league && item.league.name}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ width: '25%' }}>
                        <Button variant="outlined" color="success" size="small" sx={{ fontSize: 12, fontWeight: 'bold', float: 'right' }} >
                          Play Now
                        </Button>
                      </CardActions>
                    </Card>
                  </Paper>
                </Grid>
              );
            }) : ''}
        </Grid >
      </Box>
    </>
  );
}
