import { toast } from "react-toastify";
import { updateGameDetails, updateHistories, updateUserBets } from "../../../store/reducer/fastpartity";
import { listeners } from "./emitter-listener-types"

export default ({
    socket,
    dispatch,
    res
}) => {
    let { data, error, message, type, statusCode } = res
    switch (type) {
        case "fp-game-push-data":
            dispatch(updateGameDetails(data))
            break;
        case "fp-game-history":
            dispatch(updateHistories(data))
            break;
        case "fp-game-user-bets":
            dispatch(updateUserBets(data))
            break;
    }

}
