import { updateGameDetails } from "../../store/reducer/burstGames"
import { saveInPlayEventData, saveInPlayFilterData } from "../../store/reducer/inplay"
import { listeners } from "./emitter-listener-types"
import { saveSocketRooms } from "../../store/reducer/room";
import { walletAmountUpdate } from "../../store/reducer/wallet";
import { toast } from "react-toastify";
import FPListeners from "./fastparity/listeners";
import DiceGameListeners from "./dice/listeners";
import RoulettesListeners from "./roulettes/listeners";

export const Listeners = ({ dispatch, socket }) => {
    socket.on(listeners.InPlayFilter, (res) => {
        dispatch(saveInPlayFilterData(res))
    })
    socket.on(listeners.InPlayEvent, (res) => {
        dispatch(saveInPlayEventData(res))
    })
    socket.on(listeners.BurstGameEmitValue, (res) => {
        // console.log(res,'res')
        dispatch(updateGameDetails(res))
    })
    socket.on(listeners.AvailableRooms, (res) => {
        dispatch(saveSocketRooms(res))
    })
    

    socket.on(listeners.notification, (res) => {
        let { data, error, message, type } = res
        FPListeners({
            socket,
            dispatch,
            res
        })
        DiceGameListeners({
            socket,
            dispatch,
            res
        })
        RoulettesListeners({
            socket,
            dispatch,
            res
        })
        switch (type) {
            case "wallet-amount-update":
                dispatch(walletAmountUpdate(data))
                break
            default:
                if (error) {
                    toast.error(message)
                } else toast.info(message)
                break
        }

    })
}

