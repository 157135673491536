import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import PaymentMethods from "./PaymentMethods";
import QRtransactions from "./QRtransactions";
import PropTypes from "prop-types";
import { Box, Toolbar, Divider, Tabs, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";

//Tab Panel Value Box
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Deposit() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        console.log(newValue, 'newValue==');
        setValue(newValue);
    };

    return (
        <div style={{width: '100%'}}>
            <Container maxWidth="100%">
                <div style={{ marginTop: '80px' }}>
                    {/* <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="col-md-6 mb-3"
                        fill
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                        <Tab eventKey="payment" title="Payment">
                            <PaymentMethods />
                        </Tab>
                        <Tab eventKey="qr" title="QR Transactions">
                            <QRtransactions />
                        </Tab>
                    </Tabs> */}
                    <Box >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', maxHeight: 450, position: 'sticky' }}>
                            <Tabs value={value} onChange={handleChange}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#198754",
                                    },
                                }}
                                textColor="inherit"
                            >
                                <Tab sx={{
                                    // "&:hover": {
                                    //     color: '#15c995'
                                    // },
                                    fontWeight: 700,
                                    color: value == 0 ? '#15c995' : ''
                                }} label="Payments" {...a11yProps(0)} />
                                <Tab sx={{
                                    fontWeight: 700,
                                    color: value == 1 ? '#15c995' : ''
                                }}
                                    label="QR Transactions" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} >
                            <PaymentMethods />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <QRtransactions />
                        </TabPanel>
                    </Box>

                </div>
            </Container>
        </div >
    )
}

export default Deposit