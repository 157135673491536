import * as React from 'react';
import { Home, History, SportsCricket, CurrencyExchangeTwoTone, SportsBasketball, FlightTakeoff, SportsFootball, SportsHockey, SportsTennis, SportsVolleyball, SportsBaseball } from '@mui/icons-material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CasinoIcon from '@mui/icons-material/Casino';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
export const games = [

    {
        name: 'Cricket',
        icon: SportsCricket,
        key: 'cricket'
    },
    {
        name: 'Football',
        icon: SportsFootball,
        key: 'football'
    },
    {
        name: 'Ice Hockey',
        icon: SportsHockey,
        key: 'ice-hockey'
    },
    {
        name: 'Basketball',
        icon: SportsBasketball,
        key: 'basketball'
    },
    {
        name: 'Tennis',
        icon: SportsTennis,
        key: 'tennis'
    },
    {
        name: 'Volleyball',
        icon: SportsVolleyball,
        key: 'volleyball'
    },
    {
        name: 'Badminton',
        icon: SportsBaseball,
        key: 'badminton'
    }
]

export const mybets = [

    {
        name: 'Cash Out',
        key: 'cashout'
    },
    {
        name: 'Live Now',
        key: 'placed'
    },
    {
        name: 'Unsettled',
        key: 'succedded'
    },
    {
        name: 'Settled',
        key: 'credited'
    },
    {
        name: 'All',
        key: 'all'
    },
]

const pages = [
    { title: "Home", value: "/", path: '/', icon: Home },
    // { title: "Live", value: "/in-play", path: '/in-play', icon: SportsCricket, subPages: ['/in-play-event'] },
];


export const getPages = () => {
    let loggedIn = localStorage.getItem('isAuthenticated');
    return pages;
    // return loggedIn ? [
    //     ...pages,
    //     { title: "Bet History", value: "/my-bets", path: '/my-bets', icon: History },
    //     // { title: "Aviator", value: "/game", path: '/game', icon: FlightTakeoff },
    // ] : [
    //     ...pages,
    //     // { title: "Aviator", value: "/game", path: '/game', icon: FlightTakeoff },
    // ]
}

export const getCurrentPage = (page) => {
    let pages = getPages()
    let findPage = pages.find(item => item.value.includes(page) || (item.subPages && item.subPages.some(item => page.includes(item))))
    return findPage && findPage.value
}

export const gameTypes = [
    {
        key: 'casino',
        name: 'Casino',
        icon:CasinoIcon
    },
    {
        key: 'sports',
        name: 'Sports',
        icon:SportsSoccerIcon
    },
    {
        key: 'lottery',
        name: 'Lottery',
        icon:ViewCarouselIcon
    },
    {
        key: 'racing',
        name: 'Racing',
        icon:PedalBikeIcon
    },
    // {
    //     key: 'bingo',
    //     name: 'Bingo',
    //     icon:LocalActivityIcon
    // },
    // {
    //     key: 'updown',
    //     name: 'Up Down',
    //     icon:BoltIcon
    // }
]