import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
import { burstGameCollectBet, burstGamePlaceBet, getCurrentStakes, getMyBetsApi, getRecentOddsApi, getTopWins } from "../../services/api";
import { toast } from "react-toastify";
import { getBalance } from "./wallet";
const namespace = "burstgames";

const initialState = {
    details: {
        _id: null,
        type: null,
        odds: 0,
        gameStartWithin: null,
        gameStarted: false,
        burst: false,
        maxOdds: 0,
        endedOn: null,
        startedOn: null
    },
    myBets: [],
    recentOdds: [],
    currentStakes: [],
    topWins: [],
    betStatus: 'initial'
};

export const updateGameDetails = createAsyncThunk(
    `${namespace}/odds`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const placeBet = createAsyncThunk(
    `${namespace}/place-bet`,
    async ({ payload, updateBetPlacedId, index }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await burstGamePlaceBet({ payload });
            updateBetPlacedId(index, data?.data?.result?._id)
            dispatch(getBalance({}))
            return true
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const collectBet = createAsyncThunk(
    `${namespace}/collect-bet`,
    async ({ payload, updateBetCollectedState, index }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await burstGameCollectBet({ payload });
            updateBetCollectedState(index, data?.data?.result?._id)
            dispatch(getBalance({}))
            return true
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getRecentOdds = createAsyncThunk(
    `${namespace}/recent-odds`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getRecentOddsApi();
            return data?.data?.result
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getMyBets = createAsyncThunk(
    `${namespace}/my-bets`,
    async (postData, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getMyBetsApi(postData);
            return data?.data?.result
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const currentStakesGet = createAsyncThunk(
    `${namespace}/current-stakes`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getCurrentStakes(postData);
            return data?.data?.result
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const topWinsGet = createAsyncThunk(
    `${namespace}/top-wins`,
    async (postData, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getTopWins(postData);
            return data?.data?.result
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const burstGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: (state) => {
            state.details = initialState.details
            state.betStatus = initialState.betStatus
        },
        clearBetDetails: (state) => {
            state.details = null
        },
        clearBetPlacedDetails: (state) => {
            state.betStatus = 'initial'
        }
    },
    extraReducers: {
        [updateGameDetails.fulfilled](state, { payload }) {
            state.details = payload
        },
        [placeBet.fulfilled](state, { }) {
            state.betStatus = 'placed'
            toast.success('Bet placed successfully..')
        },
        [placeBet.rejected](state, { }) {
            state.betStatus = 'error-placed'
            toast.error('Error while placing bet..')
        },
        [collectBet.fulfilled](state, { }) {
            state.betStatus = 'collected'
            toast.success('Amount collected and transferred to your wallet successfully..')
        },
        [collectBet.rejected](state, { }) {
            state.betStatus = 'error-collected'
            toast.error('Error while winning amount..')
        },
        [getRecentOdds.fulfilled](state, { payload }) {
            state.recentOdds = payload
        },
        [getMyBets.fulfilled](state, { payload }) {
            state.myBets = payload
        },
        [currentStakesGet.fulfilled](state, { payload }) {
            state.currentStakes = payload
        },
        [topWinsGet.fulfilled](state, { payload }) {
            state.topWins = payload
        },
    },
});

export const { clearData, clearBetDetails, clearBetPlacedDetails } = burstGamesSlice.actions;

export const burstGameSelector = (state) => state.burstgames;

export default burstGamesSlice.reducer;
