import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const namespace = "common";

const initialState = {
    socketState: {}
};

export const socketStatusUpdate = createAsyncThunk(
    `${namespace}/socketStatusUpdate`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const commonSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },
    extraReducers: {
        [socketStatusUpdate.fulfilled](state, { payload }) {
            state.socketState = payload;
        }
    },
});

export const { clearData } = commonSlice.actions;

export const commonSelector = (state) => state.common;

export default commonSlice.reducer;
