import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
const namespace = "inplay";

const initialState = {
    inplayFilter: [],
    inplayEvent: [],
    errorMessage: null
};

export const saveInPlayFilterData = createAsyncThunk(
    `${namespace}/filter`,
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const saveInPlayEventData = createAsyncThunk(
    `${namespace}/event`,
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const inplaySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },
    extraReducers: {
        [saveInPlayFilterData.fulfilled](state, { payload }) {
            console.log(payload,'payload')
            state.loading = API_STATUS.FULFILLED;
            state.inplayFilter = payload
        },
        [saveInPlayEventData.fulfilled](state, { payload }) {
            console.log(payload,'payload')
            state.loading = API_STATUS.FULFILLED;
            state.inplayEvent = payload
        },
    },
});

export const { clearData } = inplaySlice.actions;

export const inplaySelector = (state) => state.inplay;

export default inplaySlice.reducer;
