import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

/* Users API*/
export const tryLogin = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/login", postData);
};

export const userlogOut = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/logout", Http.getAuthToken());
};

export const getSessionToken = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/session", Http.getAuthToken());
};

export const registerSubmit = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/register", postData);
};
export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/forgot-password", postData);
};

export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/change_password", postData, Http.getAuthToken());
};

export const userAddress = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/address", postData, Http.getAuthToken());
};

export const addBankdetails = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/addbankDetails", postData, Http.getAuthToken());
};

export const getAddress = (userId) => {
    console.log(userId, 'userId==/');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/getAddress/" + userId, '', Http.getAuthToken());
};

export const userBankdetails = (userId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/getbankDetails/" + userId, Http.getAuthToken());
};

// Sports API

export const sportsList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/sport/sportList", Http.getAuthToken());
};

export const mainBannerlist = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/promo/getMainbanner", Http.getAuthToken());
};

export const subBannerlist = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/promo/getSubbanner", Http.getAuthToken());
};

/* Bets API*/

export const betPlace = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/bet/place", postData, Http.getAuthToken());
};

export const proceedCashout = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/bet/proceed/cashout", postData, Http.getAuthToken());
};

export const betList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/bet/list", postData, Http.getAuthToken());
};

/* Wallets API*/

export const walletBalance = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/wallet/balance", '', Http.getAuthToken());
};

export const getTransactionList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/wallet/transactions", postData, Http.getAuthToken());
};

export const paymentQRlist = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/transaction/payment_qr", postData, Http.getAuthToken());
};

export const uploadQR = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/transaction/create_payment", postData, Http.getAuthToken());
};

export const QRtransactionList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/transaction/list_payments", postData, Http.getAuthToken());
};
export const addwithdrawRequest = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/transaction/request-withdraw", postData, Http.getAuthToken());
};

export const withdrawList = (postData, userID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/transaction/withdraw/list?" + userID, postData, Http.getAuthToken());
};

export const withdrawStatus = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/transaction/withdraw/status/:withdrawal_id", postData, Http.getAuthToken());
};

export const burstGamePlaceBet = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/burst-games/place", postData, Http.getAuthToken());
};

export const burstGameCollectBet = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/burst-games/collect", postData, Http.getAuthToken());
};

export const getRecentOddsApi = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/burst-games/recent-odds", Http.getAuthToken());
};

export const getMyBetsApi = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/burst-games/my-bets", postData, Http.getAuthToken());
};

export const getTopWins = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/burst-games/top-wins", postData, Http.getAuthToken());
};

export const getCurrentStakes = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/burst-games/current-stakes", postData, Http.getAuthToken());
};

export const gamesList = (payload) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/games/list", payload, Http.getAuthToken());
}

export const fastParitybetList = (payload) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + `/fastparity/bets`, payload, Http.getAuthToken());
}

export const diceGameBetList = (payload) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + `/dice/bets`, payload, Http.getAuthToken());
}