import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
    doLogin,
    loginSelector,
    logOut,
    registerNow,
} from "../../store/reducer/user";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils/constants";
import {
    getBalance,
    getTransaction,
    getPaymentQR,
    walletSelector,
    uploadQRtransaction,
    listQRtransaction
} from "../../store/reducer/wallet";
import Modal from '@mui/material/Modal';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from 'dayjs';
// import Container from "@mui/material/Container";
import { Container } from "react-bootstrap";
import { Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import TablePaginationActions from "../../utils/pagination";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';
const Uploadstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 450,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function QRtransactions() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userID = localStorage.getItem('userID')
    const formValidator = useRef(new SimpleReactValidator());
    const [modalopen, setmodalopen] = useState(false);
    const [uploadopen, setuploadopen] = useState(false);
    const [alertModal, setalertModal] = useState(false);
    const [open, setOpen] = useState(true);
    const [payment_qr, setpayment_qr] = useState('');
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [fileUpload, setfileUpload] = useState('');
    const [transactionUpload, setTransactionUpload] = useState({ qr_img: '', qr_path: '', amount: '', utr_no: '' });
    const { balanceloading, transactionloading, userBalance, uploadQRlist, paymentQR, uploadQRloading, uploadQRlistCount } = useSelector(walletSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");

    const handleClose = () => {
        setOpen(false);
        navigate("/");
    };

    const uploadOpenFunc = () => setuploadopen(true);
    const uploadClose = () => {
        setuploadopen(false); setAddFormSubmit(false); setTransactionUpload({ qr_img: '', qr_path: '', amount: '', utr_no: '' })
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        qrlistDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        qrlistDispatch();
    };

    const qrlistDispatch = () => {
        let payload = {
            limit: rowsPerPage,
            page: page + 1,
            additionalPayload: {
                user_id: userID
            }
        }

        dispatch(listQRtransaction({
            payload
        }))
    };

    useEffect(() => {
        qrlistDispatch()
    }, [])

    const handleFileUpload = (e) => {
        e.preventDefault();

        const image = e.target.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Select Valid Image - (jpg,jpeg,png)')
            return false;
        } else {
            const reader = new FileReader();
            const file = e.target.files[0];

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                const binaryStr = reader.result
                setTransactionUpload({
                    ...transactionUpload,
                    qr_path: binaryStr,
                    qr_img: file.name,
                })
                // setfileUpload(binaryStr)
                // setFileName(file.name)
            }
            reader.readAsDataURL(file);
        }
    }

    const qrTrasanctionSubmit = () => {
        setAddFormSubmit(true);

        let postData = transactionUpload;

        if (formValidator.current.allValid()) {
            dispatch(uploadQRtransaction({ postData }));
            // toast.success('QR Transaction uploaded successfully.!');
            // qrlistDispatch()
            setuploadopen(false)
            setAddFormSubmit(false)
            setTransactionUpload({
                amount: '',
                qr_img: '',
                qr_path: '',
                utr_no: ''
            })
        }
    }

    useEffect(() => {
        console.log(uploadQRloading, "uploadQRloading")
        if (uploadQRloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success('QR Transaction uploaded successfully.!');
            qrlistDispatch()
        }
        if (uploadQRloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('Upload Failed! Please check uploaded File');
        }
    }, [uploadQRloading]);

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    return (
        <div>
            <div style={{ justifyContent: 'right', float: 'right', marginBottom: '15px' }}>
                <Button
                    variant="contained"
                    color="success"
                    component="label"
                    onClick={uploadOpenFunc}
                    startIcon={<FileUploadOutlinedIcon />}
                >
                    Upload QR Transactions
                    {/* <input
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                            /> */}
                </Button>
            </div>
            <Typography variant='h6' sx={{ fontWeight: 600 }}>QR Tansaction List</Typography>
            <Container style={{ margin: '10px' }} maxWidth="100%">
                <Box sx={{ maxWidth: '100%', float: 'left', justifyContent: 'center' }}>
                    <div style={{ border: '0.5px solid grey', width:'100%' }}>
                        <TableContainer
                            sx={{
                                height: 420,
                                width: { md:'100%', sm:'100%', xs:'100%'}
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Transaction ID
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Name
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Amount
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Transaction Date
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                QR-Transaction Status
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadQRlist && uploadQRlist.map((item) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell align="">
                                                    {item.transactionID}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {item.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {item.amount}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {dayjs(item.createdOn).format('DD-MM-YYYY hh:mm:ss A')}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {item.status == 0 ? <Chip label="Pending" color="primary" variant="outlined" /> : item.status == 1 ? <Chip label="Approved" color="success"></Chip> : item.status == 2 ? <Chip label="Disapproved" color="error" /> : ''}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            colSpan={8}
                                            count={uploadQRlistCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "rows per page",
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                </Box>
                <Modal
                    open={uploadopen}
                    onClose={uploadClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box component="form" sx={Uploadstyle}>
                        <IconButton
                            aria-label="close"
                            onClick={uploadClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: "grey",
                                "&:hover": {
                                    color: '#15c995'

                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput1" class="form-label">Enter Amount<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" class="form-control" name="amount" id="exampleFormControlInput1" placeholder="Amount Rs" onChange={(e) => {
                                setTransactionUpload({
                                    ...transactionUpload,
                                    amount: e.target.value
                                })
                            }} />
                            {formValidator.current.message(
                                'amount',
                                transactionUpload.amount,
                                'required|numeric',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Amount',
                                        numeric: 'Please Enter Number',
                                    }
                                }
                            )}
                        </div>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput1" class="form-label">Unique Transaction Reference(UTR) No<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" class="form-control" name="utr_no" id="exampleFormControlInput1" placeholder="UTR No" onChange={(e) => {
                                setTransactionUpload({
                                    ...transactionUpload,
                                    utr_no: e.target.value
                                })
                            }} />
                            {formValidator.current.message(
                                'utr_no',
                                transactionUpload.utr_no,
                                'required',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter UTR No',
                                    }
                                }
                            )}
                        </div>
                        <div class="mb-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput2" class="form-label">Upload QR Transactions<span style={{ color: 'red' }}>*</span></label>
                            <Button
                                variant="contained"
                                color="success"
                                component="label"
                                sx={{ width: '43ch' }}
                                id="exampleFormControlInput2"
                                startIcon={transactionUpload.qr_path ? <DownloadDoneRoundedIcon /> : <FileUploadOutlinedIcon />}
                            >
                                {transactionUpload.qr_path ? 'Uploaded' : 'Upload'}
                                <input
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    hidden
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            {formValidator.current.message(
                                'image',
                                transactionUpload.qr_path,
                                'required',
                                {
                                    className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Upload Screenschot',
                                    }
                                }
                            )}
                        </div>
                        <Divider sx={{ color: 'black', marginTop: '30px' }} />
                        <button type="button" class="offset-md-4 col-md-4 mt-3 btn btn-success" onClick={qrTrasanctionSubmit}>Submit</button>
                    </Box>
                </Modal>
            </Container>
        </div>
    )
}

export default QRtransactions
