import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fastParitybetList} from "../../services/api";
import { toast } from "react-toastify";

const namespace = "fastparity";

const initialState = {
    details: null,
    history: [],
    userBets: [],
    bets:[],
    betsCount:0
};

export const updateGameDetails = createAsyncThunk(
    `${namespace}/details`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const updateHistories = createAsyncThunk(
    `${namespace}/history`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const updateUserBets = createAsyncThunk(
    `${namespace}/user-bets`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


export const getFastParityGameBets = createAsyncThunk(
    `${namespace}/bets`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await fastParitybetList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);


const fastPatityGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: (state) => {
            state.details = initialState.details
        }
    },
    extraReducers: {
        [updateGameDetails.fulfilled](state, { payload }) {
            if (payload?.gameStartWithin == 0)
                state.userBets = []
            state.details = payload
        },
        [updateHistories.fulfilled](state, { payload }) {
            state.history = [...payload, ...state.history]
        },
        [updateUserBets.fulfilled](state, { payload }) {
            state.userBets = payload
        },
        [getFastParityGameBets.fulfilled](state, { payload }) {
            state.betsCount = payload?.data?.data?.count;
            state.bets = payload?.data?.data?.result;
        },
    },
});
export const { clearData } = fastPatityGamesSlice.actions;

export const fastparityGameSelector = (state) => state.fastPatityGames;

export default fastPatityGamesSlice.reducer;
