import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tryLogin, forgotPassword, resetPassword, registerSubmit, getSessionToken, userlogOut, userAddress, addBankdetails, getAddress, userBankdetails } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
const namespace = "login";

const initialState = {
    loading: "initial",
    forgotloading: "initial",
    sessionloading: "initial",
    resetloading: "initial",
    registerloading: "initial",
    logoutloading: "initial",
    addressloading: "initial",
    addbankloading: "initial",
    userAddressloading: "initial",
    userBankloading: "initial",
    errorMessage: null,
    loginData: null,
    userData: null,
    userAddressDetails: null,
    userBankDetails: null,
    sessionToken: null
};

export const doLogin = createAsyncThunk(
    `${namespace}/doLogin`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await tryLogin({ payload: postData });
            localStorage.setItem('authToken', data?.data?.data?.token)
            localStorage.setItem('isAuthenticated', true)
            dispatch(getSessions({}))

            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const logOut = createAsyncThunk(
    `${namespace}/logOut`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await userlogOut();
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const registerNow = createAsyncThunk(
    `${namespace}/registerNow`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await registerSubmit({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getSessions = createAsyncThunk(
    `${namespace}/getSessions`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getSessionToken();
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const forgotPwd = createAsyncThunk(
    `${namespace}/forgotPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await forgotPassword({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const resetPwd = createAsyncThunk(
    `${namespace}/resetPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await resetPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const Addaddress = createAsyncThunk(
    `${namespace}/Addaddress`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await userAddress({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getuserAddress = createAsyncThunk(
    `${namespace}/getuserAddress`,
    async ({ userId }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getAddress(userId);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const Addbankdetails = createAsyncThunk(
    `${namespace}/Addbankdetails`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await addBankdetails({ payload: postData });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getBankDetails = createAsyncThunk(
    `${namespace}/getBankDetails`,
    async ({ userID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(userID, 'userId==');
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await userBankdetails(userID);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
        // logOut: () => {
        //     // let tempArray = localStorage.getItem('temporaryToken')
        //     localStorage.clear();
        //     // localStorage.setItem('temporaryToken', tempArray)
        //     // localStorage.clear();
        //     window.location.reload(true);
        // },
    },
    extraReducers: {
        [doLogin.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [doLogin.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log({ payload }, 'login--data');
            state.loginData = payload?.data;
            state.sessionToken = payload?.data.data.token;
        },
        [doLogin.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getSessions.pending](state) {
            state.sessionloading = API_STATUS.PENDING;
        },
        [getSessions.fulfilled](state, { payload }) {
            state.sessionloading = API_STATUS.FULFILLED;
            console.log({ payload }, 'login--data');
            state.loginData = payload?.data;
            state.sessionToken = payload?.data.data.token;
            localStorage.setItem('authToken', payload?.data?.data?.token)
            localStorage.setItem('userType', payload?.data?.data?.user?.userType)
            localStorage.setItem('userDetails', JSON.stringify(payload?.data?.data?.user))
            localStorage.setItem('userID', payload?.data?.data.user._id)
            localStorage.setItem('isAuthenticated', true)

        },
        [getSessions.rejected](state, action) {
            state.sessionloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
            localStorage.clear();
            window.location.href = '/';
            // window.location.reload(true);
        },
        [logOut.pending](state) {
            state.logoutloading = API_STATUS.PENDING;
        },
        [logOut.fulfilled](state, { payload }) {
            state.logoutloading = API_STATUS.FULFILLED;
            localStorage.clear();
            window.location.reload();
            // window.location.href = '/';
        },
        [logOut.rejected](state, action) {
            state.logoutloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [registerNow.pending](state) {
            state.registerloading = API_STATUS.PENDING;
        },
        [registerNow.fulfilled](state, { payload }) {
            state.registerloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [registerNow.rejected](state, action) {
            state.registerloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [forgotPwd.pending](state) {
            state.forgotloading = API_STATUS.PENDING;
        },
        [forgotPwd.fulfilled](state, { payload }) {
            state.forgotloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [forgotPwd.rejected](state, action) {
            state.forgotloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [resetPwd.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [resetPwd.fulfilled](state, { payload }) {
            state.resetloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [resetPwd.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [Addaddress.pending](state) {
            state.addressloading = API_STATUS.PENDING;
        },
        [Addaddress.fulfilled](state, { payload }) {
            state.addressloading = API_STATUS.FULFILLED;
        },
        [Addaddress.rejected](state, action) {
            state.addressloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [Addbankdetails.pending](state) {
            state.addbankloading = API_STATUS.PENDING;
        },
        [Addbankdetails.fulfilled](state, { payload }) {
            state.addbankloading = API_STATUS.FULFILLED;
        },
        [Addbankdetails.rejected](state, action) {
            state.addbankloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [getuserAddress.pending](state) {
            state.userAddressloading = API_STATUS.PENDING;
        },
        [getuserAddress.fulfilled](state, { payload }) {
            state.userAddressloading = API_STATUS.FULFILLED;
            state.userAddressDetails = payload?.data.data.userAddress;
        },
        [getuserAddress.rejected](state, action) {
            state.userAddressloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
        [getBankDetails.pending](state) {
            state.userBankloading = API_STATUS.PENDING;
        },
        [getBankDetails.fulfilled](state, { payload }) {
            state.userBankloading = API_STATUS.FULFILLED;
            state.userBankDetails = payload?.data.data.result;
        },
        [getBankDetails.rejected](state, action) {
            state.userBankloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.data;
        },
    },
});

export const { clearData } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
