import React, { useEffect, useState } from 'react'
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import skyImg from '../../assets/images/new/bg.png'
import footballImg from '../../assets/images/new/aviator-game-logo.png'
import { Box } from "@mui/material";
import { useDispatch } from 'react-redux';
import flewAwaySound from '../../assets/sound/flew-away1.mp3'
import useSound from 'use-sound';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress sx={{ display: { xs: 'none', md: 'flex' } }} color="inherit" size={100} {...props} />
            <CircularProgress sx={{ display: { xs: 'flex', md: 'none' } }} color="inherit" size={50} {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.label ? <Typography className={"zoom-in-out-box"} variant="h5" component="div" color="inherit">
                    {props.label}
                </Typography> : ""}

            </Box>
        </Box>
    );
}

function Flightburst({
    gameDetails,
    odds,
    setOdds,
    gameStatus,
    ClearBurstGameData,
    setGameStatus,
    clearState
}) {
    const dispatch = useDispatch()
    const [flewAwayMusic, flewAwayMusicOptions] = useSound(flewAwaySound);
    const [scene, setScene] = useState(new THREE.Scene())
    const [cricketBall, setCricketBall] = useState(null)
    const [bgTexture, setbgTexture] = useState(null)
    const [renderer, setrenderer] = useState(null)
    const [aviatorStatus, setAviatorStatus] = useState('pending')
    const [camera, setcamera] = useState(null)
    const [line, setline] = useState(null)
    const textureLoader = new THREE.TextureLoader();
    scene.matrixWorldAutoUpdate = true;


    const startGame = () => {
        if (scene && cricketBall && bgTexture && renderer && camera) {

            //Add Line
            const material = new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 15
            });
            const geometry = new THREE.BufferGeometry()
            const line = new THREE.Line(geometry, material);
            const points = []
            line.material.color = new THREE.Color('red');
            line.material.needsUpdate = true;

            scene.add(line);
            setline(line)

            // Set the initial position of the cricket ball

            geometry.setFromPoints(points);

            // Set the target position of the cricket ball
            const targetX = 13;
            // const targetX = 6.5;
            const targetY = 3;
            // const targetY = 1.5;

            // Calculate the total duration for ball movement
            const totalDuration = 100000;
            // const totalDuration = ballBurstTime;
            const speedX = (targetX - cricketBall.position.x) / totalDuration;
            const speedY = (targetY - cricketBall.position.y) / totalDuration;


            // Create the animation
            let animationStartTime = null;
            let actualXpositionOfCricketBall = 0
            const animate = function (timestamp) {
                let aviatorFlewAway = localStorage.getItem('aviatorStatus') === 'flew-away'
                if (!animationStartTime) animationStartTime = timestamp;
                const elapsedTime = timestamp - animationStartTime;

                if (cricketBall.position.x <= 3.5 || aviatorFlewAway) {
                    if (aviatorFlewAway) {
                        cricketBall.position.x += speedX * elapsedTime / 10
                        cricketBall.position.y += speedY * elapsedTime / 10
                    } else {
                        let variation = {
                            x: -0.0005,
                            y: -0.001
                        }
                        if (cricketBall.position.x < 1) {
                            variation = {
                                x: -0.0005,
                                y: -0.001
                            }
                        } else if (cricketBall.position.x < 2) {
                            variation = {
                                x: 0,
                                y: 0
                            }
                        } else if (cricketBall.position.x < 3) {
                            variation = {
                                x: 0.0005,
                                y: 0.001
                            }
                        } else if (cricketBall.position.x < 3.5) {
                            variation = {
                                x: 0.001,
                                y: 0.002,
                            }
                        }
                        cricketBall.position.x += (speedX * elapsedTime / 50) + variation.x
                        cricketBall.position.y += (speedY * elapsedTime / 50) + variation.y
                    }
                    actualXpositionOfCricketBall = cricketBall.position.x

                    if (!aviatorFlewAway) {
                        const textureOffsetX = ((cricketBall.position.x + 0.5) / 2.5);
                        bgTexture.offset.set(textureOffsetX, 0);
                        points.push(new THREE.Vector3(
                            cricketBall.position.x - 0.6,
                            cricketBall.position.y - 0.6,
                            cricketBall.position.z
                        ));
                        geometry.setFromPoints(points);
                    }
                } else {
                    actualXpositionOfCricketBall += speedX * elapsedTime / 50
                    const textureOffsetX = ((actualXpositionOfCricketBall + 0.5) / 2.5);
                    bgTexture.offset.set(textureOffsetX, 0);
                }
                renderer.render(scene, camera);
                requestAnimationFrame(animate);
            };
            setAviatorStatus('start')
            // Start the animation immediately
            animate(performance.now());


        }
    }

    const initilize = () => {
        const canvasContainer = document.getElementById('canvas-container');
        canvasContainer.style.display = 'block';
        canvasContainer.classList.add('show');
        canvasContainer.classList.remove('hide');

        const timerText = document.getElementById('timerText');
        timerText.style.display = 'block';
        timerText.classList.add('show');
        timerText.classList.remove('hide');

        const container = document.getElementById('canvas-panel')

        const camera = new THREE.PerspectiveCamera(75, 2, 0.1, 1000);
        camera.position.z = 5;


        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true
        });
        let parent = document.getElementById('canvas-container')
        const newWidth = parent.clientWidth;
        const height = (newWidth * 0.5) > 320 ? 320 : newWidth * 0.5
        renderer.setSize(newWidth, height);
        renderer.setPixelRatio(window.devicePixelRatio);
        document.getElementById('canvas-container').appendChild(renderer.domElement);

        window.addEventListener('resize', () => {
            const newWidth = container.clientWidth - 20;
            const newHeight = container.clientHeight - 20;
            renderer.setSize(newWidth, newHeight);
            camera.aspect = newWidth / newHeight;
            camera.updateProjectionMatrix();
        });

        // Create the background texture
        const bgtextureLoader = new THREE.TextureLoader();
        const bgTexture = bgtextureLoader.load(skyImg);
        bgTexture.wrapS = THREE.MirroredRepeatWrapping;
        bgTexture.wrapT = THREE.MirroredRepeatWrapping;
        bgTexture.repeat.set(1, 1);
        scene.background = bgTexture;
        scene.backgroundBlurriness = 1
        scene.backgroundIntensity = 0.2




        const flightGeometry = new THREE.PlaneGeometry(2.5, 2.5);

        // Use a PlaneGeometry instead of a SphereGeometry
        const flightTexture = textureLoader.load(footballImg);
        const flightMaterial = new THREE.MeshBasicMaterial({ map: flightTexture, transparent: true });
        const cricketBall = new THREE.Mesh(flightGeometry, flightMaterial);
        // Create the cricket ball geometry and material
        cricketBall.position.x = -7;
        cricketBall.position.y = -3;
        scene.add(cricketBall);


        setScene(scene)
        setCricketBall(cricketBall)
        setrenderer(renderer)
        setcamera(camera)
        setbgTexture(bgTexture)

        function render() {
            requestAnimationFrame(render);
            renderer.render(scene, camera);
        }
        render()
    }

    useEffect(() => {
        localStorage.setItem('aviatorStatus', aviatorStatus)
    }, [aviatorStatus])
    useEffect(() => {

        // startGame()
        if (gameDetails._id && (gameStatus == 'pending' || aviatorStatus == 'pending') && (gameDetails.odds > 0.1 || gameDetails.gameStarted)) {
            setGameStatus('start')
            // Create the scene
            startGame()

        } else {
            if (gameDetails.odds >= 0.1 && gameDetails._id)
                setOdds(gameDetails.odds)
            if (gameDetails.burst && cricketBall.visible) {
                scene.remove(line);
                flewAwayMusic()
                flewAwayMusicOptions.sound = 70
                setAviatorStatus('flew-away')

                setTimeout(() => {
                    setOdds(0)

                    scene.remove(cricketBall);
                    setGameStatus('stop')
                    dispatch(ClearBurstGameData())
                }, 2500);


                const burstText = document.getElementById('burstText');
                burstText.style.display = 'block';
                burstText.classList.remove('hide');
                burstText.classList.add('show');

                const timerText = document.getElementsByClassName('timer-text');
                let i = 0;
                while (timerText.item(i)) {
                    timerText[i].style.display = 'none';
                    timerText[i].classList.remove('show');
                    timerText[i].classList.add('hide');
                    i++;
                }


            }
        }
    }, [gameDetails])


    useEffect(() => {
        if (gameStatus == 'stop') {

            clearState()
            setGameStatus('pending')
            setTimeout(() => {
                // scene.clear()
                document.getElementById('canvas-container').innerHTML = ''
                setCricketBall(null)
                setbgTexture(null)
                setrenderer(null)
                setcamera(null)
                setline(null)
                initilize()
                const burstText = document.getElementById('burstText');
                burstText.style.display = 'none';
                burstText.classList.add('hide');
                burstText.classList.remove('show');

            }, 1500)

        }
    }, [gameStatus])

    useEffect(() => {
        initilize()
    }, [])

    useEffect(() => {
        return () => {
            flewAwayMusicOptions.stop()
        }
    }, [])


    return (
        <div id='ballburst-main'>
            <Box>
                <div id="canvas-container" style={{ width: '100%' }}></div>
            </Box>
            {!gameDetails._id || !gameDetails.gameStarted ? <div id="timerText" class="timer-text show">{gameDetails.gameStartWithin ? "Place your bets now.." : "Waiting for next round"}<br></br><CircularProgressWithLabel label={gameDetails.gameStartWithin}  />
            </div> : gameDetails && gameDetails._id && gameDetails.odds == 0 ? <div id="timerText" class="timer-text show">{gameDetails.gameStartWithin ? "Place your bets now.." : gameDetails.gameStartWithin != 0 ? "Waiting for next round":""}<br></br><CircularProgressWithLabel label={gameDetails.gameStartWithin}  />
            </div> : <div id="timerText" class="timer-text show">{odds ? `${odds}x` : ''}
            </div>}
            <div id="burstText" class="burst-text"><span>{odds ? "Flew Away!" : <>Waiting for next round<br></br><CircularProgressWithLabel /></>}</span><br></br>{odds ? `${odds}x` : ''}</div>
        </div>
    )
}

export default Flightburst
