import { useState, useEffect, useRef } from "react";
import Flightburst from './Flightburst';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import RestoreIcon from '@mui/icons-material/Restore';
import Divider from '@mui/material/Divider';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePaginationActions from "../../utils/pagination";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import Switch from "@mui/material/Switch";
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
    getBalance,
    walletSelector
} from "../../store/reducer/wallet";
import {
    burstGameSelector,
    clearData as ClearBurstGameData,
    placeBet,
    collectBet,
    getRecentOdds,
    getMyBets,
    currentStakesGet,
    topWinsGet
} from "../../store/reducer/burstGames";
import { API_STATUS } from "../../utils/constants";
import { toast } from "react-toastify";
import Remove from "@mui/icons-material/Remove";
import { Alert, AlertTitle, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, useMediaQuery } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment'
import BreadCrumb from '../common/BreadCrumb'
import { Add } from "@mui/icons-material";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorSecondary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 3,
        backgroundColor: '#007852fc',
    },
}));

const DemoPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    width: '100%',
    height: '100%',
    padding: '5px',
    margin: '5px',
    borderRadius: '10px',
    textAlign: 'center',
}));

const Item = styled(Paper)(({ theme }) => ({

    ...theme.typography.body2,
    padding: theme.spacing(1),
    backgroundImage: 'unset',
    textAlign: 'center',
}));

const theme = createTheme({
    components: {
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#ccc",
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: "#198754",
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: "#fff",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: "#198754",
                    },
                },
            },
        },
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, mt: -2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function CricketX() {
    let loggedIn = localStorage.getItem('isAuthenticated');
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { balanceloading, userBalance } = useSelector(walletSelector);
    const { details: gameDetails, recentOdds, myBets, currentStakes, topWins } = useSelector(burstGameSelector);
    const [walletBalance, setwalletBalance] = useState({ walletAmount: 0, withdrawable: 0 });

    const IsGameIsStarted = () => gameDetails && gameDetails._id && (gameDetails.odds > 0)
    const [payloadArray, setPayloadArray] = useState([{
        betId: '',
        amount: 5,
        odds: 0,
        autoCollectOdds: 2,
        betState: 0,
        autoCollect: false,
        betPlacedId: null
    },
    {
        betId: '',
        amount: 5,
        odds: 0,
        autoCollectOdds: 2,
        betState: 0,
        autoCollect: false,
        betPlacedId: null
    }
    ])
    const [odds, setOdds] = useState(0);
    const [gameStatus, setGameStatus] = useState('pending');

    const clearState = () => {
        setOdds(0)
        setGameStatus('pending')
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (tempArray[i].betPlacedId)
                tempArray[i] = {
                    ...tempArray[i],
                    betId: '',
                    betState: 0,
                    odds: 0,
                    betPlacedId: null,
                }
        }
        setPayloadArray([...tempArray])
    }


    const onValueChange = (e, index) => {
        toast.dismiss()
        let tempArray = payloadArray
        if (e.target.value > 1000) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        for (let i in tempArray) {
            if (i == index) {
                tempArray[i].amount = e.target.value
            }
        }
        setPayloadArray([...tempArray])
    }

    const onChangeAddMinus = (type, index, amount) => {
        toast.dismiss()
        let tempArray = payloadArray
        if (type == "plus" && Number(tempArray[index].amount) + amount > 1000) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        if (type == "minus" && Number(tempArray[index].amount) - amount < 5) {
            toast.error('Stack should be between 5 and 1000..')
            return
        }
        for (let i in tempArray) {
            if (i == index) {
                if (type == 'plus')
                    tempArray[i].amount = Number(tempArray[i].amount) + amount
                if (type == 'minus')
                    tempArray[i].amount = Number(tempArray[i].amount) - amount
            }
        }
        setPayloadArray([...tempArray])
    }

    const onAutobetchange = (e, index) => {
        if (Number(e.target.value) > 0) {
            let tempArray = payloadArray
            for (let i in tempArray) {
                if (i == index) {
                    tempArray[i].autoCollectOdds = Number(e.target.value)
                }
            }
            setPayloadArray([...tempArray])
        }
    }

    const onAutoAddMinus = (type, index, amount) => {
        let tempArray = payloadArray
        toast.dismiss()
        if (type == "minus" && Number(tempArray[index].autoCollectOdds) - amount < 1) {
            toast.error('Odds should be greater than 1..')
            return
        }
        for (let i in tempArray) {
            if (i == index) {
                if (type == 'plus')
                    tempArray[i].autoCollectOdds = Number(tempArray[i].autoCollectOdds) + amount
                if (type == 'minus')
                    tempArray[i].autoCollectOdds = Number(tempArray[i].autoCollectOdds) - amount
            }
        }
        setPayloadArray([...tempArray])
    }

    const handleChange = (event, newValue) => {
        if (!loggedIn && newValue == 2) {
            toast.dismiss()
            toast.info("Login to view my bets..")
        } else
            setValue(newValue);
    };
    const handleToggle = (index) => () => {
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (i == index) {
                tempArray[i].autoCollect = !tempArray[i].autoCollect
            }
        }
        setPayloadArray([...tempArray])
    };

    const updateBetPlacedId = (index, id) => {
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (i == index) {
                tempArray[i].betPlacedId = id
                tempArray[i].betState = 1
            }
        }
        setPayloadArray([...tempArray])
    }

    const updateBetCollectedState = (index, id) => {
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (i == index) {
                tempArray[i].betState = 2
            }
        }
        setTimeout(() => {
            tempArray[index] = {
                ...tempArray[index],
                betId: '',
                betState: 0,
                odds: 0,
                betPlacedId: null
            }
            setPayloadArray([...tempArray])
        }, 1500)
        setPayloadArray([...tempArray])
    }

    const betStateChange = (betType, index) => {
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (i == index) {
                if (betType == "place") {
                    if (tempArray[i].amount > walletBalance.walletAmount) {
                        toast.error("Insufficient wallet amount!")
                    } else {
                        dispatch(placeBet({
                            payload: {
                                ...tempArray[i],
                                odds: tempArray[i].autoCollect ? tempArray[i].autoCollectOdds : tempArray[i].odds
                            },
                            updateBetPlacedId: updateBetPlacedId,
                            index
                        }))
                    }
                }
                if (betType == "collect") {
                    dispatch(collectBet({
                        payload: {
                            ...tempArray[i],
                            type: tempArray[i].autoCollect ? 'auto' : 'manual'
                        },
                        updateBetCollectedState: updateBetCollectedState,
                        index
                    }))
                }
                if (betType == "inqueue") {
                    if (tempArray[i].amount > walletBalance.walletAmount) {
                        toast.error("Insufficient wallet amount!")
                    } else
                        tempArray[i].betState = 3 // in queue for placing upcoming bet
                }
                if (betType == "inqueue-cancel") {
                    tempArray[i].betState = 0 // cancelling the queue for upcoming bet
                }
            }
        }
        setPayloadArray([...tempArray])
    }

    useEffect(() => {
        if (loggedIn) {
            dispatch(getBalance({}))
            dispatch(getMyBets({ payload: {} }))
            let intervalMyBets = setInterval(() => {
                dispatch(getMyBets({ payload: {} }))
            }, 30000)
        }
    }, [loggedIn])

    useEffect(() => {
        if (balanceloading === API_STATUS.FULFILLED) {
            setwalletBalance({
                ...walletBalance,
                walletAmount: userBalance.amount,
                withdrawable: userBalance.withdraw_amount
            })
        }
        if (balanceloading === API_STATUS.REJECTED) {
            toast.dismiss();
        }
    }, [balanceloading]);

    useEffect(() => {
        let tempArray = payloadArray
        if (gameDetails._id) {

            for (let i in tempArray) {
                tempArray[i].betId = gameDetails._id
            }
        }
        if (loggedIn) {
            dispatch(getBalance({}))
        }
        dispatch(getRecentOdds({}))
        setPayloadArray([...tempArray])
    }, [gameDetails && gameDetails._id])

    useEffect(() => {
        let tempArray = payloadArray
        for (let i in tempArray) {
            if (odds > 0) {
                if (!tempArray[i].autoCollect)
                    tempArray[i].odds = odds
                else if (tempArray[i].autoCollectOdds <= odds && tempArray[i].betState == 1) {
                    dispatch(collectBet({
                        payload: {
                            ...tempArray[i],
                            type: tempArray[i].autoCollect ? 'auto' : 'manual'
                        },
                        updateBetCollectedState: updateBetCollectedState,
                        index: i
                    }))
                    tempArray[i].betState = 2
                }
            } else tempArray[i].odds = 0
        }
        setPayloadArray([...tempArray])
    }, [odds])

    useEffect(() => {

        dispatch(getRecentOdds({}))
        dispatch(topWinsGet({ payload: {} }))
        dispatch(currentStakesGet({
            postData: {
                payload: {}
            }
        }))

        let intervalTopWins = setInterval(() => {
            dispatch(topWinsGet({ payload: {} }))
        }, 30000)
        let intervalCurrentStakes = setInterval(() => {
            dispatch(currentStakesGet({
                postData: {
                    payload: {}
                }
            }))
        }, 30000)
        return () => {
            clearInterval(intervalTopWins);
            clearInterval(intervalCurrentStakes);
        }
    }, [])
    useEffect(() => {
        let check = gameDetails && gameDetails._id && !IsGameIsStarted()
        if (check) {
            let tempArray = payloadArray
            for (let i in tempArray) {
                if (tempArray[i].betState == 3) {
                    if (tempArray[i].amount > walletBalance.walletAmount) {
                        toast.error("Insufficient wallet amount!")
                    } else {
                        dispatch(placeBet({
                            payload: {
                                ...tempArray[i],
                                odds: tempArray[i].autoCollect ? tempArray[i].autoCollectOdds : tempArray[i].odds
                            },
                            updateBetPlacedId: updateBetPlacedId,
                            index: i
                        }))
                    }
                }
            }
        }
    }, [gameDetails && gameDetails._id && !IsGameIsStarted()])
    return (
        <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
            <BreadCrumb pages={[
                {
                    name: 'Aviator',
                    page_link: false
                }
            ]}></BreadCrumb>
            <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={0} sx={{ padding: '4px 8px 4px 8px', backgroundColor: '#040507' }}>
                        <Typography component="div" variant="h7" >
                            Aviator
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <List
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'scroll',
                            paddingBottom: '0px',
                            paddingLeft: '5px',
                            paddingTop: '5px',
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}

                    >
                        {recentOdds && recentOdds.length ? recentOdds.map((item, i) => (
                            <>
                                {
                                    item && item.odds ? <ListItem sx={{ padding: '2px 2px 2px 2px', width: { md: '10%' } }} key={i}>
                                        <Chip variant="outlined" color="success" size="small" sx={{ display: { xs: 'flex', md: 'none' } }} label={parseFloat(item.odds).toFixed(2)}></Chip>
                                        <Chip variant="outlined" color="success" size="small" sx={{ display: { xs: 'none', md: 'flex' } }} label={parseFloat(item.odds).toFixed(2)}></Chip>
                                    </ListItem> : ''
                                }
                            </>
                        )) : ''}
                    </List>

                    <Item id="canvas-panel">
                        <Flightburst
                            clearState={clearState}
                            gameStatus={gameStatus}
                            setGameStatus={setGameStatus}
                            odds={odds}
                            setOdds={setOdds}
                            ClearBurstGameData={ClearBurstGameData}
                            gameDetails={gameDetails} />
                    </Item>
                    <Divider></Divider>
                    <Grid container>
                        <Grid item xs={1} md={2}>
                        </Grid>
                        {payloadArray && payloadArray.map((item, index) => {
                            // return (<Grid container sx={{ display: { xs: index == 1 ? 'none' : 'flex', md: 'flex' } }}>
                            return (
                                <>
                                    {item.betState == 0 || item.betState == 3 ?
                                        <Grid item xs={12} md={4} sx={{ backgroundColor: '#1b1c1d', borderRadius: '9px', margin: '1px' }}>
                                            <Grid container>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container>
                                                        <DemoPaper variant="elevation" >
                                                            <Grid item xs={12} md={12}>
                                                                <TextField
                                                                    id="standard-basic"
                                                                    label="Stack"
                                                                    value={item.amount}
                                                                    onChange={(e) => { onValueChange(e, index) }}
                                                                    focused sx={{ width: '100%', color: '#00fe76' }}
                                                                    placeholder="Rs."
                                                                    variant="standard"
                                                                    color="success" />
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <ButtonGroup
                                                                    size='small'
                                                                    disableElevation
                                                                    variant="outlined"
                                                                    color="success"
                                                                    sx={{ float: 'left', width: '100%', paddingTop: '4px' }}
                                                                >
                                                                    <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => { onChangeAddMinus("minus", index, 5) }}>-5</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onChangeAddMinus("plus", index, 50) }}>50</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onChangeAddMinus("plus", index, 100) }}>100</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onChangeAddMinus("plus", index, 250) }}>250</Button>
                                                                    <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onChangeAddMinus("plus", index, 500) }}>500</Button>
                                                                    <Button sx={{ float: 'left', width: '20%', fontWeight: 'bold' }} onClick={(e) => { onChangeAddMinus("plus", index, 5) }}>+5</Button>
                                                                </ButtonGroup>
                                                            </Grid>
                                                        </DemoPaper>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container>
                                                        <DemoPaper variant="elevation" >
                                                            <Grid item xs={12} md={12}>
                                                                <Grid container>
                                                                    <Grid item xs={item.autoCollect ? 6 : 12} md={item.autoCollect ? 4 : 12}>
                                                                        <FormControlLabel labelPlacement={item.autoCollect ? "top" : "start"} sx={{ color: '#00fe76' }} checked={item.autoCollect} onChange={handleToggle(index)} control={<Switch size="small" color="success" />} label="Auto Collect" />
                                                                    </Grid>
                                                                    {item.autoCollect ? <Grid item xs={6} md={8}>
                                                                        <TextField id="standard-basic" disabled={!item.autoCollect} InputProps={{
                                                                            readOnly: true,
                                                                        }} label="Odds" value={`${parseFloat(item.autoCollectOdds).toFixed(2)} x`} focused sx={{ width: '100%', color: '#00fe76' }} placeholder="Rs." variant="standard" color="success" />
                                                                    </Grid> : ''}

                                                                </Grid>
                                                            </Grid>
                                                            {item.autoCollect ?
                                                                <Grid item xs={12} md={12}>
                                                                    <ButtonGroup
                                                                        size='medium'
                                                                        disableElevation
                                                                        disabled={!item.autoCollect}
                                                                        variant="outlined"
                                                                        color="success"
                                                                        sx={{ float: 'left', width: '100%', paddingTop: '4px' }}
                                                                    >
                                                                        <Button sx={{ float: 'left', width: '20%' }} onClick={(e) => { onAutoAddMinus("minus", index, 0.5) }}>-0.5</Button>
                                                                        <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onAutoAddMinus("minus", index, 0.1) }}>-0.1</Button>
                                                                        <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onAutoAddMinus("plus", index, 0.1) }}>+0.1</Button>
                                                                        <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onAutoAddMinus("plus", index, 0.5) }}>+0.5</Button>
                                                                        <Button sx={{ float: 'left', width: '15%' }} onClick={(e) => { onAutoAddMinus("plus", index, 1) }}>+1</Button>
                                                                        <Button sx={{ float: 'left', width: '20%' }} onClick={(e) => { onAutoAddMinus("plus", index, 1.5) }}>+1.5</Button>
                                                                    </ButtonGroup>
                                                                </Grid> : ''}
                                                        </DemoPaper>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container >
                                                        <DemoPaper variant="elevation" sx={{ cursor: 'pointer' }} onClick={() => {
                                                            if (!loggedIn) {
                                                                toast.dismiss();
                                                                toast.error('Please login to place bet!');
                                                                return;
                                                            }

                                                            if (gameDetails && gameDetails._id && !IsGameIsStarted() && item.betState == 0) // without queue click by user
                                                                betStateChange("place", index)
                                                            else {
                                                                if (item.betState == 0)
                                                                    betStateChange("inqueue", index) // set a queue for placing bet
                                                                else betStateChange("inqueue-cancel", index)
                                                                // toast.dismiss();
                                                                // toast.error('You can place bet for next round!');
                                                            }
                                                        }}>
                                                            <Grid item xs={12} md={12} >
                                                                {item.betState == 0 ?
                                                                    <Button variant="text" color="success" >Place bet</Button> :
                                                                    <Button variant="text" color="error">Cancel</Button>}
                                                            </Grid>
                                                            <Grid item xs={12} md={12} >
                                                                {IsGameIsStarted() || !gameDetails._id ? <Typography variant="subtitle2" component="div" sx={{ color: '#fff' }}>For Next Round</Typography>:''}
                                                            </Grid>
                                                        </DemoPaper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : ''}
                                    {item.betState == 1 && gameDetails && gameDetails.gameStarted && odds > 1 ?
                                        <Grid item xs={12} md={4}>
                                            <Grid container >
                                                <DemoPaper variant="elevation" sx={{ cursor: 'pointer' }} onClick={() => { if (item.autoCollect) return; betStateChange("collect", index) }}>
                                                    <Grid item xs={12} md={12}>
                                                        <Typography variant="h6" component="div" sx={{ color: '#00fe76' }}>Rs. {((item.autoCollect ? item.autoCollectOdds : gameDetails && gameDetails.odds) * (item.amount)).toFixed(2)}</Typography>
                                                        {!item.autoCollect && odds > 1 ? <Button color="success" >Collect</Button> : ''}
                                                    </Grid>
                                                </DemoPaper>
                                            </Grid>
                                        </Grid>
                                        : ''}
                                    {item.betState == 1 && gameDetails && (!gameDetails.gameStarted || odds < 1) ? <Grid item xs={12} md={4}>
                                        <Grid container >
                                            <DemoPaper variant="elevation">
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="h6" component="div" sx={{ color: '#00fe76' }}>Bet Accepted</Typography>
                                                </Grid>
                                            </DemoPaper>
                                        </Grid>
                                    </Grid> : ''}
                                    {item.betState == 2 ? <Grid item xs={12} md={4}>
                                        <Grid container >
                                            <DemoPaper variant="elevation">
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="h6" component="div" sx={{ color: '#00fe76' }}>Collected</Typography>
                                                </Grid>
                                            </DemoPaper>
                                        </Grid>
                                    </Grid> : ''}
                                </>)
                        })}
                        <Grid item xs={1} md={2}>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#15c995'
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        sx={{
                            borderRight: 1, borderColor: 'divider',
                            width: '100%'
                        }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: '#15c995 !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: 600
                        }}
                            label="Current Stakes" {...a11yProps(0)} />

                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: '#15c995 !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: 600
                        }} label="Top Wins" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: '#15c995 !important',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: 600
                        }} label="My Bets" {...a11yProps(2)} />

                    </Tabs>
                    <TabPanel value={value} index={0} >
                        <TableContainer

                            sx={{
                                marginBottom: 0,
                                width: '100%',
                                padding: 0,
                                fontSize: '12px'
                            }}
                        >
                            <Table sx={{ fontSize: '12px' }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                User
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Bet
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Win
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        currentStakes && currentStakes.length ? currentStakes.map((row) => (
                                            <StyledTableRow>
                                                <StyledTableCell align="">
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {`₹ ${row.amount.toFixed(2)}`}
                                                </StyledTableCell>
                                                <StyledTableCell align="" style={{ fontWeight: 'bold' }}>
                                                    {row.winningAmount ? `₹ ${row.winningAmount.toFixed(2)}` : '-'}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                            : <StyledTableRow>
                                                <StyledTableCell align="" colSpan="3">
                                                    No Records Found..
                                                </StyledTableCell>
                                            </StyledTableRow>}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TableContainer
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                User
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Bet
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Win
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        topWins && topWins.length ? topWins.map((row) => (
                                            <StyledTableRow>
                                                <StyledTableCell align="">
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {`₹ ${row.amount.toFixed(2)}`}
                                                </StyledTableCell>
                                                <StyledTableCell align="" style={{ fontWeight: 'bold' }}>
                                                    {`₹ ${row.winningAmount.toFixed(2)}`}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                            : <StyledTableRow>
                                                <StyledTableCell align="" colSpan="3">
                                                    No Records Found..
                                                </StyledTableCell>
                                            </StyledTableRow>}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TableContainer
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                #
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Date
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Bet
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Odds
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell align="">
                                            <TableSortLabel hideSortIcon={true}>
                                                Win
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        myBets && myBets.length ? myBets.map((row) => (
                                            <StyledTableRow>
                                                <StyledTableCell align="" >
                                                    {row.status == 'credited' && row.gameStatus != 'started'
                                                        ? <Chip style={{ fontSize: '10px' }} label={row.betHash} size="small" color="success" />
                                                        : row.gameStatus != 'started' ? <Chip style={{ fontSize: '10px' }} label={row.betHash} size="small" color="error" />
                                                            : <Chip style={{ fontSize: '10px' }} label={row.betHash} size="small" color="primary" />}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {moment(row.placedOn).format('DD-MM-YYYY')}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {`₹ ${row.amount.toFixed(2)}`}
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    {row.odds ? row.odds : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align="" style={{ fontWeight: 'bold' }}>
                                                    {`₹ ${row.winningAmount.toFixed(2)}`}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        ))
                                            : <StyledTableRow>
                                                <StyledTableCell align="" colSpan="5">
                                                    No Records Found..
                                                </StyledTableCell>
                                            </StyledTableRow>}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </TabPanel>

                </Grid>
            </Grid >
        </Box >
    )
}

export default CricketX
