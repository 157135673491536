import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    getBalance,
    getTransaction,
    getwithdrawList,
    walletSelector,
    withdrawRequest
} from "../../store/reducer/wallet";
import { API_STATUS } from "../../utils/constants";
import SimpleReactValidator from "simple-react-validator";
import { Addbankdetails, getBankDetails, loginSelector } from "../../store/reducer/user";
import Modal from '@mui/material/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { green } from '@mui/material/colors';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePaginationActions from "../../utils/pagination";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WithdrawRequest from "./WithdrawRequest";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Banks() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userID = localStorage.getItem('userID')
    const formValidator = useRef(new SimpleReactValidator());
    const withdrawValidator = useRef(new SimpleReactValidator());
    const upiValidator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [radioValue, setradioValue] = useState('account');
    const [withdrawRadioValue, setwithdrawRadioValue] = useState('account');
    const [walletBalance, setwalletBalance] = useState({ walletAmount: 0, withdrawable: 0 });
    const [withdrawAmount, setwithdrawAmount] = useState('');
    const [withDrawReq, setwithDrawReq] = useState({
        userId: '',
        amount: '',
        isUpi: false,
        isBank: false,
        upiId: '',
        bankaddr: {
            ifsc_code: '',
            holder_name: '',
            account_no: '',
        }
    });
    const [bankdetails, setbankdetails] = useState({
        account_no: '',
        ifsc_code: '',
        holder_name: '',
        upiId: ''
    })
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [withdrawFormSubmit, setwithdrawFormSubmit] = useState(false)
    const [upiFormSubmit, setupiFormSubmit] = useState(false)
    const { userBankloading, userBankDetails } = useSelector(loginSelector);
    const { balanceloading, userBalance, withdrawlistCount, withdrawlist, withdrawReqloading, withdrawlistLoading } = useSelector(walletSelector);
    // const [upiId, setupiId] = useState('')
    const [selectedValue, setSelectedValue] = useState('1');
    const [transactionUpload, setTransactionUpload] = useState({ qr_img: '', qr_path: '', amount: '' });
    const [uploadopen, setuploadopen] = useState(false);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const uploadOpenFunc = () => setuploadopen(true);
    const uploadClose = () => {
        setuploadopen(false); setwithdrawFormSubmit(false); setwithDrawReq({
            amount: '',
        })
    }


    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        withDrawDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        withDrawDispatch();
    };

    const withDrawDispatch = () => {
        let payload = {
            limit: rowsPerPage,
            page: page + 1,
        }

        dispatch(getwithdrawList({
            payload, userID
        }))
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRadioChange = (event) => {
        // if (event.target.value == 'upi') {
        //     setbankdetails({
        //         ...bankdetails,
        //         mode: event.target.value,
        //         account_no: '',
        //         ifsc_code: '',
        //         holder_name: '',
        //     })
        // } else {
        //     setbankdetails({
        //         ...bankdetails,
        //         mode: event.target.value,
        //         upiId: ''
        //     })
        // }

        setradioValue(event.target.value);
    };

    const bankDataSubmit = () => {
        if (radioValue == 'account') {
            setAddFormSubmit(true);
        } else {
            setupiFormSubmit(true)
        }

        let postData = bankdetails;

        console.log(postData, 'postData==');
        if (formValidator.current.allValid() || upiValidator.current.allValid()) {
            dispatch(Addbankdetails({ postData }));
        }
    }

    useEffect(() => {
        if (userBankDetails) {
            setbankdetails({
                account_no: userBankDetails.account_no,
                holder_name: userBankDetails.holder_name,
                ifsc_code: userBankDetails.ifsc_code,
                upiId: userBankDetails.upiId
            })
        }
    }, [userBankDetails])

    useEffect(() => {
        dispatch(getBalance({}))
        dispatch(getBankDetails({ userID }))
        withDrawDispatch()
    }, [])

    useEffect(() => {
        console.log(balanceloading, "balanceloading")
        if (balanceloading === API_STATUS.FULFILLED) {
            setwalletBalance({
                ...walletBalance,
                walletAmount: userBalance.amount,
                withdrawable: userBalance.withdraw_amount
            })
        }
        if (balanceloading === API_STATUS.REJECTED) {
            toast.dismiss();
        }
    }, [balanceloading]);

    useEffect(() => {
        formValidator.current.showMessages()
        upiValidator.current.showMessages()
        withdrawValidator.current.showMessages()
    }, [])
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.up('md'));
    return (
       
            <Container sx={{ marginTop: '10px' }} maxWidth='100%'>
                <h4 style={{ marginTop: '80px', marginLeft: '25px' }}><b>Banks</b></h4>
                {
                matches ? 
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', marginTop: 5, height: 300, width: '100%' }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#15c995'
                            },
                        }}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1, borderColor: 'divider',
                            width: value == 0 ? '260px' : '200px',
                        }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 0) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 0) ? 600 : ''
                        }}
                            label="Balance" {...a11yProps(0)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 1) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 1) ? 600 : ''
                        }} label="Bank Details" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 2) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 2) ? 600 : ''
                        }} label="Withdraw Request" {...a11yProps(2)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 3) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="Withdraw List" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <h5><b>Balance Details</b></h5>
                        <Container fixed>
                            <Grid container spacing={3} sx={{ width: '80%', marginTop: '5px' }}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Total Balance</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "38%  !important" }} variant="h5"> <b>Rs {parseFloat(walletBalance.walletAmount).toFixed(2)}</b></Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{
                                            padding: '10px',
                                            paddingLeft: '60px',
                                            paddingRight: '60px'
                                        }}
                                        startIcon={<FileDownloadOutlinedIcon />}
                                        onClick={() => { navigate('/deposit') }}
                                    >
                                        Deposit
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{
                                            padding: '10px',
                                            paddingLeft: '60px',
                                            paddingRight: '60px'
                                        }}
                                        startIcon={<FileUploadOutlinedIcon />}
                                        onClick={() => { setValue(2) }}
                                    >
                                        Withdraw
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{
                                        marginTop: 2
                                    }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Withdrawable</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "45% !important" }} variant="body1"><b>Rs {parseFloat(walletBalance.withdrawable).toFixed(2)}</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Sports Bet Credits</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "45%  !important" }} variant="body1"><b>Rs 100.00</b></Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h5><b>Bank Account Details</b></h5>
                        <Container>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                sx={{ marginTop: 3 }}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="account" control={<Radio color="success" />} label="Bank Account" />
                                <FormControlLabel value="upi" control={<Radio color="success" />} label="UPI" />
                            </RadioGroup>
                            {radioValue == 'account' ?
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { mt: 3, width: '40ch' },
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr' },
                                        gap: 2,
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl variant="standard">
                                        <TextField color="success" name='accountno' id="outlined-basic" label="Account Number" variant="outlined" value={bankdetails.account_no} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                account_no: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'accountno',
                                            bankdetails.account_no,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Number',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="ifsc_code" id="outlined-basic" label="IFSC Code" variant="outlined" value={bankdetails.ifsc_code} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                ifsc_code: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'ifsc_code',
                                            bankdetails.ifsc_code,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter IFSC Code',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="holder_name" id="outlined-basic" label="Account Holder Name" variant="outlined" value={bankdetails.holder_name} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                holder_name: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'holder_name',
                                            bankdetails.holder_name,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Holder Name',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                </Box>
                                :
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                            // display: 'grid',
                                            // gridTemplateColumns: { sm: '1fr 1fr' },
                                            // gap: 8,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl variant="standard">
                                            <TextField color="success" id="outlined-basic" name="upi" label="UPI ID" variant="outlined" value={bankdetails.upiId} onChange={(e) => {
                                                setbankdetails({
                                                    ...bankdetails,
                                                    upiId: e.target.value
                                                })
                                            }
                                            } />
                                        </FormControl>
                                        {upiValidator.current.message(
                                            'upi',
                                            bankdetails.upiId,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${upiFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter UPI ID',
                                                }
                                            }
                                        )}
                                    </Box>
                                </div>
                            }
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={bankDataSubmit}>
                                    SUBMIT
                                </Button>
                            </div>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <WithdrawRequest walletBalance={walletBalance.withdrawable} />
                        {/* <div>
                            <div style={{ float: 'left' }}>
                                <h5><b>Withdraw Request</b></h5>
                            </div>
                            <div style={{ marginLeft: '60%' }}>
                                <Chip label={`Withdrawable : Rs ${parseFloat(walletBalance.withdrawable).toFixed(2)}`} color="success" icon={<InfoOutlinedIcon />} variant="outlined" />
                            </div>
                        </div>
                        <Container fixed>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                    display: 'grid',
                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                    gap: 2,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl variant="standard">
                                    <TextField color="success" name='accountno' id="outlined-basic" label="Withdraw Amount" variant="outlined" placeholder="Enter Amount Rs" value={withDrawReq.amount} onChange={(e) => {
                                        setwithDrawReq({
                                            ...withDrawReq,
                                            amount: e.target.value
                                        })
                                    }} />
                                    {withdrawValidator.current.message(
                                        'amount',
                                        withDrawReq.amount,
                                        'required|numeric',
                                        {
                                            className: `invalid_feedback
														  ${withdrawFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Amount',
                                                numeric: 'Please Enter Number',
                                            }
                                        }
                                    )}
                                </FormControl>
                            </Box>
                            <h5 style={{ marginTop: '3%' }}>Choose Mode of Payment</h5>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={withdrawRadioValue}
                                sx={{ marginTop: 2 }}
                                onChange={withdrawRadioChange}
                            >
                                <FormControlLabel value="account" control={<Radio color="success" />} label="Bank Account" />
                                <FormControlLabel value="upi" control={<Radio color="success" />} label="UPI" />
                            </RadioGroup>
                            {withdrawRadioValue == 'account' ?
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { mt: 3, width: '40ch' },
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr' },
                                        gap: 2,
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl variant="standard">
                                        <TextField color="success" name='accountno' id="outlined-basic" label="Account Number" variant="outlined" value={withDrawReq.bankaddr.account_no} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    account_no: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'accountno',
                                            withDrawReq.bankaddr.account_no,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Number',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="ifsc" id="outlined-basic" label="IFSC Code" variant="outlined" value={withDrawReq.bankaddr.ifsc_code} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    ifsc_code: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'ifsc',
                                            withDrawReq.bankaddr.ifsc_code,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter IFSC Code',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="holder" id="outlined-basic" label="Account Holder Name" variant="outlined" value={withDrawReq.bankaddr.holder_name} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    holder_name: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'holder',
                                            withDrawReq.bankaddr.holder_name,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Holder Name',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                </Box>
                                :
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                            // display: 'grid',
                                            // gridTemplateColumns: { sm: '1fr 1fr' },
                                            // gap: 8,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl variant="standard">
                                            <TextField color="success" id="outlined-basic" name="upi" label="UPI ID" variant="outlined" value={withDrawReq.upiId} onChange={(e) => {
                                                setwithDrawReq({
                                                    ...withDrawReq,
                                                    upiId: e.target.value
                                                })
                                            }} />
                                        </FormControl>
                                        {upiValidator.current.message(
                                            'upi',
                                            withDrawReq.upiId,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${upiFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter UPI ID',
                                                }
                                            }
                                        )}
                                    </Box>
                                </div>
                            }
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={withdrawSubmit}>
                                    SUBMIT REQUEST
                                </Button>
                            </div>
                        </Container> */}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Box sx={{ maxWidth: '100%', float: 'left', justifyContent: 'center' }}>
                            <h5><b>Withdraw List</b></h5>
                            {/* <div style={{ border: '0.5px solid grey' }}> */}
                            <TableContainer
                                sx={{
                                    height: 420,
                                    width: 800
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Amount
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Comments
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Withdrawal Date
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {withdrawlist && withdrawlist.map((item) => {
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {item.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.comments ? item.comments : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {dayjs(item.createdOn).format('DD-MM-YYYY hh:mm:ss A')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.status == 0 ? <Chip label="Pending" color="primary" variant="outlined" /> : item.status == 1 ? <Chip label="Approved" color="success"></Chip> : item.status == 2 ? <Chip label="Disapproved" color="error" /> : ''}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={withdrawlistCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            {/* </div> */}
                        </Box>
                    </TabPanel>
                </Box>
                :
                <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    bgcolor: 'background.paper',
                  }}
                >
                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                width: '100%',
                                backgroundColor: '#15c995'
                            },
                        }}
                        aria-label="Vertical tabs example"
                        sx={{
                           
                            width: value == 0 ? '100%' : '100%',
                        }}
                    >
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 0) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 0) ? 600 : ''
                        }}
                            label="Balance" {...a11yProps(0)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 1) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 1) ? 600 : ''
                        }} label="Bank Details" {...a11yProps(1)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 2) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 2) ? 600 : ''
                        }} label="Withdraw Request" {...a11yProps(2)} />
                        <Tab sx={{
                            alignItems: 'flex-start !important',
                            color: (value == 3) ? '#15c995 !important' : '',
                            "&:hover": {
                                color: '#15c995'
                            },
                            fontWeight: (value == 3) ? 600 : ''
                        }} label="Withdraw List" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <h5><b>Balance Details</b></h5>
                        <Container fixed>
                            <Grid container spacing={3} sx={{ width: '100%', marginTop: '5px' }}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Total Balance</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "38%  !important" }} variant="h5"> <b>Rs {parseFloat(walletBalance.walletAmount).toFixed(2)}</b></Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{
                                            width: '100%',
                                            padding: '10px',
                                            paddingLeft: '60px',
                                            paddingRight: '60px'
                                        }}
                                        startIcon={<FileDownloadOutlinedIcon />}
                                        onClick={() => { navigate('/deposit') }}
                                    >
                                        Deposit
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        sx={{
                                            width: '100%',
                                            padding: '10px',
                                            paddingLeft: '60px',
                                            paddingRight: '60px'
                                        }}
                                        startIcon={<FileUploadOutlinedIcon />}
                                        onClick={() => { setValue(2) }}
                                    >
                                        Withdraw
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{
                                        marginTop: 2
                                    }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Withdrawable</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "45% !important" }} variant="body1"><b>Rs {parseFloat(walletBalance.withdrawable).toFixed(2)}</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Sports Bet Credits</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ marginLeft: "45%  !important" }} variant="body1"><b>Rs 100.00</b></Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h5><b>Bank Account Details</b></h5>
                        <Container>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                sx={{ marginTop: 3 }}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="account" control={<Radio color="success" />} label="Bank Account" />
                                <FormControlLabel value="upi" control={<Radio color="success" />} label="UPI" />
                            </RadioGroup>
                            {radioValue == 'account' ?
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { mt: 3, width: '40ch' },
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr' },
                                        gap: 2,
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl variant="standard">
                                        <TextField color="success" name='accountno' id="outlined-basic" label="Account Number" variant="outlined" value={bankdetails.account_no} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                account_no: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'accountno',
                                            bankdetails.account_no,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Number',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="ifsc_code" id="outlined-basic" label="IFSC Code" variant="outlined" value={bankdetails.ifsc_code} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                ifsc_code: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'ifsc_code',
                                            bankdetails.ifsc_code,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter IFSC Code',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="holder_name" id="outlined-basic" label="Account Holder Name" variant="outlined" value={bankdetails.holder_name} onChange={(e) => {
                                            setbankdetails({
                                                ...bankdetails,
                                                holder_name: e.target.value
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'holder_name',
                                            bankdetails.holder_name,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Holder Name',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                </Box>
                                :
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                            // display: 'grid',
                                            // gridTemplateColumns: { sm: '1fr 1fr' },
                                            // gap: 8,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl variant="standard">
                                            <TextField color="success" id="outlined-basic" name="upi" label="UPI ID" variant="outlined" value={bankdetails.upiId} onChange={(e) => {
                                                setbankdetails({
                                                    ...bankdetails,
                                                    upiId: e.target.value
                                                })
                                            }
                                            } />
                                        </FormControl>
                                        {upiValidator.current.message(
                                            'upi',
                                            bankdetails.upiId,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${upiFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter UPI ID',
                                                }
                                            }
                                        )}
                                    </Box>
                                </div>
                            }
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={bankDataSubmit}>
                                    SUBMIT
                                </Button>
                            </div>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <WithdrawRequest walletBalance={walletBalance.withdrawable} />
                        {/* <div>
                            <div style={{ float: 'left' }}>
                                <h5><b>Withdraw Request</b></h5>
                            </div>
                            <div style={{ marginLeft: '60%' }}>
                                <Chip label={`Withdrawable : Rs ${parseFloat(walletBalance.withdrawable).toFixed(2)}`} color="success" icon={<InfoOutlinedIcon />} variant="outlined" />
                            </div>
                        </div>
                        <Container fixed>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                    display: 'grid',
                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                    gap: 2,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <FormControl variant="standard">
                                    <TextField color="success" name='accountno' id="outlined-basic" label="Withdraw Amount" variant="outlined" placeholder="Enter Amount Rs" value={withDrawReq.amount} onChange={(e) => {
                                        setwithDrawReq({
                                            ...withDrawReq,
                                            amount: e.target.value
                                        })
                                    }} />
                                    {withdrawValidator.current.message(
                                        'amount',
                                        withDrawReq.amount,
                                        'required|numeric',
                                        {
                                            className: `invalid_feedback
														  ${withdrawFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Amount',
                                                numeric: 'Please Enter Number',
                                            }
                                        }
                                    )}
                                </FormControl>
                            </Box>
                            <h5 style={{ marginTop: '3%' }}>Choose Mode of Payment</h5>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={withdrawRadioValue}
                                sx={{ marginTop: 2 }}
                                onChange={withdrawRadioChange}
                            >
                                <FormControlLabel value="account" control={<Radio color="success" />} label="Bank Account" />
                                <FormControlLabel value="upi" control={<Radio color="success" />} label="UPI" />
                            </RadioGroup>
                            {withdrawRadioValue == 'account' ?
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { mt: 3, width: '40ch' },
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr' },
                                        gap: 2,
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl variant="standard">
                                        <TextField color="success" name='accountno' id="outlined-basic" label="Account Number" variant="outlined" value={withDrawReq.bankaddr.account_no} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    account_no: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'accountno',
                                            withDrawReq.bankaddr.account_no,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Number',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="ifsc" id="outlined-basic" label="IFSC Code" variant="outlined" value={withDrawReq.bankaddr.ifsc_code} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    ifsc_code: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'ifsc',
                                            withDrawReq.bankaddr.ifsc_code,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter IFSC Code',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <TextField color="success" name="holder" id="outlined-basic" label="Account Holder Name" variant="outlined" value={withDrawReq.bankaddr.holder_name} onChange={(e) => {
                                            setwithDrawReq({
                                                ...withDrawReq,
                                                bankaddr: {
                                                    holder_name: e.target.value
                                                }
                                            })
                                        }} />
                                        {formValidator.current.message(
                                            'holder',
                                            withDrawReq.bankaddr.holder_name,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Account Holder Name',
                                                }
                                            }
                                        )}
                                    </FormControl>
                                </Box>
                                :
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { mt: 3, width: '45ch' },
                                            // display: 'grid',
                                            // gridTemplateColumns: { sm: '1fr 1fr' },
                                            // gap: 8,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <FormControl variant="standard">
                                            <TextField color="success" id="outlined-basic" name="upi" label="UPI ID" variant="outlined" value={withDrawReq.upiId} onChange={(e) => {
                                                setwithDrawReq({
                                                    ...withDrawReq,
                                                    upiId: e.target.value
                                                })
                                            }} />
                                        </FormControl>
                                        {upiValidator.current.message(
                                            'upi',
                                            withDrawReq.upiId,
                                            'required',
                                            {
                                                className: `invalid_feedback
														  ${upiFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter UPI ID',
                                                }
                                            }
                                        )}
                                    </Box>
                                </div>
                            }
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <Button sx={{ mt: 5, ml: 1, width: '50ch', height: '5ch', background: '#198754' }} variant="contained" size="medium" color="success" onClick={withdrawSubmit}>
                                    SUBMIT REQUEST
                                </Button>
                            </div>
                        </Container> */}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Box sx={{ maxWidth: '100%', float: 'left', justifyContent: 'center' }}>
                            <h5><b>Withdraw List</b></h5>
                            {/* <div style={{ border: '0.5px solid grey' }}> */}
                            <TableContainer
                                sx={{
                                    height: 420,
                                    width: 800
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Amount
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Comments
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Withdrawal Date
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel hideSortIcon={true}>
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {withdrawlist && withdrawlist.map((item) => {
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {item.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.comments ? item.comments : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {dayjs(item.createdOn).format('DD-MM-YYYY hh:mm:ss A')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {item.status == 0 ? <Chip label="Pending" color="primary" variant="outlined" /> : item.status == 1 ? <Chip label="Approved" color="success"></Chip> : item.status == 2 ? <Chip label="Disapproved" color="error" /> : ''}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={withdrawlistCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            {/* </div> */}
                        </Box>
                    </TabPanel>
                </Box>
                }
                {/* <Dialog
                    open={uploadopen}
                    onClose={uploadClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle sx={{ background: '#198754', color: "white", height: '5ch' }}>Withdraw Request</DialogTitle>
                    <DialogContent sx={{ backgroundColor: 'white', color: 'black' }}>
                        <Divider sx={{ color: 'black' }} />
                        <IconButton
                            aria-label="close"
                            onClick={uploadClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: "white",
                                "&:hover": {
                                    color: '#15c995'

                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div class="mb-3 mt-3">
                            <label style={{ color: 'black' }} for="exampleFormControlInput1" class="form-label">Enter Amount<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" class="form-control" name="amount" id="exampleFormControlInput1" placeholder="Withdraw Amount Rs" onChange={(e) => {
                                setWithdrawData({
                                    ...withdrawData,
                                    amount: e.target.value
                                })
                            }} />
                            {withdrawValidator.current.message(
                                'amount',
                                withdrawData.amount,
                                'required|numeric',
                                {
                                    className: `invalid_feedback
														  ${withdrawFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Amount',
                                        numeric: 'Please Enter Number',
                                    }
                                }
                            )}
                        </div>
                        <div class="mb-3 mt-4">
                            <h5 style={{ color: 'black' }}>Choose Mode of Payment</h5>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioValue}
                                sx={{ marginTop: 1, color: 'black' }}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="account" style={{ color: 'black' }} control={<Radio color="success" sx={{
                                    color: green[800],
                                }} />} label="Bank Account" />
                                <FormControlLabel value="upi" style={{ color: 'black' }} control={<Radio color="success" sx={{
                                    color: green[800],
                                }} />} label="UPI" />
                            </RadioGroup>
                        </div>
                        {radioValue == "account" ?
                            <>
                                <p >Account No : <b>37714885412360</b></p>
                                <p>IFSC Code :   <b>SBIN008976</b></p>
                                <p>Holder Name : <b>SANKAR C</b></p>
                            </>
                            :
                            <p>UPI ID : <b>developer@upi</b></p>}
                        <button type="button" class="offset-md-4 col-md-4 mt-3 btn btn-success" onClick={withdrawSubmit}>Submit</button>
                    </DialogContent>
                </Dialog> */}
            </Container>

    )
}

export default Banks
