import React, { useEffect } from 'react'
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { gameSelector, getGames } from '../store/reducer/games';
import { gameTypes } from '../config';
import { toast } from 'react-toastify';
const MenuBorder = () => <Divider sx={{ opacity: 0, marginTop: '0px !important', marginBottom: '4px !important' }} />


const MenuCustomMenu = ({
    navigate,
    Icon,
    value,
    active,
    label,
    disabled,
    getFilterGames,
    child,
    status,
    hasChild
}) => {
    const isActive = value === active
    return <MenuItem disabled={disabled} sx={{
        backgroundColor: '#1b1c1d',
        paddingTop: '10px',
        paddingRight: '0px',
        paddingBottom: '10px',
        color: isActive ? 'red' : 'white',
    }}
        onClick={(e) => {
            if (child) {
                if (status == 2) {
                    toast.info('Coming Soon..')
                    return
                }
                navigate(value)
            }
            else if (typeof getFilterGames == "function")
                getFilterGames(value)

        }}
        onMouseEnter={() => {
            if (!child && typeof getFilterGames == "function") getFilterGames(value)
        }}
    >

        {child ? '' : <ListItemIcon>
            <Icon sx={{ color: isActive ? 'red' : 'white' }} fontSize="small" />
        </ListItemIcon>}
        <ListItemText sx={{ fontWeight: 'bold' }}>{label}</ListItemText>
        {child ? (status == 2 ? <ListItemIcon>
            <NewReleasesIcon sx={{ color: '#ef8080a3' }} titleAccess="Coming Soon.." fontSize="small" />
        </ListItemIcon> : '') : hasChild ? <ListItemIcon>
            <KeyboardArrowRightIcon sx={{ color: isActive ? 'red' : 'white' }} fontSize="small" />
        </ListItemIcon>:''}

    </MenuItem>
}

export default function NavBar() {
    const [filterGames, setFilterGames] = React.useState([]);
    const [currentGameType, setCurrentGameType] = React.useState();
    const { games } = useSelector(gameSelector)



    const getFilterGames = (type) => {
        let tempGames = games.filter((item) => item.gameType == type)
        setFilterGames([...tempGames])
        setCurrentGameType(type)
    }


    const navigate = useNavigate()

    return <>
        <MenuList>
            {gameTypes && gameTypes.map((item) => {
                let childrens = games.filter((game) => game.gameType == item.key)
                return <>
                    <MenuCustomMenu
                        navigate={navigate}
                        getFilterGames={getFilterGames}
                        value={item.key}
                        label={item.name}
                        Icon={item.icon}
                        hasChild={childrens.length > 0}
                    />
                    {item.key == currentGameType ? filterGames.map((filter) => {
                        return <MenuCustomMenu
                            child={true}
                            navigate={navigate}
                            value={filter.website_url}
                            label={filter.gameName}
                            status={filter.status}
                            hasChild={false}
                        />
                    }) : ''}
                    <MenuBorder />
                </>
            })}
        </MenuList>
        {/* <GamesSubMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
        ></GamesSubMenu> */}
    </>
}
