import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { gamesList } from "../../services/api";
import { toast } from "react-toastify";

const namespace = "games";

const initialState = {
    games: []
};



export const getGames = createAsyncThunk(
    `${namespace}/list`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await gamesList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);


const gameSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: (state) => {
            state.details = initialState.details
        }
    },
    extraReducers: {
        [getGames.fulfilled](state, { payload }) {
            state.games = payload?.data?.data?.result;
        },
    },
});
export const { clearData } = gameSlice.actions;

export const gameSelector = (state) => state.game;

export default gameSlice.reducer;
