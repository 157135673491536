import * as React from 'react';
import {
    Box,
    Toolbar,
    Divider,
    Tabs,
    Grid,
    Chip,
    Typography,
    Tab,
    Drawer,
    Paper
} from '@mui/material';
import RightDrawer from '../../common/rightdrawer';
import Cricket from './cricketinplay';
import { games } from '../../../config/index'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useParams } from 'react-router-dom';
import LeftDrawer from '../../common/leftdrawer';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { styled } from "@mui/material/styles";
import BreadCrumb from '../../common/BreadCrumb'
import BetsDrawer from '../../common/betsDrawer';
import { Button } from 'react-bootstrap';
import Fab from '@mui/material/Fab';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
const MatchItem = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#fff",
    padding: theme.spacing(1),
    maxHeight: "40px",
    minHeight: "40px",
    color: theme.palette.text.secondary,
}));

export default function InPlayEvent() {
    let loggedIn = localStorage.getItem('isAuthenticated');
    const { event_id, game } = useParams()
    const [overviewData, setOverViewData] = React.useState({})
    const [lastSixDeliveries, setLastSixDeliveries] = React.useState([])
    const [openBetsDrawer, setOpenBetsDrawer] = React.useState(false)
    React.useEffect(() => {
        if (overviewData && overviewData.success) {
            let deliveryData = overviewData.last_deliveries
            if (deliveryData) {
                deliveryData = deliveryData && deliveryData.split(' ').filter(item => item)
                deliveryData = deliveryData.filter((item, i) => {
                    return deliveryData.length - 6 < i
                })
                setLastSixDeliveries([...deliveryData])
            }
            setLastSixDeliveries([])
        }
    }, [overviewData])
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, pt: 1 }}>
                <BreadCrumb pages={[
                    {
                        name: 'Live Sports',
                        page_link: '/in-play'
                    },
                    overviewData && overviewData.success ? {
                        name: overviewData && overviewData.name,
                        page_link: false
                    } : {}
                ]}></BreadCrumb>
                {overviewData && overviewData.success ? <Box sx={{ p: 1, width: '100%', bgcolor: 'background.paper' }}>
                    {/* <Divider sx={{
                        fontWeight: 700,
                    }}>
                        <Chip label={overviewData && overviewData.name} color="success" />
                    </Divider> */}
                    <Grid container spacing={0}>
                        <Grid item xs={16}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Paper elevation={16} sx={{padding:'10px'}}>
                                    <Typography component="div" variant="h7" >
                                        {overviewData && overviewData.name}
                                    </Typography>
                                    {loggedIn?<Grid container spacing={0}>
                                            <Grid item xs={16}>
                                                <Divider sx={{ marginTop: '5px' }}></Divider>
                                                <Fab color="success" size="small" sx={{ marginTop: '10px' }} variant="extended" aria-label="add" onClick={() => {
                                                    setOpenBetsDrawer(true)
                                                }}>
                                                    <CurrencyExchangeTwoToneIcon />&nbsp;&nbsp;&nbsp;My Bets
                                                </Fab>
                                            </Grid>
                                        </Grid>:''}
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>

                </Box> : ''}
                {game == 'cricket' ? <Cricket eventId={event_id} setOverViewData={setOverViewData}></Cricket> : ''}

            </Box>
            <BetsDrawer drawerOpen={[openBetsDrawer, setOpenBetsDrawer]}></BetsDrawer>
        </>
    );
}